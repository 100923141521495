import { RadioGroup } from '@headlessui/react';
import { get } from 'lodash-es';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';

type ButtonGroupOption = { key: string; value: string; disabled?: boolean };

interface ButtonGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string | React.ReactNode;
  helperText?: string;
  options: ButtonGroupOption[];
}

function ButtonGroup({
  label,
  helperText,
  className,
  name,
  options,
  disabled,
}: ButtonGroupProps) {
  const {
    register,
    formState: { errors, isSubmitting, defaultValues },
    setValue,
  } = useFormContext();
  const error = get(errors, name);
  const hasError = Boolean(error);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onChange, ...restRegisterProps } = register(name);

  const handleChange = (value: string) => {
    setValue(name, value, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <fieldset className={className}>
      <RadioGroup
        disabled={isSubmitting}
        aria-invalid={hasError ? 'true' : 'false'}
        defaultValue={defaultValues ? defaultValues[name] : undefined}
        onChange={handleChange}
        {...restRegisterProps}
      >
        <RadioGroup.Label className="label mb-0.5">{label}</RadioGroup.Label>

        <span className="isolate inline-flex rounded-md shadow-sm">
          {options.map(option => (
            <RadioGroup.Option
              value={option.key}
              className="button-group-item"
              key={option.key}
              disabled={option.disabled || disabled}
            >
              {option.value}
            </RadioGroup.Option>
          ))}
        </span>
      </RadioGroup>
      {hasError ? (
        <p className="mt-1.5 text-sm text-red-600">
          {error?.message?.toString()}
        </p>
      ) : null}

      {helperText ? (
        <p className="mt-1.5 text-sm text-gray-600">{helperText}</p>
      ) : null}
    </fieldset>
  );
}

type ButtonGroupBaseProps = {
  label: string | React.ReactNode;
  options: ButtonGroupOption[];
  onChange: (newVal: string) => void;
  defaultValue?: any;
  helperText?: string;
  isDisabled?: boolean;
};

function ButtonGroupBase(props: ButtonGroupBaseProps) {
  const { options, label, onChange, isDisabled, defaultValue } = props;
  return (
    <RadioGroup
      disabled={isDisabled}
      onChange={onChange}
      defaultValue={defaultValue}
    >
      <RadioGroup.Label className="label mb-0.5">{label}</RadioGroup.Label>

      <span className="isolate inline-flex rounded-md shadow-sm">
        {options.map(option => (
          <RadioGroup.Option
            value={option.key}
            className="button-group-item"
            key={option.key}
            disabled={option.disabled || isDisabled}
          >
            {option.value}
          </RadioGroup.Option>
        ))}
      </span>
    </RadioGroup>
  );
}

export { ButtonGroup, ButtonGroupBase };
