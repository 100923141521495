import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationFormDto } from '@/types/application';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { isAdminDashboard } from '@/utils/is-dashboard';
import {
  AllApplications,
  Application,
  SecondarySchoolApplication,
  UpdateApplicationDto,
  UpdateApplicationDtos,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { additionalInfoFormSchema } from './additional-info.schema';
import { AdditionalInfoFormData } from './additional-info.type';

type UseAdditionalInfoFormProps = {
  onSubmit: (data: UpdateApplicationDto) => Promise<Application>;
  initialData: AllApplications;
};

function transformApiDataToFormData(
  initialData: AllApplications
): AdditionalInfoFormData {
  return {
    additionalDetails: initialData.additionalDetails,
    additionalQuestionAnswers: initialData.additionalQuestionAnswers || {},
    ...(initialData
      ? {
          subjectDetails: (initialData as SecondarySchoolApplication)
            .subjectDetails,
        }
      : {}),
  };
}

function useAdditionalInfoForm(props: UseAdditionalInfoFormProps) {
  const { initialData, onSubmit } = props;
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const defaultValues: AdditionalInfoFormData =
    transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(additionalInfoFormSchema),
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }
    reset(undefined, { keepValues: true });
  }, [form.formState.isSubmitSuccessful, reset]);

  useEffect(() => {
    reset(transformApiDataToFormData(initialData));
  }, [initialData, reset]);

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }

    if (isAdminDashboard()) {
      return navigate(
        generatePath(staffRouterPath.APPLICATIONS, {
          id: params.id || '',
        })
      );
    }

    return navigate(
      generatePath(parentRouterPath.APPLICATION_SUCCESS, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const handleError = (httpError: HttpError) => {
    if (isBadRequest(httpError)) {
      const availableFields = getNestedKeys(form.getValues());

      availableFields.forEach(field => {
        if (!Array.isArray(httpError.message)) {
          if (!httpError.message.includes(field)) {
            return;
          }

          const formatedMsg = httpError.message.replace(field, 'Field');
          form.setError(field, { message: formatedMsg });
          return;
        }

        httpError.message.map(msg => {
          if (msg.includes(field)) {
            const formatedMsg = msg.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
          }
        });
      });
    }

    form.setError('root.server', {
      message: Array.isArray(httpError.message)
        ? httpError.message[0]
        : httpError.message,
    });
  };

  const handleSubmit = async (data: ApplicationFormDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'additionalDetails',
        'additionalQuestionAnswers',
      ]);

      const applicationDto: UpdateApplicationDtos = {
        ...transformedApplication,
        // This is safe because we only spread if the property exists
        ...((data as unknown as SecondarySchoolApplication)?.subjectDetails
          ? {
              subjectDetails: (data as unknown as SecondarySchoolApplication)
                .subjectDetails,
            }
          : {}),
      };

      await onSubmit(applicationDto);
    } catch (error) {
      const httpError = error as HttpError;

      handleError(httpError);
    }
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    try {
      await onSubmit({ isSubmittingApplication: true });
      setShouldRedirect(true);
    } catch (error) {
      const httpError = error as HttpError;

      handleError(httpError);
    }
  };

  return {
    form,
    handleSubmit: handleSave,
    saveAsDraft,
  };
}

export { useAdditionalInfoForm };
