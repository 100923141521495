import { Alert } from '@/components/alert';
import { Table } from '@/components/table';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import {
  useApplicationsReportResult,
  useExportApplicationsReportResultToCSV,
} from '@/hooks/query-hooks/use-applications-report-results';
import {
  useBookingsReportResult,
  useExportBookingReportResultToCSV,
} from '@/hooks/query-hooks/use-bookings-report-result';
import {
  useExportLocationsReportResultToCSV,
  useLocationsReportResult,
} from '@/hooks/query-hooks/use-locations-report-results';
import { useModal } from '@/hooks/use-modal';
import {
  applicationsFilterDataSources,
  isApplicationsFilter,
  isBookingsFilter,
  isLocationsFilter,
  locationsFilterDataSources,
} from '@/pages/reporting/upsert-query-builder';
import { getErrorMessage } from '@/utils/get-error-message';
import { downloadCSV } from '@/utils/reporting-utils';
import {
  ChildCarer,
  ChildSiblings,
  ReferenceWithFullName,
} from '@admissions-support/types';
import {
  Columns03,
  FileDownload03,
  Loading01,
  SearchRefraction,
} from '@untitled-ui/icons-react';
import { compact } from 'lodash-es';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { useOutputApplicationsTableColumns } from './applications/use-output-applications-table-columns';
import { useOutputBookingsTableColumns } from './bookings/use-output-bookings-table-columns';
import { ReportingDetailedViewDrawer } from './drawer/reporting-detailed-view-drawer';
import { ToggleColumnsDrawer } from './drawer/toggle-columns-drawer';
import { useOutputLocationsTableColumns } from './locations/use-output-locations-table-columns';

export type ReportingSideDrawerContent =
  | ChildCarer
  | ChildSiblings
  | ReferenceWithFullName;

type OutputTableProps = {
  runOnMount?: boolean;
  className?: string;
  dataSource: string;
};

function OutputTable(props: OutputTableProps) {
  const { runOnMount = false, className, dataSource } = props;
  const { pagination, setPagination, filter, toggledColumns, hasQueryRun } =
    useReportingOutputControl();
  const { isOpen, closeModal, openModal } = useModal();

  const {
    data: applicationsResults,
    isFetching: isApplicationsFetching,
    error: applicationsError,
  } = useApplicationsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isApplicationsFilter(filter, dataSource) ? filter : []),
    },
    {
      enabled: runOnMount && applicationsFilterDataSources.includes(dataSource),
    }
  );

  const {
    data: bookingsResults,
    isFetching: isBookingsFetching,
    error: bookingsError,
  } = useBookingsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isBookingsFilter(filter, dataSource) ? filter : []),
    },
    {
      enabled: runOnMount && dataSource === 'BOOKINGS',
    }
  );

  const {
    data: locationsResults,
    isFetching: isLocationsFetching,
    error: locationsError,
  } = useLocationsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isLocationsFilter(filter, dataSource) ? filter : []),
    },
    {
      enabled: runOnMount && locationsFilterDataSources.includes(dataSource),
    }
  );

  const { columns: applicationsColumns } = useOutputApplicationsTableColumns({
    dataSource,
  });

  const { columns: bookingsColumns } = useOutputBookingsTableColumns();

  const { columns: locationsColumns } = useOutputLocationsTableColumns({
    dataSource,
  });

  const {
    refetch: exportApplicationsResultToCSV,
    isFetching: isApplicationsResultExporting,
  } = useExportApplicationsReportResultToCSV(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isApplicationsFilter(filter, dataSource) ? filter : []),
    },
    { enabled: false }
  );

  const {
    refetch: exportLocationResultToCSV,
    isFetching: isLocationsResultExporting,
  } = useExportLocationsReportResultToCSV(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isLocationsFilter(filter, dataSource) ? filter : []),
      locationQueryType:
        dataSource === 'SCHOOL LOCATIONS' ? 'SCHOOL' : undefined,
    },
    { enabled: false }
  );

  const {
    refetch: exportBookingsResultToCSV,
    isFetching: isBookingsResultExporting,
  } = useExportBookingReportResultToCSV(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isBookingsFilter(filter, dataSource) ? filter : []),
    },
    { enabled: false }
  );

  const handleExportToCSV = async () => {
    if (applicationsFilterDataSources.includes(dataSource)) {
      const applicationsBlob = await exportApplicationsResultToCSV();

      if (!applicationsBlob.isSuccess) {
        return;
      }

      downloadCSV(applicationsBlob.data);
    }

    if (locationsFilterDataSources.includes(dataSource)) {
      const locationsBlob = await exportLocationResultToCSV();

      if (!locationsBlob.isSuccess) {
        return;
      }

      downloadCSV(locationsBlob.data);
    }

    if (dataSource === 'BOOKINGS') {
      const locationsBlob = await exportBookingsResultToCSV();

      if (!locationsBlob.isSuccess) {
        return;
      }

      downloadCSV(locationsBlob.data);
    }
  };

  const isExporting =
    isApplicationsResultExporting ||
    isLocationsResultExporting ||
    isBookingsResultExporting;

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        {!runOnMount && (
          <p className="text-lg font-semibold text-primary-900">Output</p>
        )}
        <div className="ml-auto flex flex-col gap-4 sm:flex-row">
          <button
            className="btn btn-secondary flex items-center gap-1"
            onClick={openModal}
            type="button"
          >
            <Columns03
              aria-hidden="true"
              className={twMerge('h-5 w-5 text-gray-700')}
            />
            Toggle Columns
          </button>

          <button
            className="btn btn-secondary btn-icon flex items-center gap-1"
            disabled={isExporting}
            type="button"
            onClick={handleExportToCSV}
          >
            {isExporting ? (
              <Loading01
                className="leading-icon h-5 w-5 animate-spin"
                aria-hidden="true"
              />
            ) : (
              <FileDownload03
                aria-hidden="true"
                className={twMerge(
                  'leading-icon h-5 w-5',
                  isExporting ? 'text-gray-400' : 'text-gray-700'
                )}
              />
            )}
            Export to CSV
          </button>
        </div>
      </div>
      <div>
        <Tooltip
          anchorSelect=".view-application-tooltip"
          content="View Application"
          place="top"
          className="tooltip"
        />
        <Tooltip id="split-placement-tooltip" className="tooltip" place="top" />
        <Tooltip id="notes" className="tooltip max-w-80" place="top" />
        <Tooltip
          id="additionalDetails"
          className="tooltip max-w-80"
          place="top"
        />
        <Tooltip
          id="currentLocationDescription"
          className="tooltip max-w-80"
          place="top"
        />
        <Tooltip
          id="medical-details-other"
          className="tooltip max-w-80"
          place="top"
        />
        <Tooltip id="allergies" className="tooltip max-w-80" place="top" />
        <Tooltip id="areas" className="tooltip max-w-80" place="top" />
        <Tooltip id="feederSchools" className="tooltip max-w-80" place="top" />
        <Tooltip id="key-contact-email" className="tooltip" place="top" />
        {applicationsFilterDataSources.includes(dataSource) &&
        (hasQueryRun || runOnMount) &&
        applicationsResults ? (
          applicationsColumns.length > 0 ? (
            <Table
              columns={applicationsColumns}
              data={applicationsResults.docs || []}
              isFetching={isApplicationsFetching}
              error={getErrorMessage(applicationsError)}
              paginationType="manual"
              onPaginationChange={setPagination}
              pagination={pagination}
              pageCount={applicationsResults?.totalPages}
              EmptyState={
                <div className="py-12 text-center">
                  <SearchRefraction
                    className="mx-auto h-8 w-8 text-gray-400"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">
                    No Applications Found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Try refactoring your query properties.
                  </p>
                </div>
              }
              className="my-3"
            />
          ) : (
            <Alert
              type="warning"
              className="mt-10"
              text="You have disabled all columns. Enable some in order to see the
              query results!"
            />
          )
        ) : null}
        {dataSource === 'BOOKINGS' &&
        (hasQueryRun || runOnMount) &&
        bookingsResults ? (
          compact(Object.values(toggledColumns)).length > 0 ? (
            <Table
              columns={bookingsColumns}
              data={bookingsResults.docs || []}
              isFetching={isBookingsFetching}
              error={getErrorMessage(bookingsError)}
              paginationType="manual"
              onPaginationChange={setPagination}
              pagination={pagination}
              pageCount={bookingsResults?.totalPages}
              columnVisibility={toggledColumns}
              EmptyState={
                <div className="py-12 text-center">
                  <SearchRefraction
                    className="mx-auto h-8 w-8 text-gray-400"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">
                    No Bookings Found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Try refactoring your query properties.
                  </p>
                </div>
              }
              className="my-3"
            />
          ) : (
            <Alert
              type="warning"
              className="mt-10"
              text="You have disabled all columns. Enable some in order to see the
              query results!"
            />
          )
        ) : null}
        {locationsFilterDataSources.includes(dataSource) &&
        (hasQueryRun || runOnMount) &&
        locationsResults ? (
          locationsColumns.length > 0 ? (
            <Table
              columns={locationsColumns}
              data={locationsResults.docs || []}
              isFetching={isLocationsFetching}
              error={getErrorMessage(locationsError)}
              paginationType="manual"
              onPaginationChange={setPagination}
              pagination={pagination}
              pageCount={locationsResults.totalPages}
              EmptyState={
                <div className="py-12 text-center">
                  <SearchRefraction
                    className="mx-auto h-8 w-8 text-gray-400"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  <h3 className="mt-2 text-sm font-semibold text-gray-900">
                    No Locations Found
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Try refactoring your query properties.
                  </p>
                </div>
              }
              className="my-3"
            />
          ) : (
            <Alert
              type="warning"
              className="mt-10"
              text="You have disabled all columns. Enable some in order to see the
              query results!"
            />
          )
        ) : null}
      </div>
      <ReportingDetailedViewDrawer />
      <ToggleColumnsDrawer
        isOpen={isOpen}
        closeModal={closeModal}
        dataSource={dataSource}
      />
    </div>
  );
}

export { OutputTable };
