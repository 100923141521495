import { locationKey } from '@/config/query-keys';
import { locationService } from '@/services/location.service';
import { HttpError } from '@/types/error';
import { LocationListing } from '@admissions-support/types';
import { QueryLocations } from '@admissions-support/types/dist/location/dtos';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useLocations(
  queryParams?: QueryLocations,
  queryOptions?: Partial<UseQueryOptions<LocationListing[], HttpError>>
) {
  return useQuery({
    queryKey: locationKey.list(queryParams),
    queryFn: () => locationService.getLocations(queryParams),
    ...queryOptions,
  });
}

export { useLocations };
