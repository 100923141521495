import * as yup from 'yup';

const additionalInfoFormSchema = yup.object({
  additionalDetails: yup.string().label('Supporting information').nullable(),
  additionalQuestionAnswers: yup.object().required(),
  subjectDetails: yup.array().of(
    yup.object({
      name: yup.string().required(),
      courseLevel: yup.string().required(),
      fromDate: yup.string().required(),
      toDate: yup.string().required(),
    })
  ),
});

export { additionalInfoFormSchema };
