import { LocationControl, LocationFilter } from '@/types/location-filter';
import { DeepPartial } from '@/types/util';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { merge } from 'lodash-es';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type LocationControlContextState = LocationControl & {
  setFilter: Dispatch<SetStateAction<LocationFilter>>;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
};

function transformLocalStorageFilter(storedFilter: string): LocationControl {
  const parsedControlObject = JSON.parse(storedFilter) as LocationControl;

  // we need to parse date from string to Date type
  return {
    ...parsedControlObject,
    filter: {
      ...parsedControlObject.filter,
    },
  };
}

const defaultLocationControl: LocationControl = {
  filter: {
    types: [],
    search: '',
  },
  sorting: [
    {
      id: 'name',
      desc: false,
    },
  ],
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
};

const LocationControlContext = createContext<
  LocationControlContextState | undefined
>(undefined);

function LocationControlProvider({
  children,
  defaultControl,
}: {
  children: React.ReactNode | React.ReactNode[];
  defaultControl?: DeepPartial<LocationControl>;
}) {
  const localStorageKey = `admit_location_control`;
  const localStorageItem = localStorage.getItem(localStorageKey);

  let persistedLocationControl: LocationControl;
  try {
    const programaticDefaultValue = localStorageItem
      ? transformLocalStorageFilter(localStorageItem)
      : defaultLocationControl;

    persistedLocationControl = merge(
      {},
      defaultLocationControl,
      programaticDefaultValue,
      defaultControl
    );
  } catch (error) {
    console.error('Failed to parse localStorageItem:', error);
    persistedLocationControl = merge(
      {},
      defaultLocationControl,
      defaultControl
    );
  }

  const [pagination, setPagination] = useState<PaginationState>(
    persistedLocationControl.pagination
  );

  const [sorting, setSorting] = useState<SortingState>(
    persistedLocationControl.sorting
  );

  const [filter, setFilter] = useState<LocationFilter>(
    persistedLocationControl.filter
  );

  const handleSetFilter = (state: SetStateAction<LocationFilter>) => {
    // navigate to the first page if filter changes
    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    setFilter(state);
  };

  const updatedLocationControl: LocationControl = useMemo(
    () => ({
      filter,
      sorting,
      pagination,
    }),
    [filter, sorting, pagination]
  );

  useEffect(() => {
    try {
      const serializedData = JSON.stringify(updatedLocationControl);
      localStorage.setItem(localStorageKey, serializedData);
    } catch (error) {
      console.error('Failed to serialize application control state:', error);
    }
  }, [updatedLocationControl, localStorageKey]);

  return (
    <LocationControlContext.Provider
      value={{
        ...updatedLocationControl,
        setFilter: handleSetFilter,
        setPagination,
        setSorting,
      }}
    >
      {children}
    </LocationControlContext.Provider>
  );
}

const useLocationControl = () => {
  const context = useContext(LocationControlContext);

  if (!context) {
    throw new Error(
      'useLocationControl must be used within a LocationControlProvider'
    );
  }
  return context;
};

export { LocationControlProvider, defaultLocationControl, useLocationControl };
