import { ApplicationListFlags } from '@/components/application/application-list-flags';
import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import {
  ChoiceCell,
  isNotSchoolApplicationChoice,
} from '@/components/application/choice-cell';
import {
  formatApplicationAddress,
  isApplicationWithASNChild,
  isSchoolApplication,
} from '@/utils/application-utils';
import {
  AllApplications,
  SchoolApplicationYearStage,
} from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { clone, compact, startCase } from 'lodash-es';
import { useMemo } from 'react';
import { BooleanCell } from '../cells/boolean-cell';
import { TextCell } from '../cells/text-cell';
import { CarersCell } from '../cells/carers-cell';
import { OwnersCell } from '../cells/owners-cell';
import { SiblingsCell } from '../cells/siblings-cell';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { exportableKeysApplications } from '@/utils/query-builder-utils';
import { format } from 'date-fns';
import { ToggledColumns } from '@/types/reporting-output-control';
import { applicationsFilterDataSources } from '@/pages/reporting/upsert-query-builder';
import { formatSchoolStage } from '@/utils/location-utils';

type UseOutputApplicationsTableColumnsProps = {
  dataSource: string;
};

const isApplicationsToggledColumns = (data: ToggledColumns) => {
  return (
    data.homeLanguage !== undefined &&
    data['choices.location.name'] !== undefined
  );
};

function useOutputApplicationsTableColumns(
  props: UseOutputApplicationsTableColumnsProps
) {
  const { dataSource } = props;
  const columnHelper = createColumnHelper<AllApplications>();

  const { toggledColumns, openModal, setSideDrawerContent } =
    useReportingOutputControl();

  const getToggledColumnsValuesThatStartWith = (string: string) => {
    /**
     * If dataSource is not APPLICATIONS, we need to create an array with the same length
     * in order to avoid inconsistencies with useMemo dependency arrays.
     * Also need to check if toggledColumns is changed since there is a rerender
     * where dataSource has already changed but toggledColumn hasn't.
     */
    if (
      !applicationsFilterDataSources.includes(dataSource) ||
      !isApplicationsToggledColumns(toggledColumns)
    ) {
      return exportableKeysApplications
        .filter(key => key.startsWith(string))
        .map(() => false);
    }

    const filteredToggledColumns = Object.values(
      Object.entries(toggledColumns)
        .filter(([key]) => {
          return key.startsWith(string);
        })
        .reduce((acc, curr) => {
          const [key, value] = curr;
          return { ...acc, [key]: value };
        }, {})
    );

    return filteredToggledColumns;
  };

  const isChildColumnEnabled =
    toggledColumns['child.firstName'] || toggledColumns['child.lastName'];

  const childColumnDeps = [
    toggledColumns['child.firstName'],
    toggledColumns['child.lastName'],
    toggledColumns['address.lineOne'],
    toggledColumns['address.lineTwo'],
    toggledColumns['address.lineThree'],
    toggledColumns['address.lineFour'],
    toggledColumns['address.postcode'],
  ];

  const childColumn = useMemo(
    () => [
      isChildColumnEnabled
        ? columnHelper.accessor('child', {
            id: 'name',
            cell: info => {
              const childName = `${
                toggledColumns['child.firstName']
                  ? info.row.original.child.firstName
                  : ''
              } ${
                toggledColumns['child.lastName']
                  ? info.row.original.child.lastName
                  : ''
              }`;
              const autoFlags = [];

              if (info.row.original.homeStatus === 'MOVING') {
                autoFlags.push('Moving to Area');
              }

              if (isApplicationWithASNChild(info.row.original.medicalDetails)) {
                autoFlags.push('ASN');
              }

              const address = clone(info.row.original.address);

              if (!toggledColumns['address.lineOne']) {
                address.lineOne = null;
              }
              if (!toggledColumns['address.lineTwo']) {
                address.lineTwo = null;
              }
              if (!toggledColumns['address.lineThree']) {
                address.lineThree = null;
              }
              if (!toggledColumns['address.lineFour']) {
                address.lineFour = null;
              }
              if (!toggledColumns['address.postcode']) {
                address.postcode = null;
              }

              const addressString = formatApplicationAddress(address);

              return (
                <p className="block text-sm">
                  <span className="flex items-center space-x-2 font-medium text-gray-900">
                    <span>{childName}</span>

                    <ApplicationListFlags application={info.row.original} />
                  </span>
                  {addressString}
                </p>
              );
            },
            header: () => <span>Applicant</span>,
            enableSorting: true,
            // size: 358,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...childColumnDeps]
  );

  const dobColumnDeps = [toggledColumns['dateOfBirth']];

  const dobColumn = useMemo(
    () => [
      toggledColumns['dateOfBirth']
        ? columnHelper.accessor('dateOfBirth', {
            id: 'date_of_birth',
            cell: info => {
              const colValue = info.getValue();

              const dateOfBirth = colValue
                ? format(new Date(colValue || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={dateOfBirth} />;
            },
            header: () => <span>DOB</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dobColumnDeps]
  );

  const statusColumnDependencies = [toggledColumns['status']];

  const statusColumn = useMemo(
    () => [
      toggledColumns.status
        ? columnHelper.accessor('status', {
            id: 'status',
            cell: info => (
              <ApplicationStatusTag status={info.getValue()}>
                {startCase(info.getValue().toLowerCase())}
              </ApplicationStatusTag>
            ),
            header: () => <span>Status</span>,
            enableSorting: false,
            // size: 183,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...statusColumnDependencies]
  );

  const choicesColumnDeps = getToggledColumnsValuesThatStartWith('choices');

  const choicesColumn = useMemo(
    () =>
      choicesColumnDeps.some(val => val)
        ? [
            columnHelper.accessor('choices', {
              id: 'choices_1',
              cell: info => {
                const choice = info.getValue()[0];

                if (!choice) {
                  return <p className="text-sm text-gray-500">-</p>;
                }

                const schoolYearId = info.row.original.schoolYear.id.toString();

                if (isNotSchoolApplicationChoice(choice)) {
                  const newSideDrawerContent = { ...choice, schoolYearId };

                  return (
                    <button
                      className="text-left"
                      type="button"
                      onClick={() => {
                        setSideDrawerContent(newSideDrawerContent);
                        openModal();
                      }}
                    >
                      <ChoiceCell choice={choice} />
                    </button>
                  );
                }

                return <ChoiceCell choice={choice} />;
              },
              header: () => <span>First Choice</span>,
              enableSorting: false,
            }),
            columnHelper.accessor('choices', {
              id: 'choices_2',
              cell: info => {
                const choice = info.getValue()[1];

                if (!choice) {
                  return <p className="text-sm text-gray-500">-</p>;
                }

                const schoolYearId = info.row.original.schoolYear.id.toString();

                if (isNotSchoolApplicationChoice(choice)) {
                  const newSideDrawerContent = { ...choice, schoolYearId };

                  return (
                    <button
                      className="text-left"
                      type="button"
                      onClick={() => {
                        setSideDrawerContent(newSideDrawerContent);
                        openModal();
                      }}
                    >
                      <ChoiceCell choice={choice} />
                    </button>
                  );
                }

                return <ChoiceCell choice={choice} />;
              },
              header: () => <span>Second Choice</span>,
              enableSorting: false,
            }),
            columnHelper.accessor('choices', {
              id: 'choices_3',
              cell: info => {
                const choice = info.getValue()[2];

                if (!choice) {
                  return <p className="text-sm text-gray-500">-</p>;
                }

                const schoolYearId = info.row.original.schoolYear.id.toString();

                if (isNotSchoolApplicationChoice(choice)) {
                  const newSideDrawerContent = { ...choice, schoolYearId };

                  return (
                    <button
                      className="text-left"
                      type="button"
                      onClick={() => {
                        setSideDrawerContent(newSideDrawerContent);
                        openModal();
                      }}
                    >
                      <ChoiceCell choice={choice} />
                    </button>
                  );
                }

                return <ChoiceCell choice={choice} />;
              },
              header: () => <span>Third Choice</span>,
              enableSorting: false,
            }),
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...choicesColumnDeps]
  );

  const schoolYearColumnDeps = [toggledColumns['schoolYear.name']];

  const schoolYearColumn = useMemo(
    () => [
      toggledColumns['schoolYear.name']
        ? columnHelper.accessor('schoolYear', {
            id: 'school_year',
            cell: info => {
              const schoolYear = info.getValue();

              return <TextCell cellValue={schoolYear?.name} />;
            },
            header: () => <span>School Year</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...schoolYearColumnDeps]
  );

  const cohortColumnDeps = [toggledColumns['cohort.name']];

  const cohortColumn = useMemo(
    () => [
      toggledColumns['cohort.name']
        ? columnHelper.accessor('cohort', {
            id: 'cohort',
            cell: info => {
              const cohort = info.getValue();

              return <TextCell cellValue={cohort?.name} />;
            },
            header: () => <span>Cohort</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...cohortColumnDeps]
  );

  const currentLocationNameColumnDeps = [
    toggledColumns['currentLocation.name'],
  ];

  const currentLocationNameColumn = useMemo(
    () => [
      toggledColumns['currentLocation.name']
        ? columnHelper.accessor('currentLocation', {
            id: 'current_location_name',
            cell: info => {
              if (isSchoolApplication(info.row.original)) {
                return;
              }

              const currentLocation = info.row.original.currentLocation;

              return <TextCell cellValue={currentLocation?.name} />;
            },
            header: () => <span>Current Location</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...currentLocationNameColumnDeps]
  );

  const currentLocationDescriptionColumnDeps = [
    toggledColumns['currentLocationDescription'],
  ];

  const currentLocationDescriptionColumn = useMemo(
    () => [
      toggledColumns['currentLocationDescription']
        ? columnHelper.accessor('currentLocationDescription', {
            id: 'current_location_description',
            cell: info => {
              if (isSchoolApplication(info.row.original)) {
                return;
              }

              const currentLocationDescription =
                info.row.original.currentLocationDescription;
              return (
                <TextCell
                  cellValue={currentLocationDescription}
                  tooltipId="currentLocationDescription"
                />
              );
            },
            header: () => <span>Current Location Description</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...currentLocationDescriptionColumnDeps]
  );

  const additionalNeedsColumnDeps = [toggledColumns.isChildWithAdditionalNeeds];

  const additionalNeedsColumn = useMemo(
    () => [
      toggledColumns.isChildWithAdditionalNeeds
        ? columnHelper.accessor('isChildWithAdditionalNeeds', {
            id: 'additional_needs',
            cell: info => {
              const isChilWithAdditionalNeeds = info.getValue();

              return <BooleanCell cellValue={isChilWithAdditionalNeeds} />;
            },
            header: () => <span>Additional Needs</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...additionalNeedsColumnDeps]
  );

  const homeLanguageColumnDeps = [toggledColumns.homeLanguage];

  const homeLanguageColumn = useMemo(
    () => [
      toggledColumns['homeLanguage']
        ? columnHelper.accessor('homeLanguage', {
            id: 'home_language',
            cell: info => {
              const homeLanguage = info.getValue();

              return <TextCell cellValue={homeLanguage} />;
            },
            header: () => <span>Home Language</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...homeLanguageColumnDeps]
  );

  const carersColumnDeps = getToggledColumnsValuesThatStartWith('carers');

  const carersColumn = useMemo(
    () => [
      carersColumnDeps.some(Boolean)
        ? columnHelper.accessor('carers', {
            id: 'carers',
            cell: info => {
              const carers = info.getValue();

              return <CarersCell carers={carers} />;
            },
            header: () => <span>Carers</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...carersColumnDeps]
  );

  const siblingsColumnDeps = getToggledColumnsValuesThatStartWith('siblings');

  const siblingsColumn = useMemo(
    () => [
      siblingsColumnDeps.some(Boolean)
        ? columnHelper.accessor('siblings', {
            id: 'siblings',
            cell: info => {
              const siblings = info.getValue();

              return <SiblingsCell siblings={siblings} />;
            },
            header: () => <span>Siblings</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...siblingsColumnDeps]
  );

  const ownersColumnDeps = getToggledColumnsValuesThatStartWith('child.owner');

  const ownersColumn = useMemo(
    () => [
      ownersColumnDeps.some(Boolean)
        ? columnHelper.accessor('child.owners', {
            id: 'owners',
            cell: info => {
              const owners = info.getValue();

              return <OwnersCell owners={owners} />;
            },
            header: () => <span>Owners</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...ownersColumnDeps]
  );

  const isArchivedColumnDeps = [toggledColumns.isArchived];

  const isArchivedColumn = useMemo(
    () => [
      toggledColumns['isArchived']
        ? columnHelper.accessor('isArchived', {
            id: 'is_archived',
            cell: info => {
              const isArchived = info.getValue();

              return <BooleanCell cellValue={isArchived} />;
            },
            header: () => <span>Is Archived</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isArchivedColumnDeps]
  );

  const notesColumnDeps = [toggledColumns.notes];

  const notesColumn = useMemo(
    () => [
      toggledColumns['notes']
        ? columnHelper.accessor('notes', {
            id: 'notes',
            cell: info => {
              const notes = info.getValue();

              return <TextCell cellValue={notes} tooltipId="notes" />;
            },
            header: () => <span>Notes</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...notesColumnDeps]
  );

  const additionalDetailsColumnDeps = [toggledColumns.additionalDetails];

  const additionalDetailsColumn = useMemo(
    () => [
      toggledColumns['additionalDetails']
        ? columnHelper.accessor('additionalDetails', {
            id: 'additional_details',
            cell: info => {
              const additionalDetails = info.getValue();

              return (
                <TextCell
                  cellValue={additionalDetails}
                  tooltipId="additionalDetails"
                />
              );
            },
            header: () => <span>Additional Details</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...additionalDetailsColumnDeps]
  );

  const homeStatusColumnDeps = [toggledColumns['homeStatus']];

  const homeStatusColumn = useMemo(
    () => [
      toggledColumns['homeStatus']
        ? columnHelper.accessor('homeStatus', {
            id: 'home_status',
            cell: info => {
              const homeStatus = info.getValue();

              return (
                <TextCell cellValue={startCase(homeStatus?.toLowerCase())} />
              );
            },
            header: () => <span>Home Status</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...homeStatusColumnDeps]
  );

  const moveInDateColumnDeps = [toggledColumns['moveInDate']];

  const moveInDateColumn = useMemo(
    () => [
      toggledColumns['moveInDate']
        ? columnHelper.accessor('moveInDate', {
            id: 'move_in_date',
            cell: info => {
              const moveInDate = info.getValue()
                ? format(new Date(info.getValue() || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={moveInDate} />;
            },
            header: () => <span>Move In Date</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...moveInDateColumnDeps]
  );

  const organisationNameColumnDeps = [toggledColumns['organisation.name']];

  const organisationNameColumn = useMemo(
    () => [
      toggledColumns['organisation.name']
        ? columnHelper.accessor('organisation.name', {
            id: 'oragnisation_name',
            cell: info => {
              const organisatioName = info.getValue();

              return (
                <TextCell
                  cellValue={startCase(organisatioName.toLowerCase())}
                />
              );
            },
            header: () => <span>Home Status</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...organisationNameColumnDeps]
  );

  const doctorNameColumnDeps = [toggledColumns['medicalDetails.doctorName']];

  const doctorNameColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.doctorName']
        ? columnHelper.accessor('medicalDetails.doctorName', {
            id: 'doctor_name',
            cell: info => {
              const doctorName = info.getValue();

              return <TextCell cellValue={doctorName} />;
            },
            header: () => <span>Doctor Name</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...doctorNameColumnDeps]
  );

  const healthVisitorColumnDeps = [
    toggledColumns['medicalDetails.healthVisitor'],
  ];

  const healthVisitorColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.healthVisitor']
        ? columnHelper.accessor('medicalDetails.healthVisitor', {
            id: 'health_visitor',
            cell: info => {
              const healthVisitor = info.getValue();

              return <TextCell cellValue={healthVisitor} />;
            },
            header: () => <span>Health Visitor</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...healthVisitorColumnDeps]
  );

  const surgeryNameColumnDeps = [toggledColumns['medicalDetails.surgeryName']];

  const surgeryNameColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.surgeryName']
        ? columnHelper.accessor('medicalDetails.surgeryName', {
            id: 'surgery_name',
            cell: info => {
              const surgeryName = info.getValue();

              return <TextCell cellValue={surgeryName} />;
            },
            header: () => <span>Surgery Name</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...surgeryNameColumnDeps]
  );

  const phoneNumberColumnDeps = [toggledColumns['medicalDetails.phoneNumber']];

  const phoneNumberColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.phoneNumber']
        ? columnHelper.accessor('medicalDetails.phoneNumber', {
            id: 'phone_number',
            cell: info => {
              const phoneNumber = info.getValue();

              return <TextCell cellValue={phoneNumber} />;
            },
            header: () => <span>Phone Number</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...phoneNumberColumnDeps]
  );

  const isVisuallyImpairedColumnDeps = [
    toggledColumns['medicalDetails.isVisuallyImpaired'],
  ];

  const isVisuallyImpairedColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isVisuallyImpaired']
        ? columnHelper.accessor('medicalDetails.isVisuallyImpaired', {
            id: 'visually_impaired',
            cell: info => {
              const isVisuallyImpaired = info.getValue();

              return <BooleanCell cellValue={isVisuallyImpaired} />;
            },
            header: () => <span>Is Visually Impaired</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isVisuallyImpairedColumnDeps]
  );

  const isEpilepticColumnDeps = [toggledColumns['medicalDetails.isEpileptic']];

  const isEpilepticColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isEpileptic']
        ? columnHelper.accessor('medicalDetails.isEpileptic', {
            id: 'epileptic',
            cell: info => {
              const isEpileptic = info.getValue();

              return <BooleanCell cellValue={isEpileptic} />;
            },
            header: () => <span>Is Epileptic</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isEpilepticColumnDeps]
  );

  const isDiabeticColumnDeps = [toggledColumns['medicalDetails.isDiabetic']];

  const isDiabeticColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isDiabetic']
        ? columnHelper.accessor('medicalDetails.isDiabetic', {
            id: 'diabetic',
            cell: info => {
              const isDiabetic = info.getValue();

              return <BooleanCell cellValue={isDiabetic} />;
            },
            header: () => <span>Is Diabetic</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isDiabeticColumnDeps]
  );

  const isAllergicColumnDeps = [toggledColumns['medicalDetails.isAllergic']];

  const isAllergicColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isAllergic']
        ? columnHelper.accessor('medicalDetails.isAllergic', {
            id: 'allergic',
            cell: info => {
              const isAllergic = info.getValue();

              return <BooleanCell cellValue={isAllergic} />;
            },
            header: () => <span>Is Allergic</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isAllergicColumnDeps]
  );

  const isHearingImpairedColumnDeps = [
    toggledColumns['medicalDetails.isHearingImpaired'],
  ];

  const isHearingImpairedColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isHearingImpaired']
        ? columnHelper.accessor('medicalDetails.isHearingImpaired', {
            id: 'hearing_impaired',
            cell: info => {
              const isHearingImpaired = info.getValue();

              return <BooleanCell cellValue={isHearingImpaired} />;
            },
            header: () => <span>Is Hearing Impaired</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isHearingImpairedColumnDeps]
  );

  const isAsmaticColumnDeps = [toggledColumns['medicalDetails.isAsmatic']];

  const isAsmaticColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isAsmatic']
        ? columnHelper.accessor('medicalDetails.isAsmatic', {
            id: 'asthmatic',
            cell: info => {
              const isAsthmatic = info.getValue();

              return <BooleanCell cellValue={isAsthmatic} />;
            },
            header: () => <span>Is Asthmatic</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isAsmaticColumnDeps]
  );

  const isSpeechImpairedColumnDeps = [
    toggledColumns['medicalDetails.isSpeechImpaired'],
  ];

  const isSpeechImpairedColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.isSpeechImpaired']
        ? columnHelper.accessor('medicalDetails.isSpeechImpaired', {
            id: 'speech_impaired',
            cell: info => {
              const isSpeechImpaired = info.getValue();

              return <BooleanCell cellValue={isSpeechImpaired} />;
            },
            header: () => <span>Is Speech Impaired</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isSpeechImpairedColumnDeps]
  );

  const otherColumnDeps = [toggledColumns['medicalDetails.other']];

  const otherColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.other']
        ? columnHelper.accessor('medicalDetails.other', {
            id: 'medical_details_other',
            cell: info => {
              const medicalDetailsOther = info.getValue();

              return (
                <TextCell
                  cellValue={medicalDetailsOther}
                  tooltipId="medical-details-other"
                />
              );
            },
            header: () => <span>Other Medical Details</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...otherColumnDeps]
  );

  const allergiesColumnDeps = [toggledColumns['medicalDetails.allergies']];

  const allergiesColumn = useMemo(
    () => [
      toggledColumns['medicalDetails.allergies']
        ? columnHelper.accessor('medicalDetails.allergies', {
            id: 'allergies',
            cell: info => {
              const allergies = info.getValue();

              return <TextCell cellValue={allergies} tooltipId="allergies" />;
            },
            header: () => <span>Allergies</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...allergiesColumnDeps]
  );

  const isSubjectToCourtDecisionColumnDeps = [
    toggledColumns['lookedAfter.isSubjectToCourtDecision'],
  ];

  const isSubjectToCourtDecisionColumn = useMemo(
    () => [
      toggledColumns['lookedAfter.isSubjectToCourtDecision']
        ? columnHelper.accessor('lookedAfter.isSubjectToCourtDecision', {
            id: 'court_decision',
            cell: info => {
              const isSubjectToCourtDecision = info.getValue();

              return <BooleanCell cellValue={isSubjectToCourtDecision} />;
            },
            header: () => <span>Subject to Court Decision</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isSubjectToCourtDecisionColumnDeps]
  );

  const careLocationColumnDeps = [toggledColumns['lookedAfter.careLocation']];

  const careLocationColumn = useMemo(
    () => [
      toggledColumns['lookedAfter.careLocation']
        ? columnHelper.accessor('lookedAfter.careLocation', {
            id: 'care_location',
            cell: info => {
              const careLocation = startCase(info.getValue()?.toLowerCase());

              return <TextCell cellValue={careLocation} />;
            },
            header: () => <span>Care Location</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...careLocationColumnDeps]
  );

  const relationshipColumnDeps = [toggledColumns['lookedAfter.relationship']];

  const relationshipColumn = useMemo(
    () => [
      toggledColumns['lookedAfter.relationship']
        ? columnHelper.accessor('lookedAfter.relationship', {
            id: 'looked_after_relationship',
            cell: info => {
              const relationship = startCase(info.getValue()?.toLowerCase());

              return <TextCell cellValue={relationship} />;
            },
            header: () => <span>Relationship</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...relationshipColumnDeps]
  );

  const otherRelationshipColumnDeps = [
    toggledColumns['lookedAfter.otherRelationship'],
  ];

  const otherRelationshipColumn = useMemo(
    () => [
      toggledColumns['lookedAfter.otherRelationship']
        ? columnHelper.accessor('lookedAfter.otherRelationship', {
            id: 'looked_after_other_relationship',
            cell: info => {
              const otherRelationship = info.getValue();

              return <TextCell cellValue={otherRelationship} />;
            },
            header: () => <span>Other Relationship</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...otherRelationshipColumnDeps]
  );

  const authorityColumnDeps = [toggledColumns['lookedAfter.authority']];

  const authorityColumn = useMemo(
    () => [
      toggledColumns['lookedAfter.authority']
        ? columnHelper.accessor('lookedAfter.authority', {
            id: 'looked_after_authority',
            cell: info => {
              const authority = info.getValue();

              return <TextCell cellValue={authority} />;
            },
            header: () => <span>Authority</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...authorityColumnDeps]
  );

  const hasDeferredChoiceColumnDeps = [
    toggledColumns['flagsAndOverrides.hasDeferredChoice'],
  ];

  const hasDeferredChoiceColumn = useMemo(
    () => [
      toggledColumns['flagsAndOverrides.hasDeferredChoice']
        ? columnHelper.accessor('flagsAndOverrides.hasDeferredChoice', {
            id: 'has_deferred_choice',
            cell: info => {
              const hasDeferredChoice = info.getValue();

              return <BooleanCell cellValue={hasDeferredChoice} />;
            },
            header: () => <span>Has Deferred Choice</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...hasDeferredChoiceColumnDeps]
  );

  const hasFlaggedChoiceColumnDeps = [
    toggledColumns['flagsAndOverrides.hasFlaggedChoice'],
  ];

  const hasFlaggedChoiceColumn = useMemo(
    () => [
      toggledColumns['flagsAndOverrides.hasFlaggedChoice']
        ? columnHelper.accessor('flagsAndOverrides.hasFlaggedChoice', {
            id: 'has_flagged_choice',
            cell: info => {
              const hasFlaggedChoice = info.getValue();

              return <BooleanCell cellValue={hasFlaggedChoice} />;
            },
            header: () => <span>Has Flagged Choice</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...hasFlaggedChoiceColumnDeps]
  );

  const isFlaggedColumnDeps = [toggledColumns['flagsAndOverrides.isFlagged']];

  const isFlaggedColumn = useMemo(
    () => [
      toggledColumns['flagsAndOverrides.isFlagged']
        ? columnHelper.accessor('flagsAndOverrides.isFlagged', {
            id: 'is_flagged',
            cell: info => {
              const isFlagged = info.getValue();

              return <BooleanCell cellValue={isFlagged} />;
            },
            header: () => <span>Is Flagged</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...isFlaggedColumnDeps]
  );

  const submittedAtColumnDeps = [toggledColumns['submittedAt']];

  const submittedAtColumn = useMemo(
    () => [
      toggledColumns['submittedAt']
        ? columnHelper.accessor('submittedAt', {
            id: 'submitted_at',
            cell: info => {
              const submittedAt = info.getValue()
                ? format(new Date(info.getValue() || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={submittedAt} />;
            },
            header: () => <span>Submitted At</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...submittedAtColumnDeps]
  );

  const createdAtColumnDeps = [toggledColumns['createdAt']];

  const createdAtColumn = useMemo(
    () => [
      toggledColumns['createdAt']
        ? columnHelper.accessor('createdAt', {
            id: 'created_at',
            cell: info => {
              const createdAt = info.getValue()
                ? format(new Date(info.getValue() || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={createdAt} />;
            },
            header: () => <span>Created At</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...createdAtColumnDeps]
  );

  const updatedAtColumnDeps = [toggledColumns['updatedAt']];

  const updatedAtColumn = useMemo(
    () => [
      toggledColumns['updatedAt']
        ? columnHelper.accessor('updatedAt', {
            id: 'updated_at',
            cell: info => {
              const updatedAt = info.getValue()
                ? format(new Date(info.getValue() || ''), 'dd/MM/yyyy')
                : '--';

              return <TextCell cellValue={updatedAt} />;
            },
            header: () => <span>Updated At</span>,
            enableSorting: false,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...updatedAtColumnDeps]
  );

  const yearStageColumnDeps = [toggledColumns['yearStage']];

  const yearStageColumn = useMemo(
    () => [
      toggledColumns['yearStage']
        ? columnHelper.accessor('yearStage', {
            id: 'yearStage',
            cell: info => {
              const stage = info.getValue() as SchoolApplicationYearStage;
              const formattedStage = formatSchoolStage(stage);
              const start = info.row.original.startDate.override;
              return (
                <div className="flex flex-col">
                  <span className="font-medium text-gray-900">
                    {formattedStage}
                  </span>
                  <span>{format(start, 'dd/MM/yyyy')}</span>
                </div>
              );
            },
            header: () => <span>Stage/Start</span>,
          })
        : null,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...yearStageColumnDeps]
  );

  /**
   * Need to separate columns depending on which state updates them
   * because not all columns need to be updated on 1 toggledColumns state change.
   * If these were in one big useMemo then every state change would trigger
   * a 2-3 second long computation which is not optimal.
   */
  return {
    columns: compact([
      ...childColumn,
      ...statusColumn,
      ...choicesColumn,
      ...schoolYearColumn,
      ...yearStageColumn,
      ...cohortColumn,
      ...dobColumn,
      ...additionalNeedsColumn,
      ...homeLanguageColumn,
      ...carersColumn,
      ...siblingsColumn,
      ...ownersColumn,
      ...isArchivedColumn,
      ...additionalDetailsColumn,
      ...notesColumn,
      ...homeStatusColumn,
      ...moveInDateColumn,
      ...organisationNameColumn,
      ...currentLocationNameColumn,
      ...currentLocationDescriptionColumn,
      ...doctorNameColumn,
      ...healthVisitorColumn,
      ...surgeryNameColumn,
      ...phoneNumberColumn,
      ...isVisuallyImpairedColumn,
      ...isEpilepticColumn,
      ...isDiabeticColumn,
      ...isAllergicColumn,
      ...isHearingImpairedColumn,
      ...isAsmaticColumn,
      ...isSpeechImpairedColumn,
      ...otherColumn,
      ...allergiesColumn,
      ...isSubjectToCourtDecisionColumn,
      ...careLocationColumn,
      ...relationshipColumn,
      ...otherRelationshipColumn,
      ...authorityColumn,
      ...hasDeferredChoiceColumn,
      ...hasFlaggedChoiceColumn,
      ...isFlaggedColumn,
      ...submittedAtColumn,
      ...createdAtColumn,
      ...updatedAtColumn,
    ]),
  };
}

export { useOutputApplicationsTableColumns };
