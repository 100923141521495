import { TextInput } from '@/components/form/common/text-input';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { CreateSessionTypeDto, SessionType } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Plus } from '@untitled-ui/icons-react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Alert } from '../alert';
import { SessionTypeItem } from '../session-type-item';
import { Checkbox } from './common/checkbox';
import { Toggle } from '@/components/form/common/toggle';
import { ChangeEvent } from 'react';
import { useModal } from '@/hooks/use-modal';
import { Modal } from '../modal';

const sessionTypeSchema = yup.object({
  name: yup.string().required().label('Name'),
  allowance: yup
    .number()
    .min(1)
    .required()
    .nullable()
    .label('Allowance')
    .typeError('Allowance must be a number'),
  isAdditionalSessionType: yup.boolean().default(false).required(),
  hasFlexiblePlacementEnabled: yup.boolean().default(false).required(),
  operatingDays: yup
    .object({
      monday: yup.boolean().required(),
      tuesday: yup.boolean().required(),
      wednesday: yup.boolean().required(),
      thursday: yup.boolean().required(),
      friday: yup.boolean().required(),
      saturday: yup.boolean().required(),
      sunday: yup.boolean().required(),
    })
    .test('at-least-one-true', 'You must select at least one day!', value => {
      return Object.values(value).some(day => day === true);
    }),
  splitPlacement: yup
    .object({
      isEnabled: yup.boolean().required(),
      isEnabledForAdditional: yup.boolean().required(),
      hasMinimumDaysAtLocation: yup.boolean().required(),
      minimumDaysAtLocation: yup
        .number()
        .label('Sessions per Location')
        .required(),
    })
    .when('allowance', ([allowance], schema) => {
      return schema.shape({
        isEnabled: yup.boolean().required(),
        isEnabledForAdditional: yup.boolean().required(),
        hasMinimumDaysAtLocation: yup.boolean().required(),
        minimumDaysAtLocation: yup
          .number()
          .max(allowance)
          .label('Sessions per Location')
          .required(),
      });
    }),
  additionalSessionTypes: yup
    .array(
      yup
        .array(
          yup.object({
            id: yup.string().required('This field is required'),
            name: yup.string(),
            allowance: yup
              .number()
              .min(1)
              .typeError('Allowance must be a number')
              .required('This field is required')
              .nullable()
              .label('Allowance'),
          })
        )
        .required()
    )
    .required(),
});

type SessionTypeFormProps = {
  onSubmit: (data: CreateSessionTypeDto) => Promise<SessionType>;
  initialData?: SessionType;
  isLoading?: boolean;
  sessionTypes: SessionType[];
};

type SessionTypeFormData = yup.InferType<typeof sessionTypeSchema>;

function transformApiDataToFormData(
  sessionType?: SessionType
): SessionTypeFormData {
  if (!sessionType) {
    return {
      name: '',
      allowance: null,
      isAdditionalSessionType: false,
      hasFlexiblePlacementEnabled: false,
      operatingDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      },
      additionalSessionTypes: [],
      splitPlacement: {
        isEnabled: false,
        isEnabledForAdditional: false,
        hasMinimumDaysAtLocation: false,
        minimumDaysAtLocation: 1,
      },
    };
  }

  return {
    name: sessionType.name,
    allowance: sessionType.allowance,
    isAdditionalSessionType: sessionType.isAdditionalSessionType || false,
    hasFlexiblePlacementEnabled:
      sessionType.hasFlexiblePlacementEnabled ?? false,
    operatingDays: sessionType.operatingDays,
    additionalSessionTypes: sessionType.additionalSessionTypes,
    splitPlacement: sessionType.splitPlacement,
  };
}

function SessionTypeForm(props: SessionTypeFormProps) {
  const { initialData, isLoading, onSubmit, sessionTypes } = props;
  const isEditSessionType = Boolean(initialData);

  const { openModal, closeModal, isOpen } = useModal();

  const defaultValues: SessionTypeFormData =
    transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(sessionTypeSchema),
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'additionalSessionTypes',
  });

  const isSplitPlacementEnabled = form.watch('splitPlacement.isEnabled');
  const minimumDaysAtLocation = form.watch(
    'splitPlacement.minimumDaysAtLocation'
  );

  const submitHandler = async (data: SessionTypeFormData) => {
    const convertedData: CreateSessionTypeDto = {
      ...data,
      allowance: Number(data.allowance) || 0,
      splitPlacement: {
        isEnabled: data.splitPlacement?.isEnabled,
        isEnabledForAdditional: data.splitPlacement?.isEnabledForAdditional,
        hasMinimumDaysAtLocation: data.splitPlacement?.hasMinimumDaysAtLocation,
        minimumDaysAtLocation: data.splitPlacement?.hasMinimumDaysAtLocation
          ? data.splitPlacement.minimumDaysAtLocation
          : 1,
      },
      additionalSessionTypes: data.additionalSessionTypes?.map(orArray =>
        orArray.map(sessionType => ({
          id: sessionType.id,
          allowance: Number(sessionType.allowance) || 0,
        }))
      ),
    };

    try {
      const updatedSessionType = await onSubmit(convertedData);
      form.reset(transformApiDataToFormData(updatedSessionType));
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const handleAddAdditionalSessionType = () => {
    append([
      [
        {
          id: '',
          allowance: null,
        },
      ],
    ]);
  };

  /**
   * This function handles the change events when split placements are enabled or disabled
   * Also resets the connected form values when the field gets disabled
   * @param e
   */
  const handleSplitPlacementsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    form.setValue('splitPlacement.isEnabled', value);

    // Reset the values when the field gets disabled
    if (!value) {
      form.setValue('splitPlacement.minimumDaysAtLocation', 1);
      form.setValue('splitPlacement.hasMinimumDaysAtLocation', false);
    }
  };

  const handleMinDaysAtLocationChange = (value: boolean) => {
    form.setValue('splitPlacement.minimumDaysAtLocation', value ? 2 : 1);
    form.setValue('splitPlacement.hasMinimumDaysAtLocation', value);
  };

  const isAdditionalSessionType = form.watch('isAdditionalSessionType');
  const hasFlexiblePlacementEnabled = form.watch('hasFlexiblePlacementEnabled');

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="flex h-full flex-1 flex-col"
        id="upsert-session-type-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Details
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Specify session type details
              </p>
            </div>

            <div className="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <div className="space-y-6">
                  {isEditSessionType && (
                    <Alert
                      type="warning"
                      text={
                        <>
                          <p className="mb-1 font-medium">Attention!</p>
                          <p>
                            Updating or deleting a session type will affect all{' '}
                            <b>sessions</b> that use this session type.
                          </p>
                        </>
                      }
                    />
                  )}
                  <TextInput
                    name="name"
                    type="text"
                    label="Session Type Name*"
                    disabled={isLoading}
                  />
                  <TextInput
                    name="allowance"
                    type="number"
                    min={1}
                    label="Allowance*"
                    disabled={isLoading}
                    helperText="Number of sessions per week of this session type"
                  />
                  <Toggle
                    label="Additional Session Type"
                    description="Don’t display as within session types list during application form."
                    value={isAdditionalSessionType}
                    onChange={newVal =>
                      form.setValue('isAdditionalSessionType', newVal)
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="two-col-form">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Operating Days*
              </h2>
              <p className="text-md leading-6 text-gray-600">
                What days can this session be selected
              </p>
            </div>

            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4 md:col-span-2 xl:grid-cols-6">
              <div className="col-span-12 grid grid-cols-3 gap-x-4 gap-y-8 md:col-span-4 2xl:grid-cols-4">
                <Checkbox
                  name="operatingDays.monday"
                  label="Monday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.tuesday"
                  label="Tuesday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.wednesday"
                  label="Wednesday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.thursday"
                  label="Thursday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.friday"
                  label="Friday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.saturday"
                  label="Saturday"
                  disabled={isLoading}
                />
                <Checkbox
                  name="operatingDays.sunday"
                  label="Sunday"
                  disabled={isLoading}
                />
              </div>
            </div>
            {form.formState.errors.operatingDays ? (
              <p className="mt-1.5 text-sm text-red-600">
                {form.formState.errors.operatingDays.root?.message}
              </p>
            ) : null}
          </div>

          <div className="two-col-form">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Flexible Placement
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Allow the option of attending any session within their
                allowance.
              </p>
            </div>

            <div className="col-span-2">
              <div className="space-y-4">
                <Toggle
                  label={
                    <>
                      Enable <em className="italic">Any</em>
                    </>
                  }
                  description="This will add an option to their choice selection."
                  value={hasFlexiblePlacementEnabled}
                  onChange={newVal => {
                    if (newVal) {
                      openModal();
                    } else {
                      form.setValue('hasFlexiblePlacementEnabled', false);
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="two-col-form">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Split Placements
              </h2>
              <p className="text-md leading-6 text-gray-600">
                What days can this session be selected
              </p>
            </div>

            <div className="col-span-2">
              <div className="space-y-4">
                <Checkbox
                  name="splitPlacement.isEnabled"
                  label="Enable Split Placements"
                  disabled={isLoading}
                  onChange={handleSplitPlacementsChange}
                />
                {isSplitPlacementEnabled ? (
                  <Toggle
                    value={minimumDaysAtLocation > 1}
                    onChange={handleMinDaysAtLocationChange}
                    label="Require a minimum number of sessions per location?"
                    description="Do you require children to attend at least 2 sessions per location? This excludes any additional sessions."
                  />
                ) : null}
                <div className="h-[1px] w-full bg-gray-900/10" />
                <Checkbox
                  name="splitPlacement.isEnabledForAdditional"
                  label="Enable Split Placements for Additional Session"
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>

          <div className="two-col-form">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                Additional Session Types
              </h2>
              <p className="text-md leading-6 text-gray-600">
                Are there additional session types permitted? You can build rule
                groups to applicants to select between multiple session types.
              </p>
            </div>

            <div className="col-span-2">
              <div className="space-y-4">
                {fields.map((field, index) => (
                  <SessionTypeItem
                    key={field.id}
                    index={index}
                    onRemove={() => remove(index)}
                    sessionTypes={sessionTypes}
                    disabled={isLoading}
                  />
                ))}
                <button
                  type="button"
                  className="btn btn-secondary flex items-center"
                  onClick={handleAddAdditionalSessionType}
                  disabled={isLoading}
                >
                  <Plus
                    className="mr-1 inline h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  Add Session Type
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={isOpen}
          onClose={closeModal}
          title="Enable flexible placement"
          description="Are you sure you want to enable flexible placement for this session type? This action is irreversible."
          primaryBtnText="Enable flexible placement"
          secondaryBtnText="Cancel"
          secondaryAction={closeModal}
          type="warning"
          primaryAction={() => {
            form.setValue('hasFlexiblePlacementEnabled', true);
            closeModal();
          }}
        />
      </form>
    </FormProvider>
  );
}

export { SessionTypeForm };
