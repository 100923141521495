import { staffRouterPath } from '@/config/route-paths.config';
import { useSettings } from '@/context/settings.context';
import { useHasUnreadMessages } from '@/hooks/query-hooks/use-has-unread-messages';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { usePlatformUsersSubnavigation } from '@/hooks/use-platform-users-subnavigation';
import { useSettingsSubnavigation } from '@/hooks/use-settings-subnavigation';
import { Permission } from '@/types/auth';
import { NavigationItem } from '@/types/navigation';
import * as Sentry from '@sentry/react';
import {
  ArrowLeft,
  CalendarPlus02,
  GraduationHat02,
  LayersThree01,
  MessageChatSquare,
  PieChart03,
  Settings01,
  UserSquare,
} from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { CookieBanner } from '../cookie-banner';
import { FallbackComponent } from '../fallback-component';
import { MobileNavigation } from '../mobile-navigation';
import { Sidebar } from '../sidebar';

type LayoutProps = {
  children?: React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useSettings();
  const [navigation, setNavigation] = useState<NavigationItem[]>([]);
  const { hasPermission } = usePermissions();
  const location = useLocation();
  const platformUsersChildren = usePlatformUsersSubnavigation();
  const settingsNavigation = useSettingsSubnavigation();
  const { data: unreadMessagesData } = useHasUnreadMessages();

  useEffect(() => {
    const newNavigation: NavigationItem[] = [];

    if (hasPermission([Permission['application:fetch']])) {
      newNavigation.push(
        {
          name: 'ELC Applications',
          href: '/applications',
          icon: LayersThree01,
        },
        {
          name: 'Primary Applications',
          href: '/applications-primary',
          icon: LayersThree01,
        },
        {
          name: 'Secondary Applications',
          href: '/applications-secondary',
          icon: LayersThree01,
        }
      );
    }

    if (hasPermission([Permission['location:fetch']])) {
      newNavigation.push({
        name: 'Locations',
        href: '/locations',
        icon: GraduationHat02,
      });
    }

    if (hasPermission([Permission['extra-hours']])) {
      newNavigation.push({
        name: 'Extra Hours Bookings',
        href: '/extra-hours-bookings',
        icon: CalendarPlus02,
      });
    }

    if (hasPermission([Permission['messaging:fetch']])) {
      newNavigation.push({
        name: 'Messaging',
        href: staffRouterPath.MESSAGING,
        icon: MessageChatSquare,
        hasNewMarking: unreadMessagesData?.hasUnreadMessages || false,
      });
    }

    if (hasPermission([Permission['reporting']])) {
      newNavigation.push({
        name: 'Reporting',
        href: '/reporting',
        icon: PieChart03,
      });
    }

    if (
      hasPermission([Permission['users:fetch']]) ||
      hasPermission([Permission['organisation:user-groups:retrieve']])
    ) {
      newNavigation.push({
        name: 'Platform Users',
        href: staffRouterPath.PLATFORM_USERS_MAIN,
        icon: UserSquare,
        children: platformUsersChildren,
      });
    }

    setNavigation(newNavigation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadMessagesData?.hasUnreadMessages]);

  const bottomNavigation: NavigationItem[] = [];

  if (
    hasPermission([Permission['organisation:create']]) ||
    hasPermission([Permission['organisation:update']]) ||
    hasPermission([Permission['organisation:delete']])
  ) {
    bottomNavigation.push({
      name: 'Settings',
      href: '/settings',
      icon: Settings01,
      children: settingsNavigation,
    });
  }

  const toggleCollapsedStatus = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <div
        className={twMerge(
          'h-full',
          isSidebarCollapsed ? 'collapsed-sidebar' : null
        )}
      >
        <MobileNavigation
          navigation={navigation}
          bottomNavigation={bottomNavigation}
        />
        {/* Static sidebar for desktop */}
        <div
          className={twMerge(
            'group/sidebar hidden transition-all lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:flex-col',
            isSidebarCollapsed ? 'lg:w-[72px]' : 'lg:w-72'
          )}
          data-is-sidebar-collapsed={isSidebarCollapsed ? 'true' : undefined}
        >
          <div
            className={twMerge(
              'top-12 hidden -translate-x-1/2 transition-all lg:fixed lg:z-50 group-hover/sidebar:lg:flex',
              isSidebarCollapsed ? 'left-[72px]' : 'left-72'
            )}
          >
            <button
              onClick={toggleCollapsedStatus}
              className="flex h-6 w-6 items-center justify-center rounded-full border-2 border-primary-700 bg-primary-600 hover:bg-primary-700"
            >
              <ArrowLeft
                aria-hidden="true"
                className={twMerge(
                  'h-3 w-3 text-white transition-all',
                  isSidebarCollapsed ? 'rotate-180' : null
                )}
                viewBox="0 0 24 24"
              />
              <span className="sr-only">
                {isSidebarCollapsed ? 'Open sidebar' : 'Close sidebar'}
              </span>
            </button>
          </div>

          <Sidebar
            navigation={navigation}
            bottomNavigation={bottomNavigation}
          />
        </div>

        <div
          className={twMerge(
            isSidebarCollapsed ? 'lg:pl-[72px]' : 'lg:pl-72',
            'h-full transition-all'
          )}
        >
          <main className="mt-14 h-full px-4 py-6 sm:px-6 lg:mt-0 lg:px-8">
            <Sentry.ErrorBoundary
              fallback={props => <FallbackComponent {...props} />}
              key={location.pathname}
              showDialog
            >
              {children}
            </Sentry.ErrorBoundary>
          </main>
        </div>
      </div>
      <CookieBanner />
    </>
  );
}

export { Layout };
