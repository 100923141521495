import { Transition } from '@headlessui/react';

import { Popover } from '@headlessui/react';
import { ChevronDown, XClose } from '@untitled-ui/icons-react';
import { ReactNode, useEffect } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { twMerge } from 'tailwind-merge';

type HandleCloseProps = {
  onClose?: () => void;
  isOpen: boolean;
};

// This component is used to create an onClose event for the popover, because it doesn't provide one
// The reason we have this extracted is because the ESLint rules (react-hooks/rules-of-hooks specifically)
// don't allow us to place the useEffect hook inside the wrapper function of the Popover component ({({ open }) => ()
function HandleClose({ onClose, isOpen }: HandleCloseProps) {
  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // We return null because we don't want to render anything
  return null;
}

type FilterItemProps = {
  counter: number;
  reset: () => void;
  label: string;
  children: ReactNode;
  renderSelectedItems?: ReactNode;
  onClose?: () => void;
};

function FilterItem(props: FilterItemProps) {
  const { counter, reset, label, children, renderSelectedItems, onClose } =
    props;

  return (
    <Popover as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          <HandleClose isOpen={open} onClose={onClose} />
          <div className="relative flex rounded-md bg-white text-gray-700 shadow-sm ring-1 ring-gray-300">
            <Popover.Button
              className={twMerge(
                'btn btn-secondary btn-empty btn-icon py-2 ring-0',
                counter !== 0 ? 'br-0 rounded-br-none rounded-tr-none' : ''
              )}
            >
              <span className="mr-2 py-0.5 font-semibold">{label}</span>
              {counter > 0 && renderSelectedItems}
              {counter > 1 && (
                <span className="ml-1 mt-0.5 text-xs text-gray-500">
                  +{counter - 1}
                </span>
              )}
              <ChevronDown
                viewBox="0 0 24 24"
                className="trailing-icon ui-open:rotate-180 ui-open:transform"
                aria-hidden="true"
              />
            </Popover.Button>
            {counter !== 0 ? (
              <button
                className="btn btn-secondary btn-empty bl-0 rounded-bl-none rounded-tl-none bg-transparent pl-0 ring-0"
                onClick={reset}
              >
                <XClose
                  viewBox="0 0 24 24"
                  className="trailing-icon m-0"
                  aria-hidden="true"
                />
              </button>
            ) : null}
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute left-0 z-50 mt-2 max-h-96 min-w-[200px] overflow-y-auto rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="max-h-96 space-y-2 overflow-y-auto overflow-x-visible px-4 py-3">
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export { FilterItem };
