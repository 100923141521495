import { FallbackComponent } from '@/components/fallback-component';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { LoadingScreen } from '@/components/loading-screen';
import { ManageProductModal } from '@/components/locations/manage-product-modal';
import { ManageProductPriceModal } from '@/components/locations/manage-product-price-modal';
import { ProductsTableSection } from '@/components/locations/products-table-section';
import { PurchaseExtraHoursNavigation } from '@/components/locations/purchase-extra-hours-navigation';
import { Modal } from '@/components/modal';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { productKey } from '@/config/query-keys';
import { ProductControlProvider } from '@/context/products-control.context';
import { useDeleteProductMutation } from '@/hooks/delete-hooks/use-delete-product-mutation';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { getErrorMessage } from '@/utils/get-error-message';
import { ProductListing } from '@admissions-support/types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

function ProductsPage() {
  const { locationId = '' } = useParams();

  const [selectedProduct, setSelectedProduct] = useState<
    ProductListing | undefined
  >();
  const [productPriceToEdit, setProductPriceToEdit] = useState<
    ProductListing | undefined
  >();
  const [productToDelete, setProductToDelete] = useState<
    ProductListing | undefined
  >();

  const {
    isLoading: isLocationLoading,
    data: location,
    isError: isLocationError,
    isSuccess: isLocationSuccess,
  } = useLocation(locationId);

  const {
    isOpen: isManageProductModalOpen,
    openModal: openManageProductModal,
    closeModal: closeManageProductModal,
  } = useModal();

  const {
    isOpen: isManageProductPriceModalOpen,
    openModal: openManageProductPriceModal,
    closeModal: closeManageProductPriceModal,
  } = useModal();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();

  const { data: operation, isSuccess: isOperationSuccess } =
    useLocationOperation(locationId);

  const {
    mutateAsync: deleteProduct,
    isPending: isDeleting,
    error: deletionError,
  } = useDeleteProductMutation();

  const handleClickEditProduct = (product: ProductListing) => {
    setSelectedProduct(product);
    openManageProductModal();
  };
  const handleClickEditProductPrice = (product: ProductListing) => {
    setProductPriceToEdit(product);
    openManageProductPriceModal();
  };
  const handleDeleteProductClick = (product: ProductListing) => {
    setProductToDelete(product);
    openDeleteModal();
  };

  const handleDeleteProduct = async () => {
    if (!productToDelete) {
      return;
    }

    await deleteProduct(productToDelete.id);
    await queryClient.refetchQueries({
      queryKey: productKey.list,
    });

    closeDeleteModal();
  };

  const isLoading = isLocationLoading;

  const isError = isLocationError;

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError || !isLocationSuccess) {
    return <FallbackComponent />;
  }

  const hasOperationDefined =
    isOperationSuccess && operation.extraHoursResources.length > 0;

  return (
    <div>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      />
      <LocationTabNavigation id={locationId} />

      <div className="mt-6 flex items-center justify-between">
        <PurchaseExtraHoursNavigation />
        {hasOperationDefined && (
          <button
            className="btn btn-secondary"
            type="button"
            onClick={openManageProductModal}
          >
            Create Product
          </button>
        )}
      </div>

      <ProductControlProvider>
        <ProductsTableSection
          onEditProduct={handleClickEditProduct}
          onChangePrice={handleClickEditProductPrice}
          onDelete={handleDeleteProductClick}
        />
      </ProductControlProvider>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => {
          closeDeleteModal();
          setProductToDelete(undefined);
        }}
        title="Confirm Product deletion!"
        description="You can only delete Product which has no associated bookings. This action is reversible."
        primaryBtnText="Confirm Deletion"
        primaryAction={handleDeleteProduct}
        secondaryBtnText="Cancel"
        secondaryAction={closeDeleteModal}
        type="error"
        error={getErrorMessage(deletionError)}
        isLoading={isDeleting}
      />

      <ManageProductModal
        initialProduct={selectedProduct}
        isOpen={isManageProductModalOpen}
        onClose={closeManageProductModal}
        afterLeave={() => setSelectedProduct(undefined)}
      />

      <ManageProductPriceModal
        initialProduct={productPriceToEdit}
        isOpen={isManageProductPriceModalOpen}
        onClose={closeManageProductPriceModal}
        afterLeave={() => setProductPriceToEdit(undefined)}
      />
    </div>
  );
}

export { ProductsPage };
