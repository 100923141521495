import { SchoolPlacementPreferencesForm } from '@/components/application/placement-preferences/school-placement-preferences.form';
import { PlacementPreferencesForm } from '@/components/form/placement-preferences.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { applicationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useListSchoolLocations } from '@/hooks/query-hooks/use-list-school-locations';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { Permission } from '@/types/auth';
import { Application } from '@admissions-support/types';
import { useIsMutating } from '@tanstack/react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

function PlacementPreferences() {
  const navigate = useNavigate();
  const nbOfMutation = useIsMutating({
    mutationKey: applicationKey.updateApplication,
  });

  const params = useParams<{ id: string }>();
  const applicationId = params.id || '';

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(applicationId, {
    enabled: Boolean(applicationId),
  });

  const {
    data: locationsData,
    error: locationError,
    isLoading: isLocationDataLoding,
  } = useLocations(undefined, {
    select: locations =>
      //hide archived/inactive locations
      locations.filter(location => location.status === 'ACTIVE'),
  });

  const { mutateAsync: updateApplication, isPending: isApplicationUpdating } =
    useUpdateApplicationMutation(applicationId);
  const { hasPermission } = usePermissions();

  const isFormDisabled =
    isApplicationUpdating || !hasPermission([Permission['application:update']]);

  const locations = Array.isArray(locationsData)
    ? locationsData.map(location => ({
        key: location.id,
        value: location.name,
      }))
    : [];

  const { data: applicationTemplate } = useApplicationTemplate(
    application?.template?.id?.toString() || '',
    {
      enabled: !!application?.template?.id,
    }
  );

  const { data: schoolLocations, isLoading: isSchoolLocationsLoading } =
    useListSchoolLocations();

  const isFormLoading =
    isApplicationLoading || isLocationDataLoding || isSchoolLocationsLoading;

  if (applicationError || locationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  if (isApplicationLoading) {
    return (
      <>
        <PageTitle
          title="Edit Application"
          variant="gray"
          className="py-3 lg:py-5"
        />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  const isNurseryApplication = applicationTemplate?.applicationType === 'ELC';

  const backToApplications = () => {
    switch (applicationTemplate?.applicationType) {
      case 'ELC':
        navigate(staffRouterPath.APPLICATIONS);
        break;
      case 'PRIMARY':
        navigate(staffRouterPath.APPLICATIONS_PRIMARY);
        break;
      case 'SECONDARY':
        navigate(staffRouterPath.APPLICATIONS_SECONDARY);
        break;
    }
  };

  return (
    <div>
      <PageTitle
        title="Edit Application"
        variant="gray"
        className="top-0 z-10 mt-[52px] py-3 lg:sticky lg:mt-0 lg:py-5"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={backToApplications}
          >
            Cancel
          </button>

          <button
            className="btn btn-primary"
            form="upsert-application-form"
            disabled={Boolean(nbOfMutation) || isFormDisabled}
          >
            Save Changes
          </button>
        </div>
      </PageTitle>
      {!isFormLoading && application && locations ? (
        isNurseryApplication ? (
          <PlacementPreferencesForm
            initialData={application as Application}
            locations={locations}
            onSubmit={updateApplication}
          />
        ) : (
          <SchoolPlacementPreferencesForm
            schoolLocations={schoolLocations || []}
            initialData={application}
            schoolType={
              applicationTemplate?.applicationType as 'PRIMARY' | 'SECONDARY'
            }
          />
        )
      ) : (
        <div className="flex items-center justify-center px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { PlacementPreferences };
