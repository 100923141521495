import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { UpdateOverviewForm } from '@/components/settings/application-templates/update-overview-form';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationTemplateNavigation } from './application-template-navigation';

function UpdateApplicationTemplate() {
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();

  const {
    data: applicationTemplate,
    isLoading: isApplicationTemplateLoading,
    isSuccess: isApplicationTemplateLoaded,
  } = useApplicationTemplate(params.id || '');

  const handleCancel = () => {
    navigate(staffRouterPath.APPLICATION_TEMPLATES);
  };

  return (
    <div>
      <PageTitle title="Edit Form" variant="gray">
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button className="btn btn-primary" form="update-overview-form">
            Update
          </button>
        </div>
      </PageTitle>
      <ApplicationTemplateNavigation />
      {isApplicationTemplateLoading && <LoadingScreen className="mt-12" />}
      {isApplicationTemplateLoaded && (
        <UpdateOverviewForm initialData={applicationTemplate} />
      )}
    </div>
  );
}

export { UpdateApplicationTemplate };
