import {
  Question,
  QuestionCategory,
  QuestionType,
} from '@/types/application-template';
import { QuestionItem } from './question-item';

const questions: Question[] = [
  {
    id: 'local-authority-details',
    type: QuestionType.Optional,
    category: QuestionCategory.ChildDetails,
    title: 'Local Authority Details',
    description:
      'Provide details relating to current or previous local authorities you have lived in.',
  },
  {
    id: 'parent-carer-1',
    type: QuestionType.System,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Parent/Carer 1',
    description: 'Parent carer details',
  },
  {
    id: 'parent-carer-2',
    type: QuestionType.System,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Parent/Carer 2',
    description: 'Parent carer details',
  },
  {
    id: 'parent-carer-can-collect-child',
    type: QuestionType.Optional,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Can collect child?',
    description:
      'This parent/carer can collect child from their nursery setting',
  },
  {
    id: 'parent-carer-emergency-contact',
    type: QuestionType.Optional,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Emergency Contact',
    description: 'Can be contact in case of emergency',
  },
  {
    id: 'parent-carer-occupation',
    type: QuestionType.Optional,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Place of Work/Training/Education',
  },
  {
    id: 'parent-carer-occupation-schedule',
    type: QuestionType.Optional,
    category: QuestionCategory.ParentCarerDetails,
    title: 'Days & Hours of Work/Training/Education',
  },
  {
    id: 'other-children',
    type: QuestionType.System,
    category: QuestionCategory.HouseholdDetails,
    title: 'Other Children in the Household',
    description: 'Children are aged 16 and under.',
  },
  {
    id: 'home-language',
    type: QuestionType.System,
    category: QuestionCategory.HouseholdDetails,
    title: 'Home Language',
  },
  {
    id: 'current-nursery',
    type: QuestionType.System,
    category: QuestionCategory.PlacementPreferences,
    title: 'Current Nursery',
    description:
      'Please give details of any other type of pre-school your child goes to or has gone to.',
  },
  {
    id: 'placement-preferences',
    type: QuestionType.System,
    category: QuestionCategory.PlacementPreferences,
    title: 'Your Placement Preferences',
    description:
      'You can select three attendance patterns, in order of preference.',
  },
  {
    id: 'placement-preferences',
    type: QuestionType.System,
    category: QuestionCategory.AdditionalDetails,
    title: 'Other information',
  },
  {
    id: 'placement-preferences-2',
    type: QuestionType.Optional,
    category: QuestionCategory.AdditionalDetails,
    title: 'Other information 2',
  },
  {
    id: 'religion',
    type: QuestionType.System,
    category: QuestionCategory.ChildDetails,
    title: 'Religion',
    allowedLocationType: ['PRIMARY', 'SECONDARY'],
  },
  {
    id: 'placement-preferences-primary',
    type: QuestionType.System,
    category: QuestionCategory.PlacementPreferences,
    title: 'Primary / Secondary School Placement Preferences',
    allowedLocationType: ['PRIMARY', 'SECONDARY'],
  },
  {
    id: 'subject-details',
    type: QuestionType.System,
    category: QuestionCategory.AdditionalDetails,
    title: 'Secondary School - Subject Details',
    allowedLocationType: ['SECONDARY'],
  },
  {
    id: 'additional-support-needs',
    type: QuestionType.System,
    category: QuestionCategory.ChildDetails,
    title: 'Additional Support Needs',
    allowedLocationType: ['PRIMARY', 'SECONDARY'],
  },
];

type QuestionListProps = {
  active: QuestionCategory;
  locationType?: 'PRIMARY' | 'SECONDARY' | 'ELC';
};

function QuestionList({ active }: QuestionListProps) {
  const filteredList = questions.filter(q => q.category === active);
  return (
    <div className="flex-1 space-y-4">
      {filteredList.map(q => (
        <QuestionItem {...q} key={q.title} />
      ))}
    </div>
  );
}

export { QuestionList };
