import { LocationType } from '@admissions-support/types';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../../form/common/checkbox';
import { useLocationControl } from '@/context/location-control.context';

function TypesFilter() {
  const { filter, setFilter } = useLocationControl();

  const handleTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedType = event.target.name as LocationType;

    const index = filter.types.indexOf(selectedType);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        types: [...prevFilter.types, selectedType],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        types: prevFilter.types.filter(status => status !== selectedType),
      }));
    }
  };

  return (
    <>
      {types.map(type => {
        return (
          <CheckboxBase
            key={type.value}
            name={type.value}
            label={type.label}
            onChange={handleTypeChange}
            checked={filter.types.findIndex(t => t === type.value) >= 0}
          />
        );
      })}
    </>
  );
}

const types: { label: string; value: string }[] = [
  { label: 'ELC', value: 'ELC' },
  { label: 'Primary', value: 'PRIMARY' },
  { label: 'Secondary', value: 'SECONDARY' },
];

export { TypesFilter, types };
