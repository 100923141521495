import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  UpdateNon2FAAttributesDto,
  UpdateUserDto,
  User,
  UsersAndInvites,
  VerifyInvitationDto,
} from '@admissions-support/types';
import { DeleteUserDto } from '@admissions-support/types/dist/users/dtos';
import { updatePassword } from 'aws-amplify/auth';
import { AxiosResponse } from 'axios';

const changePhoneNumber = async (
  changePhoneNumberDto: UpdateNon2FAAttributesDto
) => {
  return await axiosClient.patch<any, void, UpdateNon2FAAttributesDto>(
    '/users/actions/phone-number',
    changePhoneNumberDto,
    {
      disableToast: true,
    }
  );
};

const getUsersAndInvites = async (): Promise<UsersAndInvites[]> => {
  const response = await axiosClient.get<UsersAndInvites[]>(
    '/organisations/users'
  );
  return response.data;
};

const suspendUser = async ({
  isSuspended,
  userId,
}: {
  isSuspended: boolean;
  userId: string;
}): Promise<void> => {
  const response = await axiosClient.post<void>(
    `/users/${userId}/actions/suspend?isSuspended=${
      isSuspended ? 'true' : 'false'
    }`
  );
  return response.data;
};

const updateUser = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateUserDto;
}): Promise<User> => {
  return await axiosClient.patch<any, User, UpdateUserDto>(
    `/users/${id}`,
    data
  );
};

const getUser = async (id: string): Promise<User> => {
  const response = await axiosClient.get<User>(`/users/${id}`);
  return response.data;
};

const changeUserPassword = async (data: {
  currentPassword: string;
  password: string;
}) => {
  await updatePassword({
    oldPassword: data.currentPassword,
    newPassword: data.password,
  });
};

const verifyParentInvitation = async (
  data: VerifyInvitationDto
): Promise<void> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<void>,
    VerifyInvitationDto
  >(`/invitations/verify`, data, { disableToast: true });

  return response.data;
};

const getMe = async (): Promise<User> => {
  const response = await axiosClient.get<User>('/users/me');
  return response.data;
};

const deleteUser = async (id: string, data: DeleteUserDto): Promise<void> => {
  await axiosClient.delete<void, HttpError, DeleteUserDto>(`/users/${id}`, {
    params: {
      isArchiving: data.isArchiving,
    },
  });
};

export const userService = Object.freeze({
  changePhoneNumber,
  getUsersAndInvites,
  suspendUser,
  updateUser,
  getUser,
  changeUserPassword,
  verifyParentInvitation,
  getMe,
  deleteUser,
});
