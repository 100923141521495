import { LoadingScreen } from '@/components/loading-screen';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { SchoolYear } from '@admissions-support/types';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuth } from './auth.context';

type SchoolYearContextState = {
  schoolYear: SchoolYear;
  setSchoolYear: Dispatch<SetStateAction<SchoolYear>>;
};

const defaultState: SchoolYearContextState = {
  schoolYear: {
    name: '',
    id: '',
    startDate: new Date(),
    endDate: new Date(),
    isAlreadyMatchedSchoolYear: false,
    isMatchingRunning: false,
    matchingChoicesDetails: {
      firstChoiceLastRunAt: null,
      secondChoiceLastRunAt: null,
      thirdChoiceLastRunAt: null,
    },
    intakes: [],
    cohorts: [],
    cloneSource: null,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSchoolYear: () => {},
};

const SchoolYearContext = createContext<SchoolYearContextState>(defaultState);
const localStorageKey = 'admit_school_year';

function SchoolYearProvider({ children }: { children: React.ReactNode }) {
  const persistedSchoolYear = localStorage.getItem(localStorageKey);
  const [schoolYear, setSchoolYear] = useState(() => {
    return persistedSchoolYear
      ? (JSON.parse(persistedSchoolYear) as SchoolYear)
      : defaultState.schoolYear;
  });
  const { isLoggedIn, user } = useAuth();

  // terms cannot be fetched if no user logged in with enabled MFA
  const areTermsNeeded = isLoggedIn && user && !!user.preferredMFA;

  // make sure if you have an obsolate id saved to a localstorage its does not get into the filter
  const { isSuccess, data } = useTerms({
    enabled: areTermsNeeded,
  });

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    const hasTerms = data && Array.isArray(data) && data.length > 0;

    if (data.find(term => term.id === schoolYear.id)) {
      // the saved school year still exist in the db, do nothing
      return;
    }

    localStorage.removeItem(localStorageKey);

    // run always, so we keep term data up-to-date in the localstorage
    if (hasTerms) {
      setSchoolYear(data[0]);
    }
  }, [isSuccess, data, schoolYear.id]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(schoolYear));
  }, [schoolYear]);

  const showLoadingScreen = areTermsNeeded && !isSuccess;

  return (
    <SchoolYearContext.Provider value={{ schoolYear, setSchoolYear }}>
      {showLoadingScreen ? <LoadingScreen /> : children}
    </SchoolYearContext.Provider>
  );
}

const useSchoolYear = () => {
  const context = useContext(SchoolYearContext);
  if (!context) {
    throw new Error('useSchoolYear must be used within a SchoolYearProvider');
  }
  return context;
};

export { SchoolYearProvider, useSchoolYear };
