import { logKey } from '@/config/query-keys';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useUpdateChoiceMutation } from '@/hooks/update-hooks/use-update-choice-mutation';
import { queryClient } from '@/libs/react-query';
import { Choice, ChoiceStatus } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { getChoiceName } from '../application-quick-view-drawer';
import { GroupSelect } from './common/group-select';

const schema = yup.object({
  choice: yup.string().required().label('Choice'),
  status: yup.string().required().label('Status'),
});

function ChoiceUpdateForm() {
  const params = useParams<{ id: string }>();
  const applicationId = params.id || '';

  const {
    isLoading,
    data: application,
    isSuccess: isApplicationSuccess,
  } = useApplication(applicationId, {
    enabled: Boolean(params.id),
  });

  const choices = isApplicationSuccess ? application.choices || [] : [];

  const { mutateAsync, isPending: isMutating } = useUpdateChoiceMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
      queryClient.invalidateQueries({
        queryKey: logKey.single(applicationId),
      });
    },
    onError: error => {
      toast.error(
        Array.isArray(error.message) ? error.message[0] : error.message
      );
    },
  });

  const isFormDisabled = isLoading || isMutating;

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      choice: '',
      status: '',
    },
  });

  const selectedChoiceId = form.watch('choice');

  useEffect(() => {
    form.resetField('status', {
      defaultValue: '',
      keepDirty: true,
      keepTouched: true,
    });
  }, [selectedChoiceId, form]);

  const choiceOptions = choices.map(choice => ({
    label: getChoiceName(choice as Choice),
    value: choice.id,
  }));

  const submitHandler = async (data: { choice: string; status: string }) => {
    const status = data.status as ChoiceStatus;

    await mutateAsync({
      choiceId: data.choice,
      applicationId,
      data: {
        status,
      },
    });
  };

  const selectedChoice = choices.find(choice => choice.id === selectedChoiceId);
  const isPrescribedChoice = selectedChoice
    ? selectedChoice.type === 'PRESCRIBED'
    : false;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(submitHandler)}
        className="w-full border-t border-gray-200 bg-white p-4"
      >
        <div className="light-gray-container flex w-full space-x-4">
          <GroupSelect
            name="choice"
            label="Choice"
            placeholder="Select.."
            className="w-full"
            menuPlacement="top"
            options={choiceOptions}
            isDisabled={isFormDisabled}
          />
          <GroupSelect
            name="status"
            label="Status"
            placeholder="Select.."
            className="w-full"
            menuPlacement="top"
            options={[
              {
                label: 'Resource is not Reserved',
                options: [
                  { label: 'Not matched', value: 'NOT_MATCHED' },
                  {
                    label: 'Scored',
                    value: 'SCORED',
                    isDisabled: isPrescribedChoice,
                  },
                  {
                    label: 'Waiting list',
                    value: 'WAITING_LIST',
                    isDisabled: isPrescribedChoice,
                  },
                  { label: 'Placement rejected', value: 'PLACEMENT_REJECTED' },
                ],
              },
              {
                label: 'Resource is Reserved',
                options: [
                  {
                    label: 'Matched',
                    value: 'MATCHED',
                    isDisabled: isPrescribedChoice,
                  },
                  { label: 'Placed', value: 'PLACED' },
                  { label: 'Granted', value: 'GRANTED' },
                ],
              },
            ]}
            isDisabled={isFormDisabled}
          />

          <div className="flex self-end">
            <button
              className="btn btn-primary"
              disabled={isFormDisabled}
              type="submit"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

export { ChoiceUpdateForm };
