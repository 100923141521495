import { useChoicesContext } from '@/context/choices.context';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { ChoiceFormActionTypes } from '@/reducers/choice.reducer';
import {
  getAvailableModelsFromLocation,
  selectAvailableSessionTypes,
} from '@/utils/application-utils';
import { getOperatingDaysOfSessionType } from '@/utils/location-session-utils';
import {
  Choice,
  LocationListing,
  LocationProviderType,
  Model,
  SessionType,
} from '@admissions-support/types';
import { useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';
import { Alert } from '../alert';
import { ModelRadioBoxOption } from '../application/model-radio-box-option';
import { AdditionalSessionsBox } from '../application/placement-preferences/choices/additional-sessions-box';
import { SessionsBox } from '../application/placement-preferences/choices/sessions-box';
import { SingleItem } from '../application/placement-preferences/choices/single-item';
import { SplitPlacementBox } from '../application/placement-preferences/choices/split-placement-box';
import { ProviderTypeBadge } from '../application/provider-type-badge';
import { CustomSelect } from '../custom-select';
import { Spinner } from '../spinner';
import { CheckboxBase } from './common/checkbox';
import { RadioBoxBase, RadioBoxOption } from './common/radio-box';

type ChoiceFormProps = {
  isMutating?: boolean;
  locations: LocationListing[];
  models: Model[];
  sessionTypes: SessionType[];
  initialData?: Choice;
};

export const formatLocationSelection = (location: LocationListing) => {
  if (!location.providerType && location.schoolType) {
    const providerType = (location.schoolType as string).includes(
      'NON_DENOMINATIONAL'
    )
      ? 'NON_DENOMINATIONAL'
      : 'ROMAN_CATHOLIC';
    return {
      key: location.id,
      value: location.name,
      badge: (
        <ProviderTypeBadge
          providerType={providerType as LocationProviderType}
        />
      ),
      providerOrSchoolType: providerType,
    };
  }
  return {
    key: location.id,
    value: location.name,
    badge: location.providerType ? (
      <ProviderTypeBadge providerType={location.providerType} />
    ) : undefined,
    providerOrSchoolType: location.providerType,
  };
};

const specialEstablishments = ['childminders'];
const specialProviderTypes = ['CHILD_MINDER'];

function ChoiceForm(props: ChoiceFormProps) {
  const { isMutating, locations, models, sessionTypes } = props;

  const rootRef = useRef<HTMLDivElement>(null);

  const { state, dispatch } = useChoicesContext();

  const [customEstablishmentId, setCustomEstablishmentId] = useState<
    string | null
  >(null);

  const areQueriesEnabled =
    !!state.establishment &&
    !specialEstablishments.includes(state.establishment);
  const { data: selectedLocation, isLoading: isLocationLoading } = useLocation(
    state.establishment,
    {
      enabled: areQueriesEnabled,
    }
  );

  const { data: sessionsData, isSuccess: sessionQuerySuccess } = useSessions(
    state.establishment,
    state.schoolYearId,
    {
      enabled: areQueriesEnabled,
    }
  );

  const locationsWithoutCM = locations.filter(
    location => !specialProviderTypes.includes(location.providerType || '')
  );

  const formattedLocations = locationsWithoutCM.map(location => {
    return formatLocationSelection(location);
  });

  const onlyChildMinderLocations = locations.filter(location =>
    specialProviderTypes.includes(location.providerType || '')
  );

  // Set the establishment type based on the selected location
  useEffect(() => {
    if (!selectedLocation && !customEstablishmentId) {
      return;
    }

    if (selectedLocation && selectedLocation.providerType === 'CHILD_MINDER') {
      dispatch({
        type: ChoiceFormActionTypes.SET_ESTABLISHMENT_TYPE,
        payload: 'CHILD_MINDER',
      });
      setCustomEstablishmentId('childminders');
    } else {
      dispatch({
        type: ChoiceFormActionTypes.SET_ESTABLISHMENT_TYPE,
        payload: selectedLocation?.providerType || '',
      });
    }
  }, [dispatch, selectedLocation, customEstablishmentId]);

  const modelRefsForLocation = getAvailableModelsFromLocation(selectedLocation);
  const hasMultipleModels = modelRefsForLocation.length > 1;
  const hasSingleModel = modelRefsForLocation.length === 1;

  const modelsForLocation = models.filter(model =>
    modelRefsForLocation
      .map(availableModel => availableModel.id)
      .includes(model.id)
  );

  const modelOptions: RadioBoxOption[] = modelsForLocation.map(model => ({
    value: model.id,
    Content: props => (
      <ModelRadioBoxOption
        {...props}
        name={model.name}
        url={model.additionalReadingURL}
        nbHoursPerWeek={model.nbHoursPerWeek}
      />
    ),
  }));

  const availableSessionTypeRefs = selectAvailableSessionTypes(
    state,
    sessionTypes
  );

  const selectedSessionType = sessionTypes.find(
    type => type.id === state.sessionType
  );
  const hasAdditionalSessionType =
    selectedSessionType &&
    selectedSessionType?.additionalSessionTypes.length > 0;
  const daysForSessionType = getOperatingDaysOfSessionType(selectedSessionType);

  const hasDayToDisplaySessions =
    daysForSessionType.length > 0 && !!selectedSessionType;

  const formDisabled = isMutating;

  /**
   * if only one model is available lets pre-select it
   */
  const singleModelId = hasSingleModel ? modelRefsForLocation[0].id : null;
  useEffect(() => {
    if (hasSingleModel && singleModelId) {
      dispatch({
        type: ChoiceFormActionTypes.AUTO_SET_MODEL,
        payload: singleModelId,
      });
    }
    /**
     * we need state.establishment as well, because if the establishment changes
     * we want to re-run automatic modell selection too
     */
  }, [dispatch, hasSingleModel, singleModelId, state.establishment]);

  useEffect(() => {
    if (sessionQuerySuccess) {
      dispatch({
        type: ChoiceFormActionTypes.SET_SESSIONS,
        payload: sessionsData,
      });
    }
  }, [dispatch, sessionsData, sessionQuerySuccess]);

  useEffect(() => {
    // When the component mounts, scroll the page to this component
    if (rootRef.current) {
      rootRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  const councilLocations = formattedLocations.filter(
    location => location.providerOrSchoolType === 'COUNCIL'
  );

  const partnerLocations = formattedLocations.filter(
    location => location.providerOrSchoolType === 'PARTNER_PROVIDER'
  );

  const childMinderLocations = formattedLocations.filter(
    location => location.providerOrSchoolType === 'CHILD_MINDER'
  );

  const nonDenominationalLocations = formattedLocations.filter(
    location => location.providerOrSchoolType === 'NON_DENOMINATIONAL'
  );

  const romanCatholicLocations = formattedLocations.filter(
    location => location.providerOrSchoolType === 'ROMAN_CATHOLIC'
  );

  const locationsWithPartnerProviders = [
    ...councilLocations.sort((a, b) => a.value.localeCompare(b.value)),
    ...partnerLocations.sort((a, b) => a.value.localeCompare(b.value)),
    ...childMinderLocations.sort((a, b) => a.value.localeCompare(b.value)),
    ...nonDenominationalLocations.sort((a, b) =>
      a.value.localeCompare(b.value)
    ),
    ...romanCatholicLocations.sort((a, b) => a.value.localeCompare(b.value)),
    {
      key: 'childminders',
      value: 'Child Minder',
    },
  ];

  const onEstablishmentChange = (value: string) => {
    // If the selected establishment is a normal one (not a childminder) reset the custom establishment id and set the establishment
    if (!specialEstablishments.includes(value)) {
      dispatch({
        type: ChoiceFormActionTypes.SET_ESTABLISHMENT,
        payload: value,
      });
      setCustomEstablishmentId(null);
    } else {
      // If the selected establishment is a childminder, set the custom establishment id and reset the establishment (so they can select it in the second dropdown)
      setCustomEstablishmentId(value);
      dispatch({
        type: ChoiceFormActionTypes.SET_ESTABLISHMENT,
        payload: '',
      });
    }
  };

  const isPrivatePlacementWarningVisible =
    selectedLocation?.providerType !== 'COUNCIL' && selectedLocation;

  return (
    <div className="relative" ref={rootRef}>
      <Tooltip
        anchorSelect=".show-error"
        place="top"
        offset={30}
        className="tooltip !z-20 !max-w-xs !bg-red-600 text-white"
      >
        <div>
          <p className="mb-1 text-sm font-semibold leading-4">
            You reached the maximum number of Allowance
          </p>
          <p className="text-sm leading-4">
            To select this session, please remove another session or choose a
            different session type!
          </p>
        </div>
      </Tooltip>

      <div className={'space-y-6 sm:max-h-[calc(90vh-2.5rem)]'}>
        <div className="dark-gray-container space-y-6">
          <p className="text-md mb-4 font-medium text-gray-900">
            Select Establishment
          </p>
          <CustomSelect
            name="establishment"
            label="Establishment"
            value={customEstablishmentId || state.establishment}
            onChange={onEstablishmentChange}
            options={locationsWithPartnerProviders}
            isDisabled={formDisabled}
            menuListClasses="!max-h-56"
          />
          {customEstablishmentId ? (
            <CustomSelect
              name="establishment"
              label="Child Minder"
              value={state.establishment}
              options={onlyChildMinderLocations.map(location => ({
                key: location.id,
                value: location.name,
              }))}
              onChange={value =>
                dispatch({
                  type: ChoiceFormActionTypes.SET_ESTABLISHMENT,
                  payload: value,
                })
              }
            />
          ) : null}
        </div>

        {isPrivatePlacementWarningVisible ? (
          <Alert
            title="Attention"
            text={
              <div>
                <div>
                  You must arrange this placement directly with{' '}
                  {selectedLocation?.name}.
                </div>
                <div className="mt-2">
                  1140 hours - Maximum 30 hours per week pro-rata from funding
                  start date can be used across all your placements.
                </div>
              </div>
            }
            type="warning"
          />
        ) : null}

        {sessionQuerySuccess && sessionsData.length === 0 && (
          <Alert
            text="Currently, there are no sessions available at this location for the school year."
            type="warning"
          />
        )}
        {/**
         * Loading while fetching single selected location
         */}
        {isLocationLoading && (
          <div className="dark-gray-container flex justify-center space-y-8">
            <Spinner />
          </div>
        )}
        {/**
         * Model
         */}
        {(hasSingleModel || hasMultipleModels) && (
          <div className="dark-gray-container">
            <p
              className={twMerge(
                'text-md font-medium text-gray-900',
                hasMultipleModels ? 'mb-2' : 'mb-4'
              )}
            >
              {hasMultipleModels ? 'Select Model' : 'Week Model'}
            </p>
            {hasMultipleModels && (
              <>
                <p className="text-md mb-4">
                  There are more than one model available. Select the model to
                  see the sessions available
                </p>
                <RadioBoxBase
                  label="Available Models"
                  value={state.model}
                  onChange={value =>
                    dispatch({
                      type: ChoiceFormActionTypes.SET_MODEL,
                      payload: value,
                    })
                  }
                  containerClassName="grid gap-4 grid-cols-1 2xl:grid-cols-2"
                  options={modelOptions}
                  disabled={formDisabled}
                />
              </>
            )}
            {hasSingleModel && (
              <SingleItem
                content={
                  <ModelRadioBoxOption
                    name={modelsForLocation[0].name}
                    url={modelsForLocation[0].additionalReadingURL}
                    nbHoursPerWeek={modelsForLocation[0].nbHoursPerWeek}
                  />
                }
              />
            )}
          </div>
        )}
        {/**
         * Session Type
         */}
        {state.model && (
          <div className="dark-gray-container">
            <p className="text-md mb-2 font-medium text-gray-900">
              Select Session Type
            </p>
            <p className="text-md mb-4">
              Session type&apos;s define the pattern of attendance available.
            </p>

            {availableSessionTypeRefs.length > 0 ? (
              <CustomSelect
                name="sessionType"
                label="SessionType"
                value={state.sessionType}
                onChange={value =>
                  dispatch({
                    type: ChoiceFormActionTypes.SET_SESSION_TYPE,
                    payload: value,
                  })
                }
                options={[
                  { key: '', value: 'Select..' },
                  ...availableSessionTypeRefs.map(sessionType => ({
                    key: sessionType.id,
                    value: sessionType.name,
                  })),
                ]}
                isDisabled={formDisabled}
                menuListClasses="!max-h-56"
              />
            ) : (
              <p className="italic">No available sessions!</p>
            )}
          </div>
        )}
        {/**
         * Sessions
         */}
        {hasDayToDisplaySessions && (
          <SessionsBox
            selectedSessionType={selectedSessionType}
            disabled={formDisabled}
          />
        )}

        {/** Split placement */}
        {selectedSessionType &&
          selectedSessionType.splitPlacement.isEnabled && (
            <CheckboxBase
              name="toggle_split_placement"
              label="Split Placement"
              helperText="Split this placement across 2 different locations."
              onChange={() =>
                dispatch({
                  type: ChoiceFormActionTypes.TOGGLE_SPLIT_PLACEMENT,
                })
              }
              checked={state.enableSplitPlacement}
            />
          )}

        {state.enableSplitPlacement && selectedSessionType && (
          <SplitPlacementBox
            disabled={formDisabled}
            locations={locations}
            selectedSessionType={selectedSessionType}
          />
        )}

        {hasAdditionalSessionType && (
          <AdditionalSessionsBox
            disabled={formDisabled}
            locations={formattedLocations}
            selectedSessionType={selectedSessionType}
          />
        )}
      </div>
    </div>
  );
}

export { ChoiceForm };
