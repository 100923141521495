import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';
import { LocationType } from '@admissions-support/types';

type LocationFilterProps = {
  applicationsType?: LocationType;
};

function LocationFilter(props: LocationFilterProps) {
  const { applicationsType } = props;
  const { filter, setFilter } = useApplicationControl();
  const { data: locationsData } = useLocations({
    type: applicationsType ? applicationsType : undefined,
  });

  const locations =
    locationsData?.map(location => ({
      label: location.name,
      value: location.id,
    })) || [];

  const handleLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLocation = event.target.name;

    const index = filter.locations.indexOf(selectedLocation);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: [...prevFilter.locations, selectedLocation],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: prevFilter.locations.filter(
          location => location !== selectedLocation
        ),
        //set filter false if locations gonna be empty
        isSiblingAtLocation:
          prevFilter.locations.length === 1
            ? false
            : prevFilter.isSiblingAtLocation,
      }));
    }
  };

  const handleSelectAllLocations = () => {
    if (filter.locations.length === locations.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: defaultApplicationControl.filter.locations,
        isSiblingAtLocation: false,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: locations.map(s => s.value),
      }));
    }
  };

  const isSomeSelected = locations.some(location =>
    filter.locations.includes(location.value)
  );

  return (
    <>
      {locations.map(location => {
        return (
          <CheckboxBase
            key={location.value}
            name={location.value}
            label={location.label}
            onChange={handleLocationChange}
            checked={filter.locations.findIndex(c => c === location.value) >= 0}
          />
        );
      })}
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllLocations"
        label="Select All"
        onChange={handleSelectAllLocations}
        checked={filter.locations.length === locations.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

export { LocationFilter };
