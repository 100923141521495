import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { AllApplications } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useApplication(
  id?: string,
  queryOptions?: Partial<UseQueryOptions<AllApplications, HttpError>>
) {
  return useQuery({
    queryKey: applicationKey.single(id || ''),
    queryFn: () => applicationService.getApplication(id || ''),
    ...queryOptions,
    enabled: Boolean(id),
  });
}

export { useApplication };
