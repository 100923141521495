import { Popover } from '@headlessui/react';
import { Tag } from '../tag';
import { FilterItem } from '../filter-item';
import {
  defaultLocationControl,
  useLocationControl,
} from '@/context/location-control.context';
import { types, TypesFilter } from './filters/types-filter';

function LocationsFilterRow() {
  const { filter, setFilter } = useLocationControl();

  const handleResetType = () => {
    setFilter(prevFilter => ({
      ...prevFilter,
      types: defaultLocationControl.filter.types,
    }));
  };

  function SelectedTypes() {
    const selectedTypes = types
      .filter(type => filter.types.includes(type.value))
      .sort(
        (a, b) => filter.types.indexOf(a.value) - filter.types.indexOf(b.value)
      );

    if (selectedTypes.length === 0) {
      return null;
    }

    return (
      <Tag
        key={selectedTypes[0].value}
        type="outline"
        className="px-1 py-0 text-xs"
      >
        {selectedTypes[0].label}
      </Tag>
    );
  }

  return (
    <div className="mt-3 border-t border-gray-100 py-3">
      <Popover.Group className="flex flex-wrap items-baseline gap-2">
        <FilterItem
          label="Type"
          counter={filter.types.length}
          reset={handleResetType}
          renderSelectedItems={<SelectedTypes />}
        >
          <TypesFilter />
        </FilterItem>
      </Popover.Group>
    </div>
  );
}

export { LocationsFilterRow };
