import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { ChoiceStatus } from '@admissions-support/types';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';
import { ChoiceStatusTag } from './choice-status-tag';

function ChoiceStatusesFilter() {
  const { filter, setFilter } = useApplicationControl();

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedStatus = event.target.name as ChoiceStatus;
    const index = filter.choiceStatuses.indexOf(selectedStatus);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        choiceStatuses: [...prevFilter.choiceStatuses, selectedStatus],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        choiceStatuses: prevFilter.choiceStatuses.filter(
          status => status !== selectedStatus
        ),
      }));
    }
  };

  const handleSelectAllStatus = () => {
    if (filter.choiceStatuses.length === choiceStatuses.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        choiceStatuses: defaultApplicationControl.filter.choiceStatuses,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        choiceStatuses: choiceStatuses.map(s => s.value),
      }));
    }
  };

  const isSomeSelected = choiceStatuses.some(status =>
    filter.choiceStatuses.includes(status.value)
  );

  return (
    <>
      {choiceStatuses.map(status => {
        return (
          <CheckboxBase
            key={`choice-status-${status.value}`}
            name={status.value}
            label={
              <ChoiceStatusTag status={status.value}>
                {status.label}
              </ChoiceStatusTag>
            }
            onChange={handleStatusChange}
            checked={
              filter.choiceStatuses.findIndex(s => s === status.value) >= 0
            }
          />
        );
      })}
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllChoiceStatus"
        label="Select All"
        onChange={handleSelectAllStatus}
        checked={filter.choiceStatuses.length === choiceStatuses.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

const choiceStatuses: { label: string; value: ChoiceStatus }[] = [
  { label: 'Scored', value: 'SCORED' },
  { label: 'Not Matched', value: 'NOT_MATCHED' },
  { label: 'Waiting List', value: 'WAITING_LIST' },
  { label: 'Matched', value: 'MATCHED' },
  { label: 'Placed', value: 'PLACED' },
  { label: 'Reserved', value: 'RESERVED' },
  { value: 'GRANTED', label: 'Granted' },
  { label: 'Placement Rejected', value: 'PLACEMENT_REJECTED' },
];

export { ChoiceStatusesFilter, choiceStatuses };
