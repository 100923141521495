import { schoolLocationKey } from '@/config/query-keys';
import { schoolLocationService } from '@/services/school-location.service';
import { HttpError } from '@/types/error';
import { SchoolLocationListItem } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useListSchoolLocations(
  queryOptions?: Partial<UseQueryOptions<SchoolLocationListItem[], HttpError>>
) {
  return useQuery({
    queryKey: schoolLocationKey.listAllSchoolLocations,
    queryFn: () => schoolLocationService.list(),
    ...queryOptions,
  });
}

export { useListSchoolLocations };
