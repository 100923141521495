import { staffRouterPath } from '@/config/route-paths.config';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import {
  AllApplications,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { ArrowLeft, Send01 } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AdditionalInfoElement } from '../application/additional-info/additional-info.element';
import { useAdditionalInfoForm } from '../application/additional-info/use-additional-info.form';
import { CheckboxBase } from './common/checkbox';
import { Radio } from './common/radio';
import { TextInput } from './common/text-input';
import { Textarea } from './common/textarea';

type AdditionalDetailsFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: AllApplications;
  isMutating?: boolean;
};

function AdditionalDetailsForm(props: AdditionalDetailsFormProps) {
  const { initialData, onSubmit, isMutating } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { data: applicationTemplate } = useApplicationTemplate(
    initialData.template.id.toString() ?? ''
  );

  const { form, handleSubmit, saveAsDraft } = useAdditionalInfoForm({
    initialData,
    onSubmit,
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData,
  });

  const formDisabled = isMutating;

  const additionalQuestions = applicationTemplate?.additionalQuestions ?? [];
  const additionalQuestionAnswers: Record<string, string> = form.watch(
    'additionalQuestionAnswers'
  );

  const getAdditionalQuestionAnswerForCheckbox = (
    questionId: string,
    value: string
  ) => {
    const answers = additionalQuestionAnswers?.[questionId]?.split(',') ?? [];
    return answers.includes(value);
  };

  const handleAdditionalQuestionAnswerChange = (
    questionId: string,
    value: string
  ) => {
    const answers = additionalQuestionAnswers?.[questionId]?.split(',') ?? [];
    if (answers.includes(value)) {
      answers.splice(answers.indexOf(value), 1);
    } else {
      answers.push(value);
    }
    // @ts-ignore
    form.setValue(`additionalQuestionAnswers.${questionId}`, answers.join(','));
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="mt-6"
        id="upsert-application-form"
      >
        <div className="two-col-form">
          <AdditionalInfoElement disabled={formDisabled} />
        </div>
        {additionalQuestions.length > 0 && (
          <div className="two-col-form">
            <div>
              <p className="text-md font-medium leading-7 text-gray-900">
                Additional questions
              </p>
            </div>
            <div>
              {additionalQuestions.length > 0
                ? additionalQuestions.map(question => {
                    return (
                      <div key={question.id} className="mb-4">
                        {question.type === 'radio' ? (
                          <Radio
                            label={question.label}
                            name={`additionalQuestionAnswers.${question.id}`}
                            className="mt-2"
                            options={question.options.map(option => ({
                              label: option,
                              value: option,
                            }))}
                          />
                        ) : question.type === 'checkbox' ? (
                          <>
                            <label className="label mb-2 mt-2">
                              {question.label}
                            </label>
                            {question.options.map((option, index) => (
                              <div key={index} className="flex items-center">
                                <CheckboxBase
                                  label={option}
                                  name={`additionalQuestionAnswers.${question.id}`}
                                  onChange={() =>
                                    handleAdditionalQuestionAnswerChange(
                                      question.id,
                                      option
                                    )
                                  }
                                  checked={getAdditionalQuestionAnswerForCheckbox(
                                    question.id,
                                    option
                                  )}
                                />
                              </div>
                            ))}
                          </>
                        ) : question.type === 'textarea' ? (
                          <Textarea
                            name={`additionalQuestionAnswers.${question.id}`}
                            label={question.label}
                            placeholder="Leave blank if not applicable"
                            rows={5}
                          />
                        ) : question.type === 'input' ? (
                          <TextInput
                            label={question.label}
                            name={`additionalQuestionAnswers.${question.id}`}
                            helperText="Leave blank if not applicable"
                          />
                        ) : null}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        )}
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Return to Previous Section
        </button>
        {initialData.status === 'DRAFT' && (
          <button
            className="btn btn-primary flex items-center"
            type="button"
            onClick={handleSubmit}
            disabled={formDisabled}
          >
            Submit Form <Send01 viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
          </button>
        )}
      </div>
    </FormProvider>
  );
}

export { AdditionalDetailsForm };
