import { trusteeKey } from '@/config/query-keys';
import { useDeleteTrusteeMutation } from '@/hooks/delete-hooks/use-delete-trustee-mutation';
import { useUpdateTrusteeMutation } from '@/hooks/update-hooks/update-trustee-mutation';
import { queryClient } from '@/libs/react-query';
import {
  UpdateTrusteeFormData,
  updateTrusteeSchema,
} from '@/types/update-trustee.schema';
import { TrusteeWithInvitation } from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { BaseModalPropsExport } from '../base-modal';
import { Checkbox } from '../form/common/checkbox';
import { TextInput } from '../form/common/text-input';
import { ScrollableModal } from '../scroll-modal';

type UpdateTrusteeModalProps = {
  childId: string;
  trustee?: TrusteeWithInvitation;
} & BaseModalPropsExport;

function initialValues(trustee?: TrusteeWithInvitation): UpdateTrusteeFormData {
  if (!trustee) {
    return {
      relationship: '',
      canManageApplications: false,
      canPurchaseExtraHours: false,
    };
  }

  return {
    relationship: trustee.relationship,
    canPurchaseExtraHours: trustee.permissions.includes('PURCHASE_EXTRA_HOURS'),
    canManageApplications: trustee.permissions.includes('MANAGE_APPLICATIONS'),
  };
}

function UpdateTrusteeModal(props: UpdateTrusteeModalProps) {
  const { isOpen, onClose, childId, trustee } = props;

  const {
    mutateAsync: deleteTrustee,
    isPending: isDeleting,
    isSuccess: isDeleted,
    reset: resetDeleteTrustee,
  } = useDeleteTrusteeMutation();

  const form = useForm({
    resolver: yupResolver(updateTrusteeSchema),
    defaultValues: initialValues(trustee),
  });

  useEffect(() => {
    form.reset(initialValues(trustee));
  }, [isOpen, form, trustee]);

  useEffect(() => {
    if (isDeleted) {
      onClose();
    }
  }, [isDeleted, onClose]);

  const { mutateAsync: updateTrustee, isPending: isUpdating } =
    useUpdateTrusteeMutation();

  const onSubmit = async (data: UpdateTrusteeFormData) => {
    try {
      const permissions: string[] = [];
      if (data.canManageApplications) {
        permissions.push('MANAGE_APPLICATIONS');
      }
      if (data.canPurchaseExtraHours) {
        permissions.push('PURCHASE_EXTRA_HOURS');
      }

      await updateTrustee({
        trusteeId: trustee?.id || '',
        childId,
        data: { permissions, relationship: data.relationship },
      });
      await queryClient.invalidateQueries({
        queryKey: trusteeKey.list(childId),
      });

      onClose();
    } catch (error) {
      error;
    }
  };

  const handleDeleteTrustee = async () => {
    await deleteTrustee({ trusteeId: trustee?.id || '', childId });
    await queryClient.invalidateQueries({ queryKey: trusteeKey.list(childId) });
    resetDeleteTrustee();
  };

  return (
    <ScrollableModal isOpen={isOpen} onClose={onClose} size="xl">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ScrollableModal.Header onClose={onClose}>
            <div>
              <ScrollableModal.Title
                title={`Update ${trustee?.name}`}
                className="mb-4"
              />
              <p className="text-base text-gray-500">
                Please provide the details for this trustee, and specify their
                level of access.
              </p>
            </div>
          </ScrollableModal.Header>

          <ScrollableModal.Content className="space-y-6">
            <TextInput
              name="relationship"
              label="Relationship to Child*"
              placeholder="Grandparent"
              type="text"
            />
            <Checkbox
              label="Purchase Extra Hours"
              helperText="Allow this trustee to book, and pay for extra hours if applicable"
              name="canPurchaseExtraHours"
            />
            <Checkbox
              label="Manage Applications"
              helperText="Allow this trustee to make changes to applications if applicable"
              name="canManageApplications"
            />
          </ScrollableModal.Content>

          <ScrollableModal.Footer>
            <div className="flex w-full gap-3">
              <button
                className="btn btn-error w-full"
                type="button"
                onClick={handleDeleteTrustee}
                disabled={isDeleting}
              >
                Remove
              </button>
              <button
                className="btn btn-primary w-full"
                disabled={!isEmpty(form.formState.errors) || isUpdating}
                type="submit"
              >
                Update
              </button>
            </div>
          </ScrollableModal.Footer>
        </form>
      </FormProvider>
    </ScrollableModal>
  );
}

export { UpdateTrusteeModal };
