import { schoolLocationKey } from '@/config/query-keys';
import { schoolLocationService } from '@/services/school-location.service';
import { HttpError } from '@/types/error';
import {
  SchoolLocation,
  UpdateSchoolLocationDto,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateSchoolLocationMutation(
  mutationOptions?: UseMutationOptions<
    SchoolLocation,
    HttpError,
    { id: string; data: UpdateSchoolLocationDto }
  >
) {
  return useMutation({
    mutationKey: schoolLocationKey.update,
    mutationFn: schoolLocationService.updateSchoolLocation,
    ...mutationOptions,
  });
}

export { useUpdateSchoolLocationMutation };
