import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PurchaseExtraHoursCalendar } from '@/components/locations/purchase-extra-hours-calendar';
import { PurchaseExtraHoursNavigation } from '@/components/locations/purchase-extra-hours-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { Navigate, useParams } from 'react-router-dom';

function CalendarPage() {
  const { locationId = '' } = useParams();

  const {
    isLoading: isLocationLoading,
    data: location,
    isError: isLocationError,
  } = useLocation(locationId);

  const isLoading = isLocationLoading;

  const isError = !location || isLocationError;

  if (isLoading) {
    return (
      <>
        <PageTitle title="" variant="gray" className="border-none">
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={locationId} />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  return (
    <div>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      />
      <LocationTabNavigation id={locationId} />

      <div className="mt-8">
        <PurchaseExtraHoursNavigation />
      </div>

      <PurchaseExtraHoursCalendar />
    </div>
  );
}

export { CalendarPage };
