import { useLeaveModal } from '@/hooks/use-leave-modal';
import { Child, CreateChildDto } from '@admissions-support/types';
import { ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { ChildDetailsElement } from './child-details.element';
import { useChildDetailsForm } from './use-child-details.form';

type ParentChildDetailsFormProps = {
  onSubmit: (data: CreateChildDto) => any;
  initialData?: Child;
  isLoading?: boolean;
  isMutating?: boolean;
};

function ParentChildDetailsForm(props: ParentChildDetailsFormProps) {
  const { initialData, onSubmit, isMutating } = props;

  const { form, handleSubmit } = useChildDetailsForm({
    initialData,
    onSubmit,
  });

  useLeaveModal({
    show: form.formState.isDirty && !!initialData,
  });

  const idType = form.watch('identificationType');

  return (
    <FormProvider {...form}>
      {/* @ts-expect-error TODO: fix type mismatch  */}
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <ChildDetailsElement disabled={isMutating} idType={idType} />

        <button
          className="btn btn-primary ml-auto mt-6 flex items-center"
          disabled={!form.formState.isDirty || isMutating}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 " />
        </button>
      </form>
    </FormProvider>
  );
}

export { ParentChildDetailsForm };
