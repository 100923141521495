import { ApplicantNameSearchBar } from '@/components/application/applicant-name-searchbar';
import { ApplicationsFilterDrawer } from '@/components/application/applications-filter-drawer';
import { NurseryApplicationsList } from '@/components/application/nursery-applications-list';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import {
  ApplicationControlContextType,
  ApplicationControlProvider,
} from '@/context/application-control.context';
import { useSchoolLocation } from '@/hooks/query-hooks/use-school-location';
import { getApplicationsTypeFromSchoolType } from '@/utils/application-utils';
import { Navigate, useParams } from 'react-router-dom';

function SchoolLocationApplications() {
  const { schoolId = '' } = useParams();

  const { isLoading, data: location, isError } = useSchoolLocation(schoolId);

  if (isLoading) {
    return (
      <>
        <PageTitle title="" variant="gray" className="border-none">
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={schoolId} isSchoolLocation />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  const applicationsType = getApplicationsTypeFromSchoolType(location.type);

  const contextType =
    `${applicationsType.toLowerCase()}_nursery` as ApplicationControlContextType;

  return (
    <>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-full sm:w-64" />}
      />
      <LocationTabNavigation id={schoolId} isSchoolLocation />
      <ApplicationControlProvider
        type={contextType}
        defaultControl={{ filter: { locations: [schoolId] } }}
      >
        <div className="mb-6 mt-8">
          <h3 className="mb-8 text-lg font-semibold">Applications</h3>
          <div className="flex">
            <ApplicationsFilterDrawer applicationsType={applicationsType} />
            <div className="ml-auto">
              <ApplicantNameSearchBar />
            </div>
          </div>
        </div>
        <NurseryApplicationsList applicationsType={applicationsType} />
      </ApplicationControlProvider>
    </>
  );
}

export { SchoolLocationApplications };
