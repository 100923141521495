import {
  TableChoice,
  Choice,
  SchoolApplicationChoice,
} from '@admissions-support/types';
import { ChoiceStatusTag } from './choice-status-tag';
import { startCase } from 'lodash-es';
import { generatePath, Link } from 'react-router-dom';
import { staffRouterPath } from '@/config/route-paths.config';

export const isNotSchoolApplicationChoice = (
  choice: any
): choice is TableChoice | Choice => {
  return Boolean(choice.sessions);
};

type ChoiceCellProps = {
  choice: TableChoice | Choice | SchoolApplicationChoice;
  doesHideTag?: boolean;
};

function ChoiceCell(props: ChoiceCellProps) {
  const { choice, doesHideTag } = props;

  if (!choice) {
    return <p className="text-sm text-gray-500">-</p>;
  }

  if (isNotSchoolApplicationChoice(choice) && choice.splitPlacement) {
    if (
      !choice.splitPlacement.location &&
      !choice.splitPlacement.additionalSessionLocation
    ) {
      return (
        <div>
          <p>Split placement</p>
          {choice.status && !doesHideTag ? (
            <ChoiceStatusTag status={choice.status}>
              {startCase(choice.status.toLowerCase())}
            </ChoiceStatusTag>
          ) : null}
        </div>
      );
    }

    const tooltipContent =
      choice.splitPlacement.location && choice.location
        ? `${choice.location.name}/${choice.splitPlacement.location.name}`
        : choice.location
        ? `${choice.location.name}/${choice.splitPlacement.additionalSessionLocation?.name}`
        : 'Location details not available';

    return (
      <div
        data-tooltip-id="split-placement-tooltip"
        data-tooltip-content={tooltipContent}
        className="inline-block hover:cursor-pointer"
      >
        <p>Split placement</p>
        {choice.status && !doesHideTag ? (
          <ChoiceStatusTag status={choice.status}>
            {startCase(choice.status.toLowerCase())}
          </ChoiceStatusTag>
        ) : null}
      </div>
    );
  }

  return (
    <div>
      <Link
        to={generatePath(staffRouterPath.OVERVIEW_LOCATION, {
          locationId: choice.location.id.toString(),
        })}
        className="font-normal text-gray-600 hover:text-primary-600"
      >
        <p className="mb-0.5 cursor-pointer text-sm">{choice.location.name}</p>
      </Link>
      {choice.status ? (
        <ChoiceStatusTag status={choice.status}>
          {startCase(choice.status.toLowerCase())}
        </ChoiceStatusTag>
      ) : null}
    </div>
  );
}

export { ChoiceCell };
