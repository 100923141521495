import { PermissionGuard } from '@/components/auth/permission-guard';
import { LocationOverviewForm } from '@/components/form/location-overview.form';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { staffRouterPath } from '@/config/route-paths.config';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { Permission } from '@/types/auth';
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';

function LocationOverview() {
  const navigate = useNavigate();

  const params = useParams<{ locationId: string }>();

  const { isLoading, data: location, isError } = useLocation(params.locationId);

  const { isLoading: isOperationLoading, data: operation } =
    useLocationOperation(params.locationId);

  const handleCancel = () => {
    navigate(staffRouterPath.LOCATIONS);
  };

  const handleEdit = () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_LOCATION, {
      locationId: params.locationId || null,
    });

    navigate(redirectUrl);
  };

  if (isLoading || isOperationLoading) {
    return <LoadingScreen />;
  }

  if (!location || isError || !operation) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  return (
    <div>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      >
        <PermissionGuard requiredPermissions={[Permission['location:update']]}>
          <div className="flex gap-3">
            <button
              type="button"
              className="btn btn-empty"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleEdit}
              disabled={isLoading}
            >
              Edit Location
            </button>
          </div>
        </PermissionGuard>
      </PageTitle>
      <LocationTabNavigation id={params.locationId || ''} />

      <h2 className="my-6 text-lg font-medium text-gray-900">
        Location Overview
      </h2>

      <LocationOverviewForm location={location} operation={operation} />
    </div>
  );
}

export { LocationOverview };
