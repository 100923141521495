import { LocationType } from '@admissions-support/types';
import { Alert } from '@/components/alert';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { MultiSelect } from '../common/multi-select';
import { Spinner } from '@/components/spinner';

function FeederSchoolsSection() {
  const {
    data: locations,
    isLoading: isLocationsLoading,
    isSuccess: isLocationsSuccess,
    isError: isLocationsError,
  } = useLocations({ type: 'PRIMARY' as LocationType });

  if (isLocationsLoading) {
    return <Spinner className="m-auto" />;
  }

  if (isLocationsError || !isLocationsSuccess) {
    return (
      <Alert
        type="error"
        text="An unexpected error happened querying the areas."
        className="my-6"
      />
    );
  }

  const locationsOptions = locations.map(location => ({
    label: location.name,
    value: location.id,
  }));

  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Feeder Schools
        </p>
        <p className="text-md leading-6 text-gray-600">
          Do certain primary schools typically have children that will attend
          this school?
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <MultiSelect
          name="feederSchools"
          label="Primary Schools"
          placeholder="Select..."
          options={locationsOptions}
          isDisabled={isLocationsLoading}
        />
      </div>
    </div>
  );
}

export { FeederSchoolsSection };
