import { ApplicationsFilterDrawer } from '@/components/application/applications-filter-drawer';
import { NurseryApplicationsList } from '@/components/application/nursery-applications-list';
import { ApplicantNameSearchBar } from '@/components/application/applicant-name-searchbar';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { ApplicationControlProvider } from '@/context/application-control.context';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { LocationType } from '@admissions-support/types';

function LocationApplications() {
  const navigate = useNavigate();

  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';
  const {
    isLoading: isLocationLoading,
    data: location,
    isError,
  } = useLocation(locationId);

  useEffect(() => {
    if (isError) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [isError, navigate]);

  const isPageLoading = isLocationLoading;

  if (isPageLoading) {
    return (
      <>
        <PageTitle title="" variant="gray" className="border-none">
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={params.locationId || ''} />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  return (
    <>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-full sm:w-64" />}
      />
      <LocationTabNavigation id={params.locationId || ''} />
      <ApplicationControlProvider
        type="nursery"
        defaultControl={{ filter: { locations: [locationId] } }}
      >
        <div className="mb-6 mt-8">
          <h3 className="mb-8 text-lg font-semibold">Applications</h3>
          <div className="flex">
            <ApplicationsFilterDrawer />
            <div className="ml-auto">
              <ApplicantNameSearchBar />
            </div>
          </div>
        </div>
        <NurseryApplicationsList applicationsType={'ELC' as LocationType} />
      </ApplicationControlProvider>
    </>
  );
}

export { LocationApplications };
