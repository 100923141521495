import { DeleteZone } from '@/components/delete-zone';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { QueryBuilder } from '@/components/reporting/query-builder';
import {
  transformQueryBuilderApiDataToFormData,
  useQueryBuilderForm,
} from '@/components/reporting/query-builder.form';
import { DataSource } from '@/components/reporting/query-builder/data-source';
import { OutputTable } from '@/components/reporting/query-builder/output-table';
import { ReportDetails } from '@/components/reporting/query-builder/report-details';
import { useCanUserEditTemplate } from '@/components/reporting/query-builder/use-can-user-edit-template';
import { QueryBuilderFormData } from '@/components/reporting/reporting.type';
import { reportKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import {
  defaultReportingOutputControl,
  useReportingOutputControl,
} from '@/context/reporting-output-control.context';
import { useCreateReportTemplate } from '@/hooks/create-hooks/use-create-report-template';
import { useDeleteReportTemplate } from '@/hooks/delete-hooks/use-delete-report-template';
import { useApplicationsReportResult } from '@/hooks/query-hooks/use-applications-report-results';
import { useBookingsReportResult } from '@/hooks/query-hooks/use-bookings-report-result';
import { useLocationsReportResult } from '@/hooks/query-hooks/use-locations-report-results';
import { useReportTemplate } from '@/hooks/query-hooks/use-report-template';
import { useUpdateReportTemplate } from '@/hooks/update-hooks/use-update-report-template';
import { queryClient } from '@/libs/react-query';
import { getErrorMessage } from '@/utils/get-error-message';
import { exportableKeysApplications } from '@/utils/query-builder-utils';
import {
  applicationsToggledColumnsDefaultValue,
  bookingsToggledColumnsDefaultValue,
  locationsToggledColumnsDefaultValue,
  schoolApplicationsToggledColumnsDefaultValue,
  schoolLocationsToggledColumnsDefaultValue,
} from '@/utils/toggled-columns-default-values';
import {
  CreateReportTemplateDto,
  ReportTemplateDataSource,
  ReportTemplateVisibility,
  ReportsApplicationsDto,
  ReportsBookingsDto,
  ReportsLocationsDto,
} from '@admissions-support/types';
import { compact } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

async function invalidateReportTemplateQuery() {
  await queryClient.invalidateQueries({
    queryKey: reportKey.listTemplates('ONLY_YOU'),
    refetchType: 'all',
  });
  await queryClient.invalidateQueries({
    queryKey: reportKey.listTemplates('ORG'),
    refetchType: 'all',
  });
}

function removeReportResultQueries() {
  queryClient.removeQueries({
    queryKey: ['query-result', 'bookings'],
  });
  queryClient.removeQueries({
    queryKey: ['query-result', 'applications'],
  });
  queryClient.removeQueries({
    queryKey: ['query-result', 'locations'],
  });
}

export const applicationsFilterDataSources = [
  'APPLICATIONS',
  'PRIMARY SCHOOL APPLICATIONS',
  'SECONDARY SCHOOL APPLICATIONS',
];

export const locationsFilterDataSources = ['LOCATIONS', 'SCHOOL LOCATIONS'];

export const isApplicationsFilter = (
  data: any,
  dataSource: string
): data is ReportsApplicationsDto => {
  return applicationsFilterDataSources.includes(dataSource);
};

export const isLocationsFilter = (
  data: any,
  dataSource: string
): data is ReportsLocationsDto => {
  return locationsFilterDataSources.includes(dataSource);
};

export const isBookingsFilter = (
  data: any,
  dataSource: string
): data is ReportsBookingsDto => {
  return dataSource === 'BOOKINGS';
};

function UpsertQueryBuilder() {
  const params = useParams<{ id?: string }>();
  const isNewReportTemplate = useMatch(staffRouterPath.NEW_QUERY_BUILDER);
  const {
    data: reportTemplate,
    isLoading: isReportLoading,
    isSuccess: isReportSuccess,
  } = useReportTemplate(params.id || '', {
    enabled: !isNewReportTemplate,
  });
  const canUserEditTemplate = useCanUserEditTemplate();
  const navigate = useNavigate();

  const handleReportTemplateSubmit = async (data: QueryBuilderFormData) => {
    /**
     * This would never happen as toggledColumns gets a value after dataSource is selected
     * which is required to pass validation so this is only here for type safety
     */
    if (!toggledColumns) {
      return;
    }

    const transformedToggledColumns = compact(
      Object.keys(toggledColumns).map(propertyKey =>
        toggledColumns[propertyKey] ? propertyKey : undefined
      )
    );

    const createDto: CreateReportTemplateDto = {
      name: data.name,
      visibility: data.visibility
        ? ('ORGANISATION' as ReportTemplateVisibility)
        : ('ONLY_YOU' as ReportTemplateVisibility),
      dataSource: data.dataSource as ReportTemplateDataSource,
      conditions: data.conditionGroups.map(conditionGroup => ({
        variant: conditionGroup.variant,
        data: conditionGroup.data.map(condition => ({
          type: condition.type,
          value: condition.value,
        })),
      })),
      toggledColumns: transformedToggledColumns,
    };

    if (isNewReportTemplate) {
      const templateReport = await createReportTemplate(createDto);
      await invalidateReportTemplateQuery();
      return templateReport;
    }

    const templateReport = await updateReportTemplate({
      id: params.id || '',
      data: createDto,
    });
    await invalidateReportTemplateQuery();

    return templateReport;
  };

  const { form, handleSubmit } = useQueryBuilderForm({
    onSubmit: handleReportTemplateSubmit,
  });

  const dataSource = form.watch('dataSource');

  const {
    toggledColumns,
    setToggledColumns,
    setPagination,
    pagination,
    filter,
    setFilter,
    setHasQueryRun,
    hasQueryRun,
  } = useReportingOutputControl();

  useEffect(() => {
    if (!isReportSuccess) {
      return;
    }
    const newToggledColumns = exportableKeysApplications.reduce((acc, key) => {
      return {
        ...acc,
        [key]: reportTemplate.toggledColumns.includes(key) || false,
      };
    }, {});

    setToggledColumns(newToggledColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportSuccess, reportTemplate]);

  // Remove queries when component unmounts (user navigates away)
  useEffect(() => {
    return () => {
      removeReportResultQueries();
    };
  }, []);

  // These queries need to be the same as the ones in query-builder.tsx and output-table.tsx (arguments wise)
  const { refetch: refetchApplications, data: applicationsResults } =
    useApplicationsReportResult(
      {
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        ...(isApplicationsFilter(filter, dataSource) ? filter : []),
      },
      { enabled: false }
    );

  const { refetch: refetchBookings, data: bookingsResults } =
    useBookingsReportResult(
      {
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        ...(isBookingsFilter(filter, dataSource) ? filter : []),
      },
      { enabled: false }
    );

  const { refetch: refetchLocations, data: locationsResults } =
    useLocationsReportResult(
      {
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        ...(isLocationsFilter(filter, dataSource) ? filter : []),
      },
      { enabled: false }
    );

  const {
    mutateAsync: createReportTemplate,
    isPending: isCreateTemplateLoading,
  } = useCreateReportTemplate({
    onSuccess: async createdReport => {
      await invalidateReportTemplateQuery();
      navigate(
        generatePath(staffRouterPath.EDIT_QUERY_BUILDER, {
          id: createdReport.id,
        })
      );
    },
  });

  const {
    mutateAsync: updateReportTemplate,
    isPending: isUpdateReportLoading,
  } = useUpdateReportTemplate();

  const {
    mutateAsync: deleteReportTemplate,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteReportTemplate({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: reportKey.singleTemplate(params.id || ''),
      });
      await invalidateReportTemplateQuery();
      navigate(generatePath(staffRouterPath.REPORTING));
    },
  });

  useEffect(() => {
    if (!isReportSuccess) {
      return;
    }

    form.reset(transformQueryBuilderApiDataToFormData(reportTemplate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportSuccess]);

  const isFormDisabled = isCreateTemplateLoading || isUpdateReportLoading;

  useEffect(() => {
    setPagination(defaultReportingOutputControl.pagination);
    setFilter(defaultReportingOutputControl.filter);
    let newToggledColumnsValue = {};
    removeReportResultQueries();
    setHasQueryRun(false);
    switch (dataSource) {
      case 'APPLICATIONS':
        newToggledColumnsValue = applicationsToggledColumnsDefaultValue;
        break;
      case 'PRIMARY SCHOOL APPLICATIONS':
      case 'SECONDARY SCHOOL APPLICATIONS':
        newToggledColumnsValue = schoolApplicationsToggledColumnsDefaultValue;
        break;
      case 'BOOKINGS':
        newToggledColumnsValue = bookingsToggledColumnsDefaultValue;
        break;
      case 'LOCATIONS':
        newToggledColumnsValue = locationsToggledColumnsDefaultValue;
        break;
      case 'SCHOOL LOCATIONS':
        newToggledColumnsValue = schoolLocationsToggledColumnsDefaultValue;
        break;
      default:
        break;
    }
    setToggledColumns(newToggledColumnsValue);
    // adding setFilter causes infinite rerender
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, setPagination, setToggledColumns]);

  // refetch only if filter or pagination gets updated
  useEffect(() => {
    const isStartingValues =
      Object.keys(filter).length === 0 &&
      pagination.pageIndex ===
        defaultReportingOutputControl.pagination.pageIndex &&
      pagination.pageSize === defaultReportingOutputControl.pagination.pageSize;

    if (isStartingValues) {
      return;
    }
    if (applicationsFilterDataSources.includes(dataSource)) {
      refetchApplications();
    }
    if (dataSource === 'BOOKINGS') {
      refetchBookings();
    }
    if (locationsFilterDataSources.includes(dataSource)) {
      refetchLocations();
    }
    // we don't want it to run when dataSource is changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filter,
    pagination,
    refetchApplications,
    refetchBookings,
    refetchLocations,
  ]);

  if (isReportLoading) {
    return <LoadingScreen />;
  }

  if (!isNewReportTemplate && !isReportSuccess) {
    return <FallbackComponent />;
  }

  const handleCloneTemplate = async () => {
    if (!isReportSuccess) {
      toast.error('Report is not loaded! Try it again!');
      return;
    }
    if (!toggledColumns) {
      return;
    }

    const transformedToggledColumns = compact(
      Object.keys(toggledColumns).map(propertyKey =>
        toggledColumns[propertyKey] ? propertyKey : undefined
      )
    );

    await createReportTemplate({
      name: `Copy of ${reportTemplate.name}`,
      visibility: 'ONLY_YOU' as ReportTemplateVisibility,
      dataSource: reportTemplate.dataSource,
      conditions: reportTemplate.conditions,
      toggledColumns: transformedToggledColumns,
    });
  };

  const handleDeleteReportTemplate = async () => {
    if (!isReportSuccess) {
      return;
    }

    await deleteReportTemplate(reportTemplate.id);
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={form.handleSubmit(handleSubmit)}
        id="upsert-query-builder-form"
      >
        <PageTitle title="Query Builder" variant="gray">
          <div className="flex gap-3">
            {(isNewReportTemplate || canUserEditTemplate) && (
              <button
                className="btn btn-secondary"
                type="submit"
                disabled={isFormDisabled}
                form="upsert-query-builder-form"
              >
                {isNewReportTemplate ? 'Create Template' : 'Update'}
              </button>
            )}
            {!isNewReportTemplate && !canUserEditTemplate && (
              <button
                className="btn btn-secondary"
                type="button"
                disabled={isFormDisabled}
                onClick={handleCloneTemplate}
              >
                Clone Template
              </button>
            )}
          </div>
        </PageTitle>
        <div className="two-col-form mt-6">
          <div>
            <p className="text-md font-medium leading-7 text-gray-900">
              Report Details
            </p>
            <p className="text-md leading-6 text-gray-600">
              Update this reports name and availability.
            </p>
          </div>
          <div className="col-span-2 space-y-6">
            <ReportDetails />
          </div>
        </div>
        <div className="two-col-form mt-6">
          <div>
            <p className="text-md font-medium leading-7 text-gray-900">
              Step 1
            </p>
            <p className="text-md leading-6 text-gray-600">
              Select the data source you would like to use as the basis for your
              query.
            </p>
          </div>
          <div className="col-span-2 space-y-6">
            <DataSource />
          </div>
        </div>
        {dataSource ? (
          <>
            <div className="two-col-form mt-6">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Step 2
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Define the rules and conditions you would like to use, in
                  order to filter the returned data.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <QueryBuilder />
              </div>
            </div>
            {(locationsResults || applicationsResults || bookingsResults) &&
            hasQueryRun ? (
              <div className="py-6">
                <OutputTable dataSource={dataSource} />
              </div>
            ) : null}
          </>
        ) : null}
        {!isNewReportTemplate && canUserEditTemplate ? (
          <div className="two-col-form border-t">
            <div>
              <p className="text-md font-medium leading-7 text-gray-900">
                Destructive Actions
              </p>
              <p className="text-md leading-6 text-gray-600">
                Take care with these actions as they are destructive and may not
                be reversable.
              </p>
            </div>
            <div className="col-span-2 space-y-6">
              <DeleteZone
                title="Delete Template"
                actionButtonText="Delete Template"
                onDelete={handleDeleteReportTemplate}
                isLoading={isDeleting}
                error={getErrorMessage(deleteError)}
              />
            </div>
          </div>
        ) : null}
      </form>
    </FormProvider>
  );
}

export { UpsertQueryBuilder };
