import { schoolLocationKey } from '@/config/query-keys';
import { schoolLocationService } from '@/services/school-location.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteSchoolLocationMutation(
  mutationOptions?: UseMutationOptions<void, HttpError, string>
) {
  return useMutation({
    mutationKey: schoolLocationKey.delete,
    mutationFn: schoolLocationService.deleteSchoolLocation,
    ...mutationOptions,
  });
}

export { useDeleteSchoolLocationMutation };
