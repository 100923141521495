import { TextInput } from '@/components/form/common/text-input';
import * as yup from 'yup';
import { schoolLocationKey } from '@/config/query-keys';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateSchoolLocationMutation } from '@/hooks/update-hooks/use-update-school-location-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { isBadRequest } from '@/types/error';
import { HttpError } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stage,
  PrimaryCapacityDto,
  SecondaryCapacityDto,
  PrimaryCapacity,
} from '@admissions-support/types';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { InferType } from 'yup';
import { useEffect } from 'react';

type SchoolLocationStagesFormProps = {
  schoolStages: Stage;
};

const order: { [key: string]: number } = {
  one: 0,
  two: 1,
  three: 2,
  four: 3,
  five: 4,
  six: 5,
  seven: 6,
};

function isPrimaryLocation(capacity: any): capacity is PrimaryCapacity {
  if (capacity.primaryOne || capacity.primaryOne === 0) {
    return true;
  }
  return false;
}

function transformCapacity(capacity: Record<string, any>) {
  for (const key in capacity) {
    if (!Number(capacity[key]) && Number(capacity[key]) !== 0) {
      toast.error(`The following field is not a number: ${key}`);
      return;
    }
    capacity[key] = Number(capacity[key]);
  }

  return capacity as PrimaryCapacityDto | SecondaryCapacityDto;
}

const schoolLocationStagesFormSchema = yup.object({
  maxPhysicalCapacity: yup.number().required().label('Max Physical Capacity'),
  capacity: yup
    .object()
    .required()
    .when('$isPrimaryLocation', function valami([isPrimaryLocation]) {
      if (isPrimaryLocation) {
        return yup.object({
          primaryOne: yup
            .number()
            .required()
            .label('Primary 1')
            .typeError('This field must be a number'),
          primaryTwo: yup
            .number()
            .required()
            .label('Primary 2')
            .typeError('This field must be a number'),
          primaryThree: yup
            .number()
            .required()
            .label('Primary 3')
            .typeError('This field must be a number'),
          primaryFour: yup
            .number()
            .required()
            .label('Primary 4')
            .typeError('This field must be a number'),
          primaryFive: yup
            .number()
            .required()
            .label('Primary 5')
            .typeError('This field must be a number'),
          primarySix: yup
            .number()
            .required()
            .label('Primary 6')
            .typeError('This field must be a number'),
          primarySeven: yup
            .number()
            .required()
            .label('Primary 7')
            .typeError('This field must be a number'),
        });
      }

      return yup.object({
        secondaryOne: yup
          .number()
          .required()
          .label('Secondary 1')
          .typeError('This field must be a number'),
        secondaryTwo: yup
          .number()
          .required()
          .label('Secondary 2')
          .typeError('This field must be a number'),
        secondaryThree: yup
          .number()
          .required()
          .label('Secondary 3')
          .typeError('This field must be a number'),
        secondaryFour: yup
          .number()
          .required()
          .label('Secondary 4')
          .typeError('This field must be a number'),
        secondaryFive: yup
          .number()
          .required()
          .label('Secondary 5')
          .typeError('This field must be a number'),
        secondarySix: yup
          .number()
          .required()
          .label('Secondary 6')
          .typeError('This field must be a number'),
      });
    }),
});

type SchoolLocationStagesFormData = InferType<
  typeof schoolLocationStagesFormSchema
>;

function SchoolLocationStagesForm(props: SchoolLocationStagesFormProps) {
  const { schoolStages } = props;

  const form = useForm<SchoolLocationStagesFormData>({
    resolver: yupResolver(schoolLocationStagesFormSchema),
    defaultValues: {
      maxPhysicalCapacity: schoolStages.maxPhysicalCapacity || 0,
      capacity: schoolStages.capacity,
    },
    context: { isPrimaryLocation: isPrimaryLocation(schoolStages.capacity) },
  });

  useEffect(() => {
    form.reset({
      maxPhysicalCapacity: schoolStages.maxPhysicalCapacity || 0,
      capacity: schoolStages.capacity,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolStages]);

  const { mutateAsync: update, isPending } = useUpdateSchoolLocationMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: schoolLocationKey.allSchoolLocations,
        refetchType: 'active',
      });
    },
  });

  const { hasPermission } = usePermissions();

  const params = useParams<{ schoolId: string }>();

  const submitHandler = async (data: SchoolLocationStagesFormData) => {
    try {
      const transformedCapacity = transformCapacity(data.capacity);
      if (!transformedCapacity) {
        return;
      }
      await update({
        id: params.schoolId || '',
        data: {
          stage: {
            maxPhysicalCapacity: Number(data.maxPhysicalCapacity),
            capacity: transformedCapacity,
            schoolYearId: schoolStages.schoolYear.id.toString(),
          },
        },
      });
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const isFormDisabled =
    !hasPermission([Permission['location:update']]) || isPending;

  const orderedCapacityKeys: string[] = [];

  // Since the BE is returning the keys of the object unordered, we have to order them
  Object.keys(schoolStages.capacity).forEach(capKey => {
    const numbers = Object.keys(order);

    const position = numbers.find(num => capKey.toLowerCase().includes(num));

    if (!position) {
      return;
    }

    orderedCapacityKeys[order[position]] = capKey;
  });

  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Physical Capacity
        </p>
        <p className="text-md leading-6 text-gray-600">
          Define physical limits and ages supported as defined by the care
          inspectorate.
        </p>
      </div>
      <div className="col-span-2">
        <div>
          <FormProvider {...form}>
            <form
              className="mb-6 flex h-full flex-1 flex-col"
              id="update-stages-form"
              onSubmit={form.handleSubmit(submitHandler)}
            >
              <TextInput
                label="Max Physical Capacity (Building)"
                name="maxPhysicalCapacity"
                helperText="This is the maximum capacity for children of any age as specified by the care inspectorate."
                placeholder="40"
                type="number"
                disabled={isFormDisabled}
              />
              <div className="light-gray-container mt-6">
                <p className="font-semibold text-gray-700">
                  Placement Capacity
                </p>
                <div className="mt-6 grid grid grid-cols-3 gap-6">
                  {orderedCapacityKeys.map((capKey, idx) => {
                    const labelPrefix = isPrimaryLocation(schoolStages.capacity)
                      ? 'Primary'
                      : 'Secondary';
                    return (
                      <TextInput
                        label={`${labelPrefix} ${idx + 1}`}
                        name={`capacity.${capKey}`}
                        key={capKey}
                        disabled={isFormDisabled}
                      />
                    );
                  })}
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
}

export { SchoolLocationStagesForm };
