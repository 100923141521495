import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useSchoolYear } from '@/context/school-year.context';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';

function CohortFilter() {
  const { filter, setFilter } = useApplicationControl();
  const { schoolYear } = useSchoolYear();

  const cohorts = schoolYear.cohorts.map(cohort => ({
    label: cohort.cohort.name,
    value: cohort.cohort.id,
  }));

  const handleCohortChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedLocation = event.target.name;

    const index = filter.cohorts.indexOf(selectedLocation);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        cohorts: [...prevFilter.cohorts, selectedLocation],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        cohorts: prevFilter.cohorts.filter(
          location => location !== selectedLocation
        ),
        //set filter false if cohorts gonna be empty
        isSiblingAtLocation:
          prevFilter.cohorts.length === 1
            ? false
            : prevFilter.isSiblingAtLocation,
      }));
    }
  };

  const handleSelectAllCohorts = () => {
    if (filter.cohorts.length === cohorts.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        cohorts: defaultApplicationControl.filter.cohorts,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        cohorts: cohorts.map(s => s.value.toString()),
      }));
    }
  };

  const isSomeSelected = cohorts.some(location =>
    filter.cohorts.includes(location.value.toString())
  );

  return (
    <>
      {cohorts.map(cohort => {
        return (
          <CheckboxBase
            key={cohort.value.toString()}
            name={cohort.value.toString()}
            label={cohort.label}
            onChange={handleCohortChange}
            checked={
              filter.cohorts.findIndex(c => c === cohort.value.toString()) >= 0
            }
          />
        );
      })}
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllCohorts"
        label="Select All"
        onChange={handleSelectAllCohorts}
        checked={filter.cohorts.length === cohorts.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

export { CohortFilter };
