import { ParentPlacementPreferencesForm } from '@/components/application/placement-preferences/parent-placement-preferences.form';
import { SchoolPlacementPreferencesForm } from '@/components/application/placement-preferences/school-placement-preferences.form';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useListSchoolLocations } from '@/hooks/query-hooks/use-list-school-locations';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import {
  Application,
  PrimarySchoolApplication,
  SecondarySchoolApplication,
} from '@admissions-support/types';
import { Navigate, useParams } from 'react-router-dom';

function ParentPlacementPreferences() {
  const params = useParams<{ id: string }>();
  const applicationId = params.id || '';

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(applicationId, {
    enabled: Boolean(applicationId),
  });

  const {
    data: locationsData,
    error: locationError,
    isLoading: isLocationDataLoding,
  } = useLocations(undefined, {
    select: locations =>
      //hide archived/inactive locations
      locations.filter(location => location.status === 'ACTIVE'),
  });

  const { mutateAsync: updateApplication } =
    useUpdateApplicationMutation(applicationId);

  const { data: schoolLocations, isLoading: isSchoolLocationsLoading } =
    useListSchoolLocations();

  const isFormLoading =
    isApplicationLoading || isLocationDataLoding || isSchoolLocationsLoading;

  const locations = Array.isArray(locationsData)
    ? locationsData.map(location => ({
        key: location.id,
        value: location.name,
      }))
    : [];

  const { data: applicationTemplate } = useApplicationTemplate(
    application?.template?.id?.toString() || '',
    {
      enabled: !!application?.template?.id,
    }
  );

  if (applicationError || locationError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  if (isApplicationLoading) {
    return (
      <div className="flex justify-center py-12">
        <Spinner className="h-12 w-12" />
      </div>
    );
  }

  const isNurseryApplication = applicationTemplate?.applicationType === 'ELC';

  return (
    <div>
      {!isFormLoading && application && locations ? (
        isNurseryApplication ? (
          <ParentPlacementPreferencesForm
            // Casting is safe because we know the application is an ELC application
            initialData={application as Application}
            locations={locations}
            onSubmit={updateApplication}
          />
        ) : (
          <SchoolPlacementPreferencesForm
            schoolLocations={schoolLocations || []}
            initialData={
              application as
                | PrimarySchoolApplication
                | SecondarySchoolApplication
            }
            schoolType={
              applicationTemplate?.applicationType as 'PRIMARY' | 'SECONDARY'
            }
          />
        )
      ) : (
        <div className="flex items-center justify-center px-4 py-20">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export { ParentPlacementPreferences };
