import { SCHOOL_STAGE_LABELS } from '@/utils/location-utils';
import { Condition } from './reporting.type';
import { SchoolApplicationYearStage } from '@admissions-support/types';

const applicationConditionTypes = [
  'isChildWithAdditionalNeeds',
  'isArchived',
  'isFlagged',
  'isLookedAfter',
  'isSplitPlacement',
  'isAttendedBySibling',
  'isInCouncilArea',
  'hasDeferredChoice',
  'hasFlaggedChoice',
  'status',
  'schoolYearId',
  'locationId',
  'ratioId',
  'sessionId',
  'sessionTypeId',
  'choiceStatus',
  'homeLanguage',
  'homeStatus',
  'moveInDate',
  'address.lineOne',
  'address.lineTwo',
  'address.lineThree',
  'address.lineFour',
  'address.postcode',
  'carer.firstName',
  'carer.lastName',
  'carer.relationship',
  'carer.mobile',
  'carer.email',
  'carer.isPreferredContact',
  'sibling.firstName',
  'sibling.lastName',
  'sibling.dateOfBirth',
  'sibling.sex',
  'sibling.stage',
  'doctorName',
  'healthVisitor',
  'surgeryName',
  'isVisuallyImpaired',
  'isEpileptic',
  'isDiabetic',
  'isAllergic',
  'isHearingImpaired',
  'isAsmatic',
  'isSpeechImpaired',
] as const;

const schoolApplicationConditionTypes = [
  'yearStage',
  'number',
  'feederSchool',
] as const;

const locationConditionTypes = [
  'name',
  'type',
  'status',
  'providerType',
  'serviceProviderNumber',
  'careInspectorNumber',
  'careInspectorNumber',
  'contactNumber',
  'website',
  'addressStreetAddress',
  'addressStreetAddressTwo',
  'addressCity',
  'addressPostcode',
  'keyContactFirstName',
  'keyContactLastName',
  'keyContactEmail',
  'notes',
  'areaName',
] as const;

const bookingConditionTypes = [
  'date',
  'price',
  'childId',
  'paymentStatus',
  'productId',
  'status',
  'schoolYearId',
  'locationId',
  'ratioId',
] as const;

const dataSources = [
  {
    name: 'APPLICATIONS',
    displayName: 'ELC Applications',
    description:
      'Search application specific items such as choice related data, child address, looked after status, parent/carer details.',
    subtitle: 'eg. See all second choices that are matched.',
  },
  {
    name: 'PRIMARY SCHOOL APPLICATIONS',
    displayName: 'Primary School Applications',
    description:
      'Search primary school application specific items such as choice related data, child address, looked after status, parent/carer details.',
    subtitle: 'eg. See all second choices that are matched.',
  },
  {
    name: 'SECONDARY SCHOOL APPLICATIONS',
    displayName: 'Secondary School Applications',
    description:
      'Search secondary school application specific items such as choice related data, child address, looked after status, parent/carer details.',
    subtitle: 'eg. See all second choices that are matched.',
  },
  {
    name: 'LOCATIONS',
    displayName: 'ELC Locations',
    description: 'Query location details such as address.',
    subtitle: 'eg. See key contacts for each nursery setting.',
  },
  {
    name: 'SCHOOL LOCATIONS',
    displayName: 'School Locations',
    description: 'Query school location details such as address.',
    subtitle: 'eg. See key contacts for each nursery setting.',
  },
  // {
  //   name: 'BALANCE',
  //   description:
  //     'Generate reports to determine credits and debits for a child’s account.',
  //   subtitle: 'eg. See all children with overdue accounts.',
  // },
  {
    name: 'BOOKINGS',
    displayName: 'Extra Nursery Bookings',
    description: 'Query bookings for a child, by status, price etc.',
    subtitle: 'eg. All bookings for a nursery setting for the next 7 days',
  },
];

const extraConditionDataSources = [
  {
    dataSource: 'PRIMARY SCHOOL APPLICATIONS',
    extraConditions: [
      {
        key: 'applicationsType',
        value: 'PRIMARY',
      },
    ],
  },
  {
    dataSource: 'SECONDARY SCHOOL APPLICATIONS',
    extraConditions: [
      {
        key: 'applicationsType',
        value: 'SECONDARY',
      },
    ],
  },
  {
    dataSource: 'SCHOOL LOCATIONS',
    extraConditions: [
      {
        key: 'locationQueryType',
        value: 'SCHOOL',
      },
    ],
  },
];

const applicationReportConditions: { label: string; options: Condition[] }[] = [
  {
    label: 'General',
    options: [
      {
        value: 'isArchived',
        label: 'Is Archived',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isFlagged',
        label: 'Is flagged',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isLookedAfter',
        label: 'Is Looked After',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isSplitPlacement',
        label: 'Is Split Placement',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isAttendedBySibling',
        label: 'Is Attended By Sibling',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isInCouncilArea',
        label: 'Is In Council Area',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'hasDeferredChoice',
        label: 'Has Deferred Choice',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'hasFlaggedChoice',
        label: 'Has Flagged Choice',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'status',
        label: 'Application Status',
        inputType: 'select',
        options: [
          { value: 'DRAFT', label: 'Draft' },
          { value: 'SUBMITTED', label: 'Submitted' },
          { value: 'PLACED', label: 'Placed' },
          { value: 'GRANTED', label: 'Granted' },
          { value: 'NOT_PLACED', label: 'Not Placed' },
          { value: 'PLACEMENT_REJECTED', label: 'Placement Rejected' },
        ],
      },
      {
        value: 'schoolYearId',
        label: 'School Year',
        inputType: 'select',
        options: [],
      },
      {
        value: 'locationId',
        label: 'Location',
        inputType: 'select',
        options: [],
      },
      { value: 'ratioId', label: 'Ratio', inputType: 'select', options: [] },
      {
        value: 'sessionId',
        label: 'Session',
        inputType: 'select',
        options: [],
      },
      {
        value: 'sessionTypeId',
        label: 'Session Type',
        inputType: 'select',
        options: [],
      },
      {
        value: 'choiceStatus',
        label: 'Choice Status',
        inputType: 'select',
        options: [
          { value: 'SCORED', label: 'Scored' },
          { value: 'NOT_MATCHED', label: 'Not Matched' },
          { value: 'WAITING_LIST', label: 'Waiting List' },
          { value: 'MATCHED', label: 'Matched' },
          { value: 'PLACED', label: 'Placed' },
          { value: 'GRANTED', label: 'Granted' },
          { value: 'PLACEMENT_REJECTED', label: 'Placement Rejected' },
        ],
      },
      {
        value: 'homeLanguage',
        label: 'Home Language',
        inputType: 'text',
      },
      {
        value: 'homeStatus',
        label: 'Home Status',
        inputType: 'select',
        options: [
          { value: 'LIVING', label: 'Living In' },
          { value: 'MOVING', label: 'Moving In' },
        ],
      },
      { value: 'moveInDate', label: 'Move-In Date', inputType: 'date' },
    ],
  },
  {
    label: 'Address',
    options: [
      {
        value: 'address.lineOne',
        label: 'Address Line 1',
        inputType: 'text',
      },
      {
        value: 'address.lineTwo',
        label: 'Address Line 2',
        inputType: 'text',
      },
      {
        value: 'address.lineThree',
        label: 'Address Line 3',
        inputType: 'text',
      },
      {
        value: 'address.lineFour',
        label: 'Address Line 4',
        inputType: 'text',
      },
      { value: 'address.postcode', label: 'Postcode', inputType: 'text' },
    ],
  },
  {
    label: 'Carer',
    options: [
      {
        value: 'carer.firstName',
        label: 'Carer First Name',
        inputType: 'text',
      },
      {
        value: 'carer.lastName',
        label: 'Carer Last Name',
        inputType: 'text',
      },
      {
        value: 'carer.relationship',
        label: 'Carer Relationship',
        inputType: 'text',
      },
      { value: 'carer.mobile', label: 'Carer Mobile', inputType: 'text' },
      { value: 'carer.email', label: 'Carer Email', inputType: 'text' },
      {
        value: 'carer.isPreferredContact',
        label: 'Is Carer Preferred Contact',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
    ],
  },
  {
    label: 'Sibling',
    options: [
      {
        value: 'sibling.firstName',
        label: 'Sibling First Name',
        inputType: 'text',
      },
      {
        value: 'sibling.lastName',
        label: 'Sibling Last Name',
        inputType: 'text',
      },
      {
        value: 'sibling.dateOfBirth',
        label: 'Sibling Date of Birth',
        inputType: 'date',
      },
      {
        value: 'sibling.sex',
        label: 'Sibling Sex',
        inputType: 'select',
        options: [
          { value: 'female', label: 'Female' },
          { value: 'male', label: 'Male' },
        ],
      },
      {
        value: 'sibling.stage',
        label: 'Sibling Stage',
        inputType: 'select',
        options: [
          { value: 'nursery', label: 'Nursery' },
          { value: 'child-minder', label: 'Child Minder' },
          { value: 'playgroup', label: 'Playgroup' },
          { value: 'primary', label: 'Primary' },
          { value: 'secondary', label: 'Secondary' },
        ],
      },
    ],
  },
  {
    label: 'Health',
    options: [
      {
        value: 'isChildWithAdditionalNeeds',
        label: 'Additional Needs',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      { value: 'doctorName', label: 'Doctor Name', inputType: 'text' },
      {
        value: 'healthVisitor',
        label: 'Health Visitor',
        inputType: 'text',
      },
      { value: 'surgeryName', label: 'Surgery Name', inputType: 'text' },
      {
        value: 'isVisuallyImpaired',
        label: 'Is Visually Impaired',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isEpileptic',
        label: 'Is Epileptic',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isDiabetic',
        label: 'Is Diabetic',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isAllergic',
        label: 'Is Allergic',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isHearingImpaired',
        label: 'Is Hearing Impaired',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isAsmatic',
        label: 'Is Asmatic',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
      {
        value: 'isSpeechImpaired',
        label: 'Is Speech Impaired',
        inputType: 'select',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
      },
    ],
  },
];

const commonApplicationConditionLabels = [
  'Address',
  'Carer',
  'Sibling',
  'Health',
];

const commonApplicationConditions = applicationReportConditions.filter(
  reportCondition =>
    commonApplicationConditionLabels.includes(reportCondition.label)
);

const commonApplicationGeneralConditionsValues = [
  'isArchived',
  'isFlagged',
  'isLookedAfter',
  'isInCouncilArea',
  'isAttendedBySibling',
  'status',
  'schoolYearId',
  'locationId',
  'choiceStatus',
  'homeLanguage',
  'homeStatus',
  'moveInDate',
];

const applicationGeneralConditions =
  applicationReportConditions.find(
    reportCondition => reportCondition.label === 'General'
  ) ||
  ({} as {
    label: string;
    options: Condition[];
  });

const commonApplicationGeneralConditions =
  applicationGeneralConditions?.options?.filter(option =>
    commonApplicationGeneralConditionsValues.includes(option.value)
  ) || [];

const getYearStageOptions = (yearStageType: 'primary' | 'secondary') => {
  return Object.keys(SCHOOL_STAGE_LABELS)
    .filter(key => key.includes(yearStageType))
    .map(key => {
      return {
        value: key,
        label: SCHOOL_STAGE_LABELS[key as SchoolApplicationYearStage],
      };
    });
};

const primaryApplicationReportConditions: {
  label: string;
  options: Condition[];
}[] = [
  {
    label: 'General',
    options: [
      ...commonApplicationGeneralConditions,
      {
        value: 'yearStage',
        label: 'Year Stage',
        inputType: 'select',
        options: getYearStageOptions('primary'),
      },
    ],
  },
  ...commonApplicationConditions,
];

const secondaryApplicationReportConditions: {
  label: string;
  options: Condition[];
}[] = [
  {
    label: 'General',
    options: [
      ...commonApplicationGeneralConditions,
      {
        value: 'yearStage',
        label: 'Year Stage',
        inputType: 'select',
        options: getYearStageOptions('secondary'),
      },
    ],
  },
  ...commonApplicationConditions,
];

const locationAddressConditions: { label: string; options: Condition[] } = {
  label: 'Address',
  options: [
    {
      value: 'addressStreetAddress',
      label: 'Address Street Address',
      inputType: 'text',
    },
    {
      value: 'addressStreetAddressTwo',
      label: 'Address Street Address Two',
      inputType: 'text',
    },
    { value: 'addressCity', label: 'Address City', inputType: 'text' },
    {
      value: 'addressPostcode',
      label: 'Address Postcode',
      inputType: 'text',
    },
  ],
};

const locationReportConditions: { label: string; options: Condition[] }[] = [
  {
    label: 'General',
    options: [
      { value: 'name', label: 'Name', inputType: 'text' },
      {
        value: 'status',
        label: 'Status',
        inputType: 'select',
        options: [
          { value: 'ACTIVE', label: 'Active' },
          { value: 'INACTIVE', label: 'Inactive' },
          { value: 'ARCHIVED', label: 'Archived' },
        ],
      },
      {
        value: 'providerType',
        label: 'Provider Type',
        inputType: 'select',
        options: [
          { value: 'COUNCIL', label: 'Council' },
          { value: 'CHILD_MINDER', label: 'Child Minder' },
          { value: 'PARTNER_PROVIDER', label: 'Partner Provider' },
        ],
      },
      {
        value: 'serviceProviderNumber',
        label: 'Service Provider Number',
        inputType: 'text',
      },
      {
        value: 'careInspectorNumber',
        label: 'Care Inspector Number',
        inputType: 'text',
      },
      { value: 'contactNumber', label: 'Contact Number', inputType: 'text' },
      { value: 'website', label: 'Website', inputType: 'text' },
      {
        value: 'keyContactFirstName',
        label: 'Key Contact First Name',
        inputType: 'text',
      },
      {
        value: 'keyContactLastName',
        label: 'Key Contact Last Name',
        inputType: 'text',
      },
      {
        value: 'keyContactEmail',
        label: 'Key Contact Email',
        inputType: 'text',
      },
      { value: 'notes', label: 'Notes', inputType: 'text' },
      {
        value: 'areaName',
        label: 'Area Name',
        inputType: 'select',
        options: [],
      },
    ],
  },
  locationAddressConditions,
];

const locationGeneralCommonOptionValues = [
  'name',
  'status',
  'website',
  'keyContactFirstName',
  'keyContactLastName',
  'keyContactEmail',
  'notes',
];

const getSchoolLocationGeneralConditions = () => {
  const locationGeneralCondition =
    locationReportConditions.find(condition => condition.label === 'General') ||
    ({} as { label: string; options: Condition[] });

  const locationGeneralOptions = locationGeneralCondition.options.filter(
    option => locationGeneralCommonOptionValues.includes(option.value)
  );

  const numberOption: Condition = {
    value: 'number',
    label: 'Contact Number',
    inputType: 'text',
  };

  const typeOption: Condition = {
    value: 'type',
    label: 'Type',
    inputType: 'select',
    options: [
      { value: 'PRIMARY', label: 'Primary Roman Catholic' },
      {
        value: 'PRIMARY_NON_DENOMINATIONAL',
        label: 'Primary Non-Denominational',
      },
      { value: 'SECONDARY', label: 'Secondary Roman Catholic' },
      {
        value: 'SECONDARY_NON_DENOMINATIONAL',
        label: 'Secondary Non-Denominational',
      },
    ],
  };

  const feederSchoolOption: Condition = {
    value: 'feederSchool',
    label: 'Feeder School Name',
    inputType: 'select',
    options: [],
  };

  locationGeneralOptions.splice(1, 0, typeOption);
  locationGeneralOptions.splice(3, 0, numberOption);

  locationGeneralOptions.push(feederSchoolOption);

  return {
    label: 'General',
    options: locationGeneralOptions,
  };
};

const schoolLocationReportConditions: {
  label: string;
  options: Condition[];
}[] = [getSchoolLocationGeneralConditions(), locationAddressConditions];

const bookingReportConditions: { label: string; options: Condition[] }[] = [
  {
    label: 'General',
    options: [
      {
        value: 'price',
        label: 'Price',
        inputType: 'text',
      },
      {
        value: 'date',
        label: 'Date',
        inputType: 'date',
      },
      //TODO: re-add when getChildren endpoint exists
      // {
      //   value: 'childId',
      //   label: 'Child',
      //   inputType: 'select',
      //   options: [],
      // },
      {
        value: 'paymentStatus',
        label: 'Payment Status',
        inputType: 'select',
        options: [
          { value: 'PAID', label: 'Paid' },
          { value: 'PAYMENT_DUE', label: 'Payment Due' },
          { value: 'UNPAID', label: 'Unpaid' },
        ],
      },
      {
        value: 'status',
        label: 'Booking Status',
        inputType: 'select',
        options: [
          { value: 'ACTIVE', label: 'Active' },
          { value: 'CANCELLED', label: 'Cancelled' },
        ],
      },
      {
        value: 'schoolYearId',
        label: 'School Year',
        inputType: 'select',
        options: [],
      },
      {
        value: 'productId',
        label: 'Product',
        inputType: 'select',
        options: [],
      },
      {
        value: 'locationId',
        label: 'Location',
        inputType: 'select',
        options: [],
      },
      { value: 'ratioId', label: 'Ratio', inputType: 'select', options: [] },
      {
        value: 'sessionId',
        label: 'Session',
        inputType: 'select',
        options: [],
      },
    ],
  },
];

export {
  applicationConditionTypes,
  schoolApplicationConditionTypes,
  applicationReportConditions,
  primaryApplicationReportConditions,
  secondaryApplicationReportConditions,
  schoolLocationReportConditions,
  bookingConditionTypes,
  bookingReportConditions,
  dataSources,
  locationConditionTypes,
  locationReportConditions,
  extraConditionDataSources,
};
