import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useApplicationTemplates } from '@/hooks/query-hooks/use-application-templates';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { ApplicationTemplate } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { capitalize } from 'lodash-es';
import { useMemo } from 'react';
import { Link, generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function ApplicationTemplates() {
  const navigate = useNavigate();
  const { isLoading, data: templates, error } = useApplicationTemplates();

  const columnHelper = createColumnHelper<ApplicationTemplate>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(
      staffRouterPath.EDIT_APPLICATION_TEMPLATE,
      {
        id,
      }
    );
    navigate(redirectUrl);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        size: 400,
      }),
      columnHelper.accessor('applicationType', {
        cell: info => {
          const type = info.getValue();

          if (type !== 'ELC') {
            return capitalize(type.toLowerCase());
          }

          return type;
        },
        header: () => <span>Type</span>,
      }),
      columnHelper.accessor('criteria', {
        cell: info => <span>{info.getValue().length > 0 ? 'Yes' : '--'}</span>,
        header: () => <span>Additional Criteria</span>,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-af-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit Application Form</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <PageTitle title="Application Forms" variant="gray" className="mb-6">
        <PermissionGuard requiredPermissions={[Permission['managers']]}>
          <Link
            type="button"
            className="btn btn-primary"
            to={staffRouterPath.NEW_APPLICATION_TEMPLATE}
          >
            Add Form
          </Link>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-af-tooltip"
        content="Edit Application Form"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={templates}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Application Form Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any application form added. Please add
              your first application form.
            </p>
          </div>
        }
      />
    </>
  );
}

export { ApplicationTemplates };
