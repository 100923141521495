import { Toggle } from '@/components/form/common/toggle';
import { Tag } from '@/components/tag';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useUpdateApplicationTemplateMutation } from '@/hooks/update-hooks/use-update-application-template';
import { Question, QuestionType } from '@/types/application-template';
import { useParams } from 'react-router-dom';
import { invalidateApplicationTemplatesQuery } from './update-overview-form';

type QuestionItemProps = Question;
function QuestionItem({
  type,
  title,
  description,
  id,
  allowedLocationType,
}: QuestionItemProps) {
  const params = useParams<{ id?: string }>();

  const { data: applicationTemplate, isSuccess: isApplicationTemplateLoaded } =
    useApplicationTemplate(params.id || '');

  const { mutateAsync: update } = useUpdateApplicationTemplateMutation({
    onSettled: invalidateApplicationTemplatesQuery,
  });

  const isEnabled = isApplicationTemplateLoaded
    ? applicationTemplate.enabledQuestions.findIndex(eq => eq === id) >= 0
    : false;

  const handleToggle = async (newValue: boolean) => {
    if (!isApplicationTemplateLoaded) {
      return;
    }

    const newEnabledQuestions = [];

    if (newValue) {
      newEnabledQuestions.push(...applicationTemplate.enabledQuestions);
      newEnabledQuestions.push(id);
    } else {
      newEnabledQuestions.push(
        ...applicationTemplate.enabledQuestions.filter(eq => eq !== id)
      );
    }
    await update({
      id: params.id || '',
      data: {
        enabledQuestions: newEnabledQuestions,
      },
    });
  };

  // Don't show questions unrelated to location type
  if (
    allowedLocationType &&
    applicationTemplate?.applicationType &&
    !allowedLocationType.includes(applicationTemplate?.applicationType)
  ) {
    return null;
  }

  return (
    <div className="rounded-md border p-4">
      <div className="mb-4 flex gap-2">
        <Tag type="outline" className="capitalize">
          {type}
        </Tag>

        {(type === QuestionType.System || isEnabled) && (
          <Tag type="success" className="capitalize">
            Enabled
          </Tag>
        )}

        {type === QuestionType.Optional && !isEnabled && (
          <Tag type="warning" className="capitalize">
            Disabled
          </Tag>
        )}
      </div>
      <p className="text-md font-semibold">{title}</p>
      {description && <p className="text-gray-500">{description}</p>}
      {type !== QuestionType.System && (
        <div className="mt-4">
          <Toggle
            label="Enable"
            disabled={!isApplicationTemplateLoaded}
            onChange={handleToggle}
            value={isEnabled}
          />
        </div>
      )}
    </div>
  );
}

export { QuestionItem };
