import { parentRouterPath, staffRouterPath } from '@/config/route-paths.config';
import { useCreateEmptyApplication } from '@/hooks/create-hooks/use-create-empty-application';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { ApplicationTemplate } from '@admissions-support/types';
import { ArrowRight, Star06 } from '@untitled-ui/icons-react';
import {
  generatePath,
  Link,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';

type ApplicationTemplateChoiceProps = {
  applicationTemplate: ApplicationTemplate;
  schoolYearId: string;
  childIdentificationNumber: string;
};

function ApplicationTemplateChoice({
  applicationTemplate,
  schoolYearId,
  childIdentificationNumber,
}: ApplicationTemplateChoiceProps) {
  const { childId } = useParams<{ childId: string }>();

  const {
    mutateAsync: createApplication,
    isPending: isApplicationMutating,
    isSuccess: isApplicationCreated,
    data: createdApplication,
  } = useCreateEmptyApplication();

  const navigate = useNavigate();

  const handleNewApplication = async () => {
    const result = await createApplication({
      schoolYearId: schoolYearId,
      identificationNumber: childIdentificationNumber,
      templateId: applicationTemplate.id,
    });

    const isNurseryApplication = applicationTemplate.applicationType === 'ELC';
    if (!isNurseryApplication) {
      const isAdmin = isAdminDashboard();
      const path = isAdmin
        ? staffRouterPath.NEW_APPLICATION_SELECT_SCHOOL_YEAR_STAGE
        : parentRouterPath.APPLICATION_CHILD_DETAILS;

      navigate(
        generatePath(path, {
          id: result.id,
        })
      );
      return;
    }
  };

  if (isApplicationCreated) {
    return (
      <Navigate
        to={generatePath(parentRouterPath.APPLICATION_CHILD_DETAILS, {
          id: createdApplication.id,
        })}
      />
    );
  }

  return (
    <div className="rounded-md border px-4 py-3">
      <div className="mb-3 flex space-x-3">
        <p className="text-md font-semibold">{applicationTemplate.name}</p>
        {applicationTemplate.criteria.length > 0 && (
          <span className="flex items-center rounded-md border border-gray-200 bg-gray-50 px-1.5 text-xs">
            <Star06
              viewBox="0 0 24 24"
              className="mr-1 h-4 w-4 text-gray-600"
            />
            Additional Eligibility Criteria
          </span>
        )}
      </div>
      {applicationTemplate.description && (
        <p className="mb-3 text-sm text-gray-600">
          {applicationTemplate.description}
        </p>
      )}

      {isAdminDashboard() ? (
        <button
          className="btn btn-primary mt-8 flex items-center"
          type="button"
          disabled={isApplicationMutating}
          onClick={handleNewApplication}
        >
          Continue with Application
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
        </button>
      ) : applicationTemplate.criteria.length > 0 ? (
        <div className="mt-6 flex">
          <Link
            className="btn btn-primary flex items-center"
            to={`${generatePath(
              parentRouterPath.APPLICATION_CONFIRM_ELIGIBILITY,
              {
                childId: childId || '',
              }
            )}?schoolYearId=${schoolYearId}&childIdentificationNumber=${childIdentificationNumber}&templateId=${
              applicationTemplate.id
            }`}
          >
            Continue
            <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
          </Link>
        </div>
      ) : (
        <button
          className="btn btn-primary mt-8 flex items-center"
          type="button"
          disabled={isApplicationMutating}
          onClick={handleNewApplication}
        >
          Continue with Application
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
        </button>
      )}
    </div>
  );
}

export { ApplicationTemplateChoice };
