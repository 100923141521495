import { twMerge } from 'tailwind-merge';

type TagProps = {
  type:
    | 'error'
    | 'warning'
    | 'success'
    | 'info'
    | 'default'
    | 'outline'
    | 'purple';
  children?: React.ReactNode;

  className?: string;
};

function Tag(props: TagProps) {
  const { children, type, className } = props;

  return (
    <span
      className={twMerge(
        'inline-flex items-center justify-center whitespace-nowrap rounded-md px-2 py-1 text-xs font-medium',
        type === 'error'
          ? 'border border-red-200 bg-red-50 text-red-700'
          : null,
        type === 'warning'
          ? 'border border-yellow-200 bg-yellow-50 text-yellow-700'
          : null,
        type === 'success'
          ? 'border border-green-200 bg-green-50 text-green-700'
          : null,
        type === 'info'
          ? 'border border-blue-200 bg-blue-50 text-blue-700'
          : null,
        type === 'default'
          ? 'border border-gray-200 bg-gray-50 text-gray-700'
          : null,
        type === 'outline'
          ? 'border border-gray-300 bg-white text-gray-700'
          : null,
        type === 'purple'
          ? 'border border-purple-200 bg-purple-50 text-purple-700'
          : null,
        className
      )}
    >
      {children}
    </span>
  );
}

export { Tag };
