import { PermissionGuard } from '@/components/auth/permission-guard';
import { PageTitle } from '@/components/page-title';
import { NewSchoolYearModal } from '@/components/settings/school-years/new-school-year-modal';
import { Table } from '@/components/table';
import { staffRouterPath } from '@/config/route-paths.config';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { useModal } from '@/hooks/use-modal';
import { Permission } from '@/types/auth';
import { formatDateToShortDate } from '@/utils/format-date';
import { getErrorMessage } from '@/utils/get-error-message';
import { SchoolYear } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchRefraction } from '@untitled-ui/icons-react';
import { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function SchoolYears() {
  const navigate = useNavigate();

  const { isLoading, data: terms, error } = useTerms();

  const { openModal, closeModal, isOpen } = useModal();

  const columnHelper = createColumnHelper<SchoolYear>();

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_TERM, {
      id,
    });
    navigate(redirectUrl);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => <span className="font-medium">{info.getValue()}</span>,
        header: () => <span>School Year</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.display({
        id: 'term_dates',
        cell: info => (
          <span className="font-mono">
            {formatDateToShortDate(info.row.original.startDate)}
            {' - '}
            {formatDateToShortDate(info.row.original.endDate)}
          </span>
        ),
        header: () => <span>Dates</span>,
        enableSorting: false,
        size: 260,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => (
          <button
            type="button"
            className="edit-term-tooltip rounded p-2"
            onClick={handleEdit(info.row.original.id)}
          >
            <Edit01
              className="h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <span className="sr-only">Edit School Year</span>
          </button>
        ),
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div>
      <PageTitle
        title="School Years"
        description="Add school years that locations can process applications for"
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['organisation:create']]}
        >
          <button type="button" className="btn btn-primary" onClick={openModal}>
            Add School Year
          </button>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-term-tooltip"
        content="Edit School Year"
        place="top"
        className="tooltip"
      />
      <Table
        columns={columns}
        data={terms}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No School Year Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any school year added. Please add your
              first school year.
            </p>
          </div>
        }
      />
      <NewSchoolYearModal isOpen={isOpen} onClose={closeModal} />
    </div>
  );
}

export { SchoolYears };
