import { FilterItem } from '@/components/filter-item';
import { CheckboxBase } from '@/components/form/common/checkbox';
import { TextInputBase } from '@/components/form/common/text-input';
import { Tag } from '@/components/tag';
import { useAreas } from '@/hooks/query-hooks/use-areas';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { Area, LocationProviderType } from '@admissions-support/types';
import { SearchSm } from '@untitled-ui/icons-react';
import { useState } from 'react';

type FilteredArea = Area & {
  allLocationIds: string[];
};

type AreaItemProps = {
  area: Area;
  allAreaLocationIds: string[];
  onChange: (locationIds: string[]) => void;
  selectedLocationIds: string[];
};

function AreaItem({
  area,
  onChange,
  selectedLocationIds,
  allAreaLocationIds,
}: AreaItemProps) {
  const isAllLocationsSelected =
    allAreaLocationIds.every(locationId =>
      selectedLocationIds.includes(locationId)
    ) && allAreaLocationIds.length > 0;

  const selectAllLocationsInArea = () => {
    if (isAllLocationsSelected) {
      const newLocationIds = selectedLocationIds.filter(
        id => !allAreaLocationIds.includes(id)
      );
      onChange(newLocationIds);
      return;
    }

    const newLocationIds = [...selectedLocationIds, ...allAreaLocationIds];
    onChange(newLocationIds);
  };

  const selectSingleLocation = (locationId: string) => {
    if (selectedLocationIds.includes(locationId)) {
      onChange(selectedLocationIds.filter(id => id !== locationId));
    } else {
      onChange([...selectedLocationIds, locationId]);
    }
  };

  const isSomeSelected = allAreaLocationIds.some(locationId =>
    selectedLocationIds.includes(locationId)
  );

  const isAreaChecked = isAllLocationsSelected;
  const isAreaIndeterminate = !isAllLocationsSelected && isSomeSelected;

  return (
    <div className="flex min-w-[300px] max-w-full flex-col">
      <div className="flex h-11 flex-col justify-center rounded-lg p-2">
        <CheckboxBase
          key={area.id}
          name={area.id}
          label={area.name}
          checked={isAreaChecked}
          indeterminate={isAreaIndeterminate}
          onChange={selectAllLocationsInArea}
          className="cursor-pointer text-left font-medium text-gray-900"
        />
      </div>

      {area?.locations?.length > 0 && (
        <div className="flex flex-col">
          {area?.locations?.map(location => (
            <div
              key={location.id.toString()}
              className="flex h-11 flex-col justify-center rounded-lg p-2"
            >
              <CheckboxBase
                name={location.id.toString()}
                label={location.name}
                checked={selectedLocationIds.includes(location.id.toString())}
                onChange={() => selectSingleLocation(location.id.toString())}
                className="cursor-pointer text-ellipsis whitespace-nowrap text-left font-normal text-gray-500"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

type SelectedLocationsProps = {
  selectedLocationIds: string[];
};

function SelectedLocations({ selectedLocationIds }: SelectedLocationsProps) {
  const { data: locations = [] } = useLocations();
  const selectedLocations = locations.filter(location =>
    selectedLocationIds.includes(location.id)
  );

  if (!selectedLocations.length) {
    return null;
  }

  const locationName = selectedLocations[0]?.name;

  return (
    <Tag type="default" className="mr-1">
      {locationName}
    </Tag>
  );
}

type LocationFilterProps = {
  selectedLocations: string[];
  onSelectedLocationsChange: (locationIds: string[]) => void;
  providerType: LocationProviderType;
};

function CapacityDemandLocationFilter({
  selectedLocations = [],
  onSelectedLocationsChange,
  providerType,
}: LocationFilterProps) {
  const [search, setSearch] = useState('');
  const { data: areas = [] } = useAreas({
    providerType,
  });

  const reset = () => {
    onSelectedLocationsChange([]);
    setSearch('');
  };

  const resetSearch = () => {
    setSearch('');
  };

  const orderedAreas = areas.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const filteredAreas = orderedAreas.reduce<FilteredArea[]>((prev, area) => {
    if (area.locations.length === 0) {
      return prev;
    }
    const isSearchInAreaName = area.name
      .toLowerCase()
      .includes(search.toLowerCase());

    const isSearchInLocationName = area.locations.some(location =>
      location.name.toLowerCase().includes(search.toLowerCase())
    );

    // If the search is not in the area name or location name, return the previous area
    if (!isSearchInAreaName && !isSearchInLocationName) {
      return prev;
    }

    // If the search is in the area name and not in the location name, return the previous area with no locations
    if (isSearchInAreaName && !isSearchInLocationName) {
      prev.push({
        ...area,
        locations: [],
        allLocationIds: area.locations.map(location => location.id.toString()),
      });
      return prev;
    }

    // If the search is in the area name and in the location name, return the previous area with the filtered locations
    const orderedLocations = area.locations.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    prev.push({
      ...area,
      locations: area.locations.filter(location =>
        location.name.toLowerCase().includes(search.toLowerCase())
      ),
      allLocationIds: orderedLocations.map(location => location.id.toString()),
    });

    return prev;
  }, []);

  return (
    <div className="w-full">
      <FilterItem
        label="Filter Location"
        counter={selectedLocations.length}
        reset={reset}
        onClose={resetSearch}
        renderSelectedItems={
          <SelectedLocations selectedLocationIds={selectedLocations} />
        }
      >
        <div>
          <TextInputBase
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search Locations or Areas"
            LeadingIcon={<SearchSm className="h-4 w-4" />}
          />
        </div>
        <div className="mt-2 max-h-96 space-y-2 overflow-y-auto">
          {filteredAreas?.map(area => (
            <AreaItem
              key={area.id.toString()}
              area={area}
              onChange={onSelectedLocationsChange}
              selectedLocationIds={selectedLocations}
              allAreaLocationIds={area.allLocationIds}
            />
          ))}
        </div>
      </FilterItem>
    </div>
  );
}

export { CapacityDemandLocationFilter };
