import { DeleteZone } from '@/components/delete-zone';
import {
  SchoolLocationForm,
  SchoolLocationFormData,
} from '@/components/form/school-location.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { locationKey, schoolLocationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateSchoolLocationMutation } from '@/hooks/create-hooks/use-create-school-location-mutation';
import { useDeleteSchoolLocationMutation } from '@/hooks/delete-hooks/use-delete-school-location.mutation';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useSchoolLocation } from '@/hooks/query-hooks/use-school-location';
import { useUpdateSchoolLocationMutation } from '@/hooks/update-hooks/use-update-school-location-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import {
  CreateSchoolLocationDto,
  SchoolLocationType,
  UpdateSchoolLocationDto,
} from '@admissions-support/types';
import { useEffect } from 'react';

import {
  generatePath,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

export const getTypeFromProviderType = (
  providerType: string,
  type: string
): SchoolLocationType => {
  if (providerType === 'NON_DENOMINATIONAL') {
    return `${type}_${providerType}` as SchoolLocationType;
  }
  return type as SchoolLocationType;
};

async function invalidateLocationQueries() {
  await queryClient.invalidateQueries({
    queryKey: locationKey.allLocations,
    refetchType: 'active',
  });
}

function UpsertSchoolLocation() {
  const navigate = useNavigate();

  const params = useParams<{ schoolId?: string }>();

  const schoolId = params.schoolId || '';

  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') || '';

  const { hasPermission } = usePermissions();

  const {
    mutateAsync: createSchoolLocation,
    isPending: isCreatingSchoolLocation,
  } = useCreateSchoolLocationMutation({
    onSuccess: async location => {
      await invalidateLocationQueries();

      const redirectUrl = generatePath(staffRouterPath.EDIT_SCHOOL_LOCATION, {
        schoolId: location.id,
      });
      navigate(redirectUrl);
    },
  });

  const {
    mutateAsync: updateSchoolLocation,
    isPending: isUpdatingSchoolLocation,
  } = useUpdateSchoolLocationMutation({
    onSuccess: invalidateLocationQueries,
  });

  const {
    mutateAsync: deleteSchoolLocation,
    isPending: isDeleting,
    error: deleteError,
  } = useDeleteSchoolLocationMutation({
    onSuccess: async () => {
      queryClient.removeQueries({
        queryKey: schoolLocationKey.single(schoolId),
      });
      await invalidateLocationQueries();
      navigate(staffRouterPath.LOCATIONS);
    },
    retry: 0,
  });

  const isMutating = isCreatingSchoolLocation || isUpdatingSchoolLocation;

  const {
    data: school,
    isLoading: isSchoolLoading,
    isError: isSchoolError,
  } = useSchoolLocation(schoolId);

  const isCreateLocationView = useMatch(staffRouterPath.CREATE_SCHOOL_LOCATION);

  const handleCancel = () => {
    if (isCreateLocationView) {
      navigate(staffRouterPath.LOCATIONS);
    } else {
      const redirectUrl = generatePath(
        staffRouterPath.OVERVIEW_SCHOOL_LOCATION,
        {
          schoolId: schoolId,
        }
      );
      navigate(redirectUrl);
    }
  };

  useEffect(() => {
    if (isSchoolError) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [isSchoolError, navigate]);

  const handleSubmitSchoolLocation = async (data: SchoolLocationFormData) => {
    const schoolType = getTypeFromProviderType(data.providerType, type);

    if (isCreateLocationView) {
      const convertedData = {
        address: data.address,
        website: data.website,
        seedCode: data.seedCode,
        number: data.contactNumber,
        keyContact: data.keyContact,
        name: data.name,
        feederSchoolIds: data.feederSchools
          ? data.feederSchools.map(schoolId => schoolId)
          : [],
        status: 'ACTIVE',
        type: schoolType,
      } as CreateSchoolLocationDto;

      if (type !== 'SECONDARY') {
        delete convertedData.feederSchoolIds;
      }

      await createSchoolLocation(convertedData);
      return;
    }

    const convertedData = {
      address: data.address,
      website: data.website,
      seedCode: data.seedCode,
      number: data.contactNumber,
      keyContact: data.keyContact,
      name: data.name,
      feederSchoolIds: data.feederSchools
        ? data.feederSchools.map(schoolId => schoolId)
        : [],
    } as UpdateSchoolLocationDto;

    if (!school?.type.includes('SECONDARY')) {
      delete convertedData.feederSchoolIds;
    }

    await updateSchoolLocation({
      id: schoolId,
      data: convertedData,
    });
  };

  const handleDeleteLocation = async () => {
    await deleteSchoolLocation(schoolId);
  };

  return (
    <div>
      <PageTitle
        title={isCreateLocationView ? 'New Location' : 'Edit Location'}
        variant="gray"
      >
        <div className="flex gap-3">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleCancel}
            disabled={isMutating}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            form="upsert-school-location-form"
            disabled={isCreatingSchoolLocation || isUpdatingSchoolLocation}
          >
            {isCreateLocationView ? 'Create' : 'Update'}
          </button>
        </div>
      </PageTitle>
      {isSchoolLoading && !isCreateLocationView ? (
        <div className="mt-6 flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <div className="mt-6">
          <SchoolLocationForm
            onSubmit={handleSubmitSchoolLocation}
            initialData={school}
          />
          {!isCreateLocationView &&
          hasPermission([Permission['location:delete']]) ? (
            <div className="two-col-form">
              <div>
                <p className="text-md font-medium leading-7 text-gray-900">
                  Destructive Actions
                </p>
                <p className="text-md leading-6 text-gray-600">
                  Take care with these actions as they are destructive and may
                  not be reversable.
                </p>
              </div>
              <div className="col-span-2 space-y-6">
                <DeleteZone
                  title="Delete Location"
                  actionButtonText="Delete Location"
                  description="Deleting a location will remove all the location details. This action cannot be completed if there are applications associated with it."
                  modalDescription="In order to delete this location, please type DELETE in the input below and confirm deletion."
                  onDelete={handleDeleteLocation}
                  isLoading={isDeleting}
                  error={getErrorMessage(deleteError)}
                />
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export { UpsertSchoolLocation };
