import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationFormDto } from '@/types/application';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  AllApplications,
  Application,
  ApplicationTemplate,
  LocationType,
  UpdateApplicationDto,
  UpdateApplicationDtos,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { has } from 'lodash-es';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  nurseryAdditionalChildDetailsSchema,
  schoolAdditionalChildDetailsSchema,
} from './additional-child-details.schema';
import { Child } from '@admissions-support/types';
import * as yup from 'yup';
import {
  transformFormAdditionalSupportNeedsToApiAdditionalSupportNeeds,
  transformFormLookedAfterStatusToApiLookedAfterStatus,
  transformNurseryApiDataToFormData,
  transformSchoolApiDataToFormData,
} from './additional-child-details-form-transformers';
import {
  NurseryAdditionalChildDetailsFormData,
  SchoolAdditionalChildDetailsFormData,
} from './additional-child-details.type';

type UseAdditionalChildDetailsFormProps = {
  initialData: AllApplications;
  child: Child;
  template: ApplicationTemplate;
  onSubmit: (data: UpdateApplicationDto) => Promise<AllApplications>;
};

// Maps the location type to the schema used to validate form data
// Currently Primary & Secondary Schools are using the same schema
const locationTypeToSchema: Record<LocationType, yup.ObjectSchema<any>> = {
  ELC: nurseryAdditionalChildDetailsSchema,
  PRIMARY: schoolAdditionalChildDetailsSchema,
  SECONDARY: schoolAdditionalChildDetailsSchema,
};

// Maps the location type to the transformer function
// Currently Primary & Secondary Schools are using the same transformer
const locationTypeToFormDataTransformer: Record<
  LocationType,
  (
    initialData: AllApplications,
    child: Child
  ) =>
    | NurseryAdditionalChildDetailsFormData
    | SchoolAdditionalChildDetailsFormData
> = {
  ELC: application =>
    transformNurseryApiDataToFormData(application as Application),
  PRIMARY: transformSchoolApiDataToFormData,
  SECONDARY: transformSchoolApiDataToFormData,
};

function useAdditionalChildDetailsForm<T extends LocationType>(
  props: UseAdditionalChildDetailsFormProps & {
    template: { applicationType: T };
  }
) {
  const { initialData, onSubmit, child, template } = props;
  const isDraft = useRef(true);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const defaultValues:
    | NurseryAdditionalChildDetailsFormData
    | SchoolAdditionalChildDetailsFormData = locationTypeToFormDataTransformer[
    template.applicationType
  ](initialData, child);

  // Fallback to Nursery schema if no schema is found for the location type
  // This should never happen, but needed to avoid TypeScript errors
  const schema =
    locationTypeToSchema[template.applicationType] ?? yup.object().shape({});

  const defaultResolver = yupResolver(schema);
  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    navigate(
      generatePath(staffRouterPath.APPLICATION_CARER, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }

    reset(undefined, { keepValues: true });

    if (!isDraft.current) {
      setShouldRedirect(true);
    }
  }, [form.formState.isSubmitSuccessful, params.id, navigate, reset]);

  const handleSubmit = async (data: ApplicationFormDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'moveInDate',
        'address',
        'homeStatus',
        'medicalDetails',
        'proofOfAddress',
      ]);

      const applicationDto: UpdateApplicationDtos = {
        ...transformedApplication,

        //need to convert it because of the wrong typing of the BE. it waits for Date object.
        moveInDate:
          has(transformedApplication, 'moveInDate') &&
          transformedApplication.moveInDate
            ? new Date(transformedApplication.moveInDate)
            : null,
        localAuthorityDetails: data.localAuthorityDetails
          ? data.localAuthorityDetails
          : undefined,

        ...(data.placeOfBaptism
          ? {
              placeOfBaptism: data.placeOfBaptism,
            }
          : {}),

        ...(data.proofOfBaptism
          ? {
              proofOfBaptism: data.proofOfBaptism,
            }
          : {}),

        ...(data.lookedAfter
          ? {
              lookedAfter: transformFormLookedAfterStatusToApiLookedAfterStatus(
                data.lookedAfter
              ),
            }
          : {}),

        ...((data as SchoolAdditionalChildDetailsFormData)
          .additionalSupportNeeds
          ? {
              additionalSupportNeeds:
                transformFormAdditionalSupportNeedsToApiAdditionalSupportNeeds(
                  (data as SchoolAdditionalChildDetailsFormData)
                    .additionalSupportNeeds
                ),
            }
          : {}),
      };

      await onSubmit(applicationDto);
    } catch (error) {
      console.log('error', error);
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const setDraft = (status: boolean) => {
    isDraft.current = status;
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(true);
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(false);
    await form.handleSubmit(handleSubmit)();
  };

  return {
    form,
    handleSubmit: handleSave,
    defaultValues,
    saveAsDraft,
  };
}

export { useAdditionalChildDetailsForm };
