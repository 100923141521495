import { schoolLocationKey } from '@/config/query-keys';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { HttpError, isBadRequest } from '@/types/error';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  LocationStatus,
  SchoolLocation,
  SchoolLocationStatus,
  UpdateSchoolLocationDto,
} from '@admissions-support/types';
import { kebabCase, startCase } from 'lodash-es';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { CheckboxItem } from '../../application/checkbox-item';
import {
  RadioBoxBase,
  RadioBoxOption,
  RadioBoxOptionProps,
} from './../../form/common/radio-box';
import { Textarea } from './../../form/common/textarea';
import { useUpdateSchoolLocationMutation } from '@/hooks/update-hooks/use-update-school-location-mutation';

type SchoolLocationOverviewFormProps = {
  school: SchoolLocation;
};

function SchoolLocationOverviewForm(props: SchoolLocationOverviewFormProps) {
  const { school } = props;

  const form = useForm<UpdateSchoolLocationDto>({
    defaultValues: {
      notes: school.notes || '',
      status: school.status as SchoolLocationStatus,
    },
  });

  useLeaveModal({
    show: form.formState.isDirty,
  });

  const { hasPermission } = usePermissions();

  const params = useParams<{ schoolId: string }>();

  const { mutateAsync: update, isPending } = useUpdateSchoolLocationMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: schoolLocationKey.allSchoolLocations,
        refetchType: 'active',
      });
    },
  });

  const schoolId = params.schoolId || '';

  const handleUpdateDeferring = async () => {
    const newValue = !school.isDeferToWaitingList;
    await update({
      id: params.schoolId || '',
      data: {
        isDeferToWaitingList: newValue,
      },
    });
  };

  const submitHandler = async (data: UpdateSchoolLocationDto) => {
    const convertedData = data;

    if (!convertedData.notes) {
      convertedData.notes = undefined;
    }

    try {
      await update({ id: params.schoolId || '', data: convertedData });
      form.reset(form.getValues(), { keepValues: true });
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  const handleStatusChange = async (status: string) => {
    const selectedStatus = status as LocationStatus;

    await update({
      id: schoolId,
      data: {
        status: selectedStatus,
      },
    });
  };

  const statusOptions: RadioBoxOption[] = [
    {
      value: 'ACTIVE',
      Content: props => (
        <StatusOption
          {...props}
          name="Active"
          description="Location can accept applications"
        />
      ),
    },
    {
      value: 'INACTIVE',
      Content: props => (
        <StatusOption
          {...props}
          name="Inactive"
          description="Location cannot accept applications"
        />
      ),
    },
    {
      value: 'ARCHIVED',
      Content: props => (
        <StatusOption
          {...props}
          name="Archived"
          description="Location no longer in use"
        />
      ),
    },
  ];

  const details = [
    { name: 'Location Type', value: startCase(school.type.toLowerCase()) },
    { name: 'Seed Code', value: school.seedCode },
    { name: 'Contact Number', value: school.number },
    { name: 'Website', value: school.website },
    {
      name: 'Key Contact',
      value: `${school.keyContact.firstName} ${school.keyContact.lastName}`,
    },
    { name: 'Key Contact Email', value: school.keyContact.email },
  ];

  const isFormDisabled =
    !hasPermission([Permission['location:update']]) || isPending;

  return (
    <div className="two-col-form">
      <div>
        <dl className="space-y-6">
          {details.map(detail => (
            <div key={kebabCase(detail.name)}>
              <dt className="font-medium leading-6 text-gray-700">
                {detail.name}
              </dt>
              <dd className="break-words leading-6">
                {detail.value ? detail.value : '-'}
              </dd>
            </div>
          ))}
        </dl>
      </div>
      <div className="col-span-2">
        <div>
          <FormProvider {...form}>
            <form
              onSubmit={form.handleSubmit(submitHandler)}
              className="mb-6 flex h-full flex-1 flex-col"
              id="update-location-form"
            >
              <Textarea
                label="Notes"
                name="notes"
                helperText="Add any notes relating to this location"
                placeholder="Your note goes here.."
                rows={4}
                disabled={form.formState.isSubmitting}
              />
              <button
                className="btn btn-primary self-end"
                disabled={
                  form.formState.isSubmitting || !form.formState.isDirty
                }
              >
                Save
              </button>
            </form>
          </FormProvider>
          <RadioBoxBase
            value={school.status}
            onChange={handleStatusChange}
            containerClassName="space-y-2"
            label="Location Status"
            options={statusOptions}
            disabled={isFormDisabled}
          />
          <p className="label mb-1.5 mt-6">Overrides</p>
          <CheckboxItem
            Content={() => (
              <div>
                <p className="mb-0.5 text-sm font-semibold">
                  Defer to Waiting List
                </p>
                <p className="text-sm">
                  If a future application is matched with this location, set as
                  a waiting list.
                </p>
              </div>
            )}
            checked={school.isDeferToWaitingList || false}
            onChange={handleUpdateDeferring}
            disabled={isPending}
          />
        </div>
      </div>
    </div>
  );
}

function StatusOption(
  props: RadioBoxOptionProps & {
    name: string;
    description: string;
  }
) {
  const { checked, name, description } = props;

  return (
    <div>
      <span
        className={twMerge(
          checked ? 'text-primary-800' : 'text-gray-700',
          'block text-sm font-medium'
        )}
      >
        {name}
      </span>
      <span className="block text-sm text-primary-700">{description}</span>
    </div>
  );
}

export { SchoolLocationOverviewForm, StatusOption };
