import { Alert } from '@/components/alert';
import { Spinner } from '@/components/spinner';
import { useChoicesContext } from '@/context/choices.context';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { ChoiceFormActionTypes } from '@/reducers/choice.reducer';
import { LocationListing, SessionType } from '@admissions-support/types';
import { useEffect } from 'react';
import { SplitPlacementSessions } from './split-placement-sessions';
import { CustomSelect } from '@/components/custom-select';
import { usePublicOrganisation } from '@/hooks/query-hooks/use-public-organisation';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { formatLocationSelection } from '@/components/form/choice.form';

type SplitPlacementBoxProps = {
  disabled?: boolean;
  locations: LocationListing[];
  selectedSessionType: SessionType;
};

function SplitPlacementBox(props: SplitPlacementBoxProps) {
  const { disabled, locations, selectedSessionType } = props;
  const { state, dispatch } = useChoicesContext();
  const {
    data: sessionsData,
    isSuccess: sessionQuerySuccess,
    isLoading: isSessionLoading,
  } = useSessions(state.splitPlacementEstablishmentId, state.schoolYearId, {
    enabled: !!state.splitPlacementEstablishmentId,
  });

  const { data: selectedLocation } = useLocation(
    state.splitPlacementEstablishmentId,
    {
      enabled: !!state.splitPlacementEstablishmentId,
    }
  );

  const { data: organisation } = usePublicOrganisation();

  /**
   * User cannot select the same location for the split placement as for the main location
   */
  const locationsWithoutMainLocation = locations
    .filter(location => {
      if (!organisation?.isCouncilSplitPermitted) {
        return (
          location.id !== state.establishment &&
          location.providerType !== 'COUNCIL'
        );
      }
      return location.id !== state.establishment;
    })
    .map(location => {
      return formatLocationSelection(location);
    });

  useEffect(() => {
    if (sessionQuerySuccess) {
      dispatch({
        type: ChoiceFormActionTypes.SET_SESSIONS_AT_SPLIT_PLACEMENT,
        payload: sessionsData,
      });
    }
  }, [dispatch, sessionsData, sessionQuerySuccess]);

  if (locationsWithoutMainLocation.length === 0) {
    return (
      <Alert
        type="warning"
        text="There is no Location available for Split Placement!"
      />
    );
  }

  /**
   * This could happen if the selected split location has no session
   * with the selected session type
   */
  const noSessionIsAvailable =
    state.sessionsAtSplitPlacement.length === 0 &&
    state.splitPlacementEstablishmentId &&
    !isSessionLoading;

  /**
   * Display split placement box when user selected a location for split placement
   * and if that location has at least one session with the proper session type
   */
  const shouldSplitPlacementSessionBoxBeVisible =
    state.splitPlacementEstablishmentId &&
    state.sessionsAtSplitPlacement.length > 0 &&
    !isSessionLoading;

  const isPrivatePlacementWarningVisible =
    selectedLocation?.providerType !== 'COUNCIL' && selectedLocation;

  return (
    <div className="dark-gray-container">
      <p className="text-md mb-4 font-medium text-gray-900 sm:flex">
        Split Placement Session
        {selectedSessionType.splitPlacement.hasMinimumDaysAtLocation && (
          <span className="ml-auto block font-normal text-gray-600 sm:inline-block">
            Minimum of{' '}
            {selectedSessionType.splitPlacement.minimumDaysAtLocation} Sessions
          </span>
        )}
      </p>
      <p className="text-md mb-4">
        Select which session you would like to attend at this location.
      </p>
      <CustomSelect
        name="splitPlacementEstablishmentId"
        label="Location"
        value={state.splitPlacementEstablishmentId}
        onChange={value =>
          dispatch({
            type: ChoiceFormActionTypes.SET_SPLIT_PLACEMENT_ESTABLISHMENT,
            payload: value,
          })
        }
        options={[...locationsWithoutMainLocation]}
        isDisabled={disabled}
        menuListClasses="!max-h-56"
      />

      {/**
       * Loading while fetching sessions for the split placement location
       */}
      {isSessionLoading && (
        <div className="mt-4 flex justify-center space-y-8">
          <Spinner />
        </div>
      )}

      {shouldSplitPlacementSessionBoxBeVisible && (
        <SplitPlacementSessions selectedSessionType={selectedSessionType} />
      )}

      {noSessionIsAvailable && (
        <Alert
          type="warning"
          text="No sessions of the selected type are available at this location."
          className="mt-4"
        />
      )}

      {isPrivatePlacementWarningVisible ? (
        <Alert
          title="Attention"
          className="mt-4"
          text={
            <div>
              <div>
                You must arrange this placement directly with{' '}
                {selectedLocation?.name}.
              </div>
              <div className="mt-2">
                1140 hours - Maximum 30 hours per week pro-rata from funding
                start date can be used across all your placements.
              </div>
            </div>
          }
          type="warning"
        />
      ) : null}
    </div>
  );
}

export { SplitPlacementBox };
