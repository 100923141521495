import * as yup from 'yup';

// Schema describing the address of the child
const addressSchema = yup.object({
  lineOne: yup.string().label('Address Line 1').required(),
  lineTwo: yup.string().label('Address Line 2').nullable(),
  lineThree: yup.string().label('Town/City').nullable(),
  lineFour: yup.string().label('').nullable(),
  postcode: yup.string().label('Postcode').required(),
  uprn: yup.string().label('UPRN').nullable(),
  eastings: yup.string().label('Eastings').nullable(),
  northings: yup.string().label('Northings').nullable(),
});

// Schema describing the home status of the child
const homeStatusSchema = yup
  .string()
  .label('Current Situation')
  .oneOf(['LIVING', 'MOVING'])
  .required()
  .nullable();

// Schema describing the move in date of the child
const moveInDateSchema = yup.string().label('Date of Move').nullable();

// Schema describing the medical details of the child
const medicalDetailsSchema = yup.object({
  doctorName: yup.string().label('Doctor Name').nullable(),
  healthVisitor: yup.string().label('Health Visitor Name').nullable(),
  surgeryName: yup.string().label('GP Practice Name').nullable(),
  phoneNumber: yup.string().label('Phone Number').nullable(),
  isVisuallyImpaired: yup.boolean().nullable(),
  isHearingImpaired: yup.boolean().nullable(),
  isEpileptic: yup.boolean().nullable(),
  isDiabetic: yup.boolean().nullable(),
  isAllergic: yup.boolean().nullable(),
  isAsmatic: yup.boolean().nullable(),
  isSpeechImpaired: yup.boolean().nullable(),
  allergies: yup.string().label('Allergies').nullable(),
  other: yup.string().label('Other').nullable(),
});

// Schema describing the additional support needs of the child (For Primary & Secondary Schools only)
const additionalSupportNeedsSchema = yup.object({
  hasAdditionalSupportNeeds: yup.boolean(),
  hasIndividualEducationalProgramme: yup.string().oneOf(['true', 'false']),
  hasCoordinatedSupportPlan: yup.string().oneOf(['true', 'false']),
  coordinatedSupportPlanAuthorityName: yup
    .string()
    .label('CSP Local Authority Name')
    .nullable(),
  agencies: yup.object().default({}),
  hasAnyOtherNeed: yup.boolean(),
  extraNeeds: yup.object().default({}),
  specialistContactDetails: yup
    .string()
    .label('Specialist Contact Details')
    .nullable(),
});

// Schema describing the looked after status of the child
const lookedAfterSchema = yup.object({
  isSubjectToCourtDecision: yup.string().label('Court decision').nullable(),
  careLocation: yup.string().label('Care Location').nullable(),
  relationship: yup
    .string()
    .oneOf(['PARENT', 'CARER', 'FOSTER_CARER', 'KINSHIP_CARER', 'OTHER'])
    .label('Relationship')
    .nullable(),
  otherRelationship: yup.string().label('Other Relationship').nullable(),
  authority: yup.string().label('Authority').nullable(),
});

// Schema describing the proof of address of the child
const proofOfAddressSchema = yup.array(
  yup.object({
    filename: yup.string().required(),
    reference: yup.string().required(),
    key: yup.string().required(),
  })
);

// Schema describing the place of baptism of the child (For Primary & Secondary Schools only)
const placeOfBaptismSchema = yup
  .string()
  .label('Place of Baptism')
  .nullable()
  .optional();

// Schema describing the proof of baptism of the child (For Primary & Secondary Schools only)
const proofOfBaptismSchema = yup.array(
  yup.object({
    filename: yup.string().required(),
    reference: yup.string().required(),
    key: yup.string().required(),
  })
);

// Schema describing the local authority details of the child (For Nurseries only)
const localAuthorityDetailsSchema = yup
  .object({
    nursery: yup.string().label('Nursery').nullable(),
    isSplit: yup.boolean().nullable(),
    splitDetails: yup.string().label('Details').nullable(),
    isAlreadyAttending: yup.boolean().nullable(),
    attendanceDetails: yup.string().label('Details').nullable(),
    isExtendedChildcare: yup.boolean().nullable(),
  })
  .nullable()
  .optional();

// Additional Child Details Schema for Nurseries
const nurseryAdditionalChildDetailsSchema = yup.object({
  address: addressSchema,
  proofOfAddress: proofOfAddressSchema,
  homeStatus: homeStatusSchema,
  moveInDate: moveInDateSchema,
  medicalDetails: medicalDetailsSchema,
  lookedAfter: lookedAfterSchema,
  localAuthorityDetails: localAuthorityDetailsSchema,
});

// Additional Child Details Schema for Schools (both Primary & Secondary)
const schoolAdditionalChildDetailsSchema = yup.object({
  address: addressSchema,
  proofOfAddress: proofOfAddressSchema,
  homeStatus: homeStatusSchema,
  moveInDate: moveInDateSchema,
  medicalDetails: medicalDetailsSchema,
  additionalSupportNeeds: additionalSupportNeedsSchema,
  placeOfBaptism: placeOfBaptismSchema,
  lookedAfter: lookedAfterSchema,
  proofOfBaptism: proofOfBaptismSchema,
});

export {
  nurseryAdditionalChildDetailsSchema,
  addressSchema,
  schoolAdditionalChildDetailsSchema,
};
