import { applicationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { applicationService } from '@/services/application.service';
import { ApplicationFilter } from '@/types/application-filter';
import { HttpError } from '@/types/error';
import { LocationType } from '@admissions-support/types';
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from '@tanstack/react-query';

function useCountApplications(
  filter: ApplicationFilter,
  applicationsType?: LocationType,
  queryOptions?: Partial<UseQueryOptions<string[], HttpError>>
) {
  const { schoolYear } = useSchoolYear();

  return useQuery({
    queryKey: applicationKey.count(schoolYear.id, filter),
    queryFn: () =>
      applicationService.countApplications({
        filter,
        schoolYear: schoolYear.id,
        applicationsType,
      }),
    ...queryOptions,
    placeholderData: keepPreviousData,
  });
}

export { useCountApplications };
