import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { Permission } from '@/types/auth';
import { TabNavigation } from '../tab-navigation';
import {
  getLocationNavigation,
  getSchoolLocationNavigation,
} from '@/utils/location-utils';

type LocationTabNavigationProps = {
  id: string;
  isSchoolLocation?: boolean;
};

function LocationTabNavigation(props: LocationTabNavigationProps) {
  const { isSchoolLocation, id } = props;

  const { hasPermission } = usePermissions();

  const currentNavigation = isSchoolLocation
    ? getSchoolLocationNavigation(id)
    : getLocationNavigation(id, hasPermission([Permission['extra-hours']]));

  return (
    <div className="mt-5">
      <TabNavigation
        tabContainerClassName="border-b border-gray-200 bg-gray-25 -mx-4 px-4 pb-6 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
        options={currentNavigation}
      />
    </div>
  );
}

export { LocationTabNavigation };
