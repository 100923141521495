import { Box } from '@/components/box';
import { staffRouterPath } from '@/config/route-paths.config';
import { Application, UpdateApplicationDto } from '@admissions-support/types';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Choices } from '../choices';
import { PreSchool } from './placement-preferences.element';
import { usePlacementPreferencesForm } from './use-placement-preferences.form';

type ParentPlacementPreferencesFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: Application;
  locations: { key: string; value: string }[];
};

function ParentPlacementPreferencesForm(
  props: ParentPlacementPreferencesFormProps
) {
  const { initialData, onSubmit, locations } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { form, handleSubmit, saveAsDraft } = usePlacementPreferencesForm({
    initialData,
    onSubmit,
  });
  const isFormDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="space-y-4"
        id="upsert-application-form"
      >
        <Box className="space-y-4">
          <PreSchool disabled={isFormDisabled} locations={locations} />
        </Box>

        <Box className="space-y-4">
          <Choices disabled={isFormDisabled} />
        </Box>
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Previous
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
          disabled={initialData.choices.length < 1}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
        </button>
      </div>
    </FormProvider>
  );
}

export { ParentPlacementPreferencesForm };
