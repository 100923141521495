import { DrawerContainer } from '@/components/application/drawer/drawer-container';
import { CheckboxBase } from '@/components/form/common/checkbox';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { ToggledColumns } from '@/types/reporting-output-control';
import {
  applicationSessionFields,
  exportableKeysApplications,
  exportableKeysApplicationsLookup,
  exportableKeysBookings,
  exportableKeysBookingsLookup,
  exportableKeysLocationsLookup,
  exportableKeysSchoolApplications,
  exportableKeysSchoolApplicationsLookup,
  exportableKeysSchoolLocationsLookup,
} from '@/utils/query-builder-utils';
import { startCase } from 'lodash-es';

type ToggleColumnsDrawerProps = {
  isOpen: boolean;
  closeModal: () => void;
  dataSource: string;
};

const applicationsToggleSections = {
  general: ['submittedAt', 'createdAt', 'updatedAt'],
  applicationData: [
    'isChildWithAdditionalNeeds',
    'additionalDetails',
    'notes',
    'homeLanguage',
    'homeStatus',
    'moveInDate',
    'isArchived',
    'status',
    'organisation.name',
    'schoolYear.name',
    'cohort.name',
    'currentLocation.name',
    'currentLocationDescription',
  ],
  child: [
    ...exportableKeysApplications.filter(key => key.startsWith('child')),
    'dateOfBirth',
  ],
  choicesGeneral: exportableKeysApplications.filter(
    key => key.startsWith('choices') && !applicationSessionFields.includes(key)
  ),
  choicesSession: applicationSessionFields,
  lookedAfter: [
    ...exportableKeysApplications.filter(key => key.startsWith('lookedAfter')),
  ],
  medicalDetails: [
    ...exportableKeysApplications.filter(key =>
      key.startsWith('medicalDetails')
    ),
  ],
  siblings: [
    ...exportableKeysApplications.filter(key => key.startsWith('siblings')),
  ],
  carers: [
    ...exportableKeysApplications.filter(key => key.startsWith('carers')),
  ],
  address: [
    ...exportableKeysApplications.filter(key => key.startsWith('address')),
  ],
  flagsAndOverrides: [
    ...exportableKeysApplications.filter(key =>
      key.startsWith('flagsAndOverrides')
    ),
  ],
};

const schoolApplicationsToggleSections = {
  general: ['submittedAt', 'createdAt', 'updatedAt'],
  applicationData: [
    'isChildWithAdditionalNeeds',
    'notes',
    'homeLanguage',
    'homeStatus',
    'moveInDate',
    'isArchived',
    'status',
    'organisation.name',
    'schoolYear.name',
    'yearStage',
  ],
  child: [
    ...exportableKeysSchoolApplications.filter(key => key.startsWith('child')),
    'dateOfBirth',
  ],
  lookedAfter: [
    ...exportableKeysSchoolApplications.filter(key =>
      key.startsWith('lookedAfter')
    ),
  ],
  medicalDetails: [
    ...exportableKeysSchoolApplications.filter(key =>
      key.startsWith('medicalDetails')
    ),
  ],
  siblings: [
    ...exportableKeysSchoolApplications.filter(key =>
      key.startsWith('siblings')
    ),
  ],
  carers: [
    ...exportableKeysSchoolApplications.filter(key => key.startsWith('carers')),
  ],
  address: [
    ...exportableKeysSchoolApplications.filter(key =>
      key.startsWith('address')
    ),
  ],
  flagsAndOverrides: [
    ...exportableKeysSchoolApplications.filter(key =>
      key.startsWith('flagsAndOverrides')
    ),
  ],
};

const bookingsToggleSections = {
  bookings: exportableKeysBookings,
};

const locationsToggleSections = {
  general: ['createdAt', 'updatedAt'],
  locationsData: [
    'name',
    'type',
    'providerType',
    'serviceProviderNumber',
    'careInspectorNumber',
    'contactNumber',
    'website',
    'notes',
    'status',
    'areas.name',
  ],
  address: [
    'address.streetAddress',
    'address.streetAddressTwo',
    'address.city',
    'address.postcode',
  ],
  keyContact: [
    'keyContact.firstName',
    'keyContact.lastName',
    'keyContact.email',
  ],
  sessions: [
    'sessions.model.name',
    'sessions.type.name',
    'sessions.name',
    'sessions.times.monday.start',
    'sessions.times.monday.end',
    'sessions.times.tuesday.start',
    'sessions.times.tuesday.end',
    'sessions.times.wednesday.start',
    'sessions.times.wednesday.end',
    'sessions.times.thursday.start',
    'sessions.times.thursday.end',
    'sessions.times.friday.start',
    'sessions.times.friday.end',
    'sessions.times.saturday.start',
    'sessions.times.saturday.end',
    'sessions.times.sunday.start',
    'sessions.times.sunday.end',
  ],
};

const schoolLocationsToggleSections = {
  general: ['createdAt', 'updatedAt'],
  locationsData: [
    'name',
    'type',
    'providerType',
    'contactNumber',
    'website',
    'notes',
    'status',
    'feederSchools.name',
  ],
  address: [
    'address.streetAddress',
    'address.streetAddressTwo',
    'address.city',
    'address.postcode',
  ],
  keyContact: [
    'keyContact.firstName',
    'keyContact.lastName',
    'keyContact.email',
  ],
};

function ToggleColumnsDrawer(props: ToggleColumnsDrawerProps) {
  const { isOpen, closeModal, dataSource } = props;

  const { toggledColumns, setToggledColumns } = useReportingOutputControl();

  const getSectionsData = () => {
    let sections;
    let keyLookup;
    switch (dataSource) {
      case 'APPLICATIONS':
        keyLookup = exportableKeysApplicationsLookup;
        sections = applicationsToggleSections;
        break;
      case 'PRIMARY SCHOOL APPLICATIONS':
      case 'SECONDARY SCHOOL APPLICATIONS':
        keyLookup = exportableKeysSchoolApplicationsLookup;
        sections = schoolApplicationsToggleSections;
        break;
      case 'BOOKINGS':
        keyLookup = exportableKeysBookingsLookup;
        sections = bookingsToggleSections;
        break;
      case 'LOCATIONS':
        keyLookup = exportableKeysLocationsLookup;
        sections = locationsToggleSections;
        break;
      case 'SCHOOL LOCATIONS':
        keyLookup = exportableKeysSchoolLocationsLookup;
        sections = schoolLocationsToggleSections;
        break;
      default:
        keyLookup = exportableKeysApplicationsLookup;
        sections = applicationsToggleSections;
        break;
    }
    return { sections, keyLookup };
  };

  const handleChangeAll = (valuesToChange: string[], newState: boolean) => {
    const newValues = valuesToChange.reduce((acc, curr) => {
      const currentObj = {
        [curr as keyof ToggledColumns]: newState,
      };
      return { ...acc, ...currentObj };
    }, {});

    setToggledColumns(prev => ({ ...prev, ...newValues }));
  };

  return (
    <DrawerContainer open={isOpen} onClose={closeModal} title="Toggle Columns">
      <p className="text-md text-gray-600">
        Select the columns you would like to include on your table and CSV
        export.
      </p>
      <div className="mt-6 space-y-10">
        {Object.entries(getSectionsData().sections).map(([key, value]) => {
          const isAllCheckedFromCurrentSection = value.every(
            value => toggledColumns[value as keyof ToggledColumns] === true
          );

          return (
            <div key={key} className="space-y-10">
              <div className="flex justify-between">
                <p className="text-md font-semibold text-gray-700">
                  {startCase(key)}
                </p>
                <CheckboxBase
                  name={`all-${key}`}
                  label="Select All"
                  checked={isAllCheckedFromCurrentSection}
                  onChange={e => handleChangeAll(value, e.target.checked)}
                />
              </div>
              <div className="flex flex-wrap gap-8">
                {value.map(field => {
                  return (
                    <CheckboxBase
                      key={field}
                      name={field}
                      label={getSectionsData().keyLookup[field]}
                      checked={toggledColumns[field as keyof ToggledColumns]}
                      onChange={e =>
                        setToggledColumns(prev => ({
                          ...prev,
                          [field]: e.target.checked,
                        }))
                      }
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </DrawerContainer>
  );
}

export { ToggleColumnsDrawer };
