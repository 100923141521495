import { schoolLocationKey } from '@/config/query-keys';
import { schoolLocationService } from '@/services/school-location.service';
import { HttpError } from '@/types/error';
import { SchoolLocation } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useSchoolLocation(
  id?: string,
  queryOptions?: Partial<UseQueryOptions<SchoolLocation, HttpError>>
) {
  return useQuery({
    queryKey: schoolLocationKey.single(id || ''),
    queryFn: () => schoolLocationService.getSchoolLocation(id || ''),
    enabled: !!id,
    ...queryOptions,
  });
}

export { useSchoolLocation };
