import {
  ConditionGroup as ConditionGroupType,
  QueryBuilderFormData,
} from '@/components/reporting/reporting.type';
import { useReportingOutputControl } from '@/context/reporting-output-control.context';
import { useApplicationsReportResult } from '@/hooks/query-hooks/use-applications-report-results';
import { useBookingsReportResult } from '@/hooks/query-hooks/use-bookings-report-result';
import { useLocationsReportResult } from '@/hooks/query-hooks/use-locations-report-results';
import {
  isApplicationsFilter,
  isBookingsFilter,
  isLocationsFilter,
} from '@/pages/reporting/upsert-query-builder';
import { ReportingOutputFilter } from '@/types/reporting-output-control';
import { getAndConditions, getOrConditions } from '@/utils/query-builder-utils';
import {
  addAndCondition,
  addAndConditionToOrGroup,
  addOrCondition,
  findGroupForCondition,
  moveCondition,
} from '@/utils/reporting-utils';
import type {
  CollisionDetection,
  DragEndEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Loading01, Play, Plus, PlusCircle } from '@untitled-ui/icons-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Alert } from '../alert';
import { Condition } from './condition';
import { ConditionGroup } from './condition-group';
import { ConditionTag } from './condition-tag';
import { extraConditionDataSources } from './reporting.const';

function QueryBuilder() {
  const { control, setValue, getValues, watch, trigger } =
    useFormContext<QueryBuilderFormData>();

  const { pagination, filter, setFilter, setHasQueryRun } =
    useReportingOutputControl();

  const { fields: conditionGroups } = useFieldArray({
    name: 'conditionGroups',
    control,
    keyName: 'uid',
  });

  const formArrayValue = watch('conditionGroups');

  const dataSource = watch('dataSource');

  const {
    isFetching: isApplicationsFetching,
    isError: isApplicationReportError,
    error: applicationReportError,
  } = useApplicationsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isApplicationsFilter(filter, dataSource) ? filter : []),
    },
    { enabled: false }
  );

  const {
    isFetching: isBookingsFetching,
    isError: isBookingReportError,
    error: bookingReportError,
  } = useBookingsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isBookingsFilter(filter, dataSource) ? filter : []),
    },
    { enabled: false }
  );

  const {
    isFetching: isLocationsFetching,
    isError: isLocationReportError,
    error: locationReportError,
  } = useLocationsReportResult(
    {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(isLocationsFilter(filter, dataSource) ? filter : []),
    },
    { enabled: false }
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        // Require mouse to move 5px to start dragging, this allow onClick to be triggered on click
        tolerance: 5,
        // Require to press for 100ms to start dragging, this can reduce the chance of dragging accidentally due to page scroll
        delay: 100,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // activeId - used for displaying DragOverlay
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const itemsBeforeDrag = useRef<null | ConditionGroupType[]>(null);

  const handleDragStart = useCallback(
    ({ active }: DragStartEvent) => {
      itemsBeforeDrag.current = conditionGroups;
      setActiveId(active.id);
    },
    [conditionGroups]
  );

  const handleDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (!over) {
        setActiveId(null);
        return;
      }

      const activeContainerId = findGroupForCondition({
        conditionId: active.id.toString(),
        conditionGroups,
      });

      const overContainerId = findGroupForCondition({
        conditionId: over.id.toString(),
        conditionGroups,
      });

      if (!overContainerId || !activeContainerId) {
        setActiveId(null);
        return;
      }

      if (active.id !== over.id) {
        const newConditionGroups = moveCondition({
          conditionGroups,
          activeId: active.id.toString(),
          overId: over.id.toString(),
        });

        setValue('conditionGroups', newConditionGroups, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
      setActiveId(null);
    },
    //eslint-disable-next-line
    [conditionGroups]
  );

  /**
   * Custom collision detection strategy optimized for multiple containers
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      const conditionGroups = getValues().conditionGroups;
      if (activeId && conditionGroups.some(group => group.id === activeId)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container =>
            conditionGroups.some(group => group.id === container.id)
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (conditionGroups.some(group => group.id === overId)) {
          const containerItems =
            conditionGroups.find(group => group.id === overId)?.data || [];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                container => container.id !== overId
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, getValues]
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [conditionGroups]);

  const handleAddAndCondition = () => {
    const newConditionGroups = addAndCondition(getValues().conditionGroups);
    setValue('conditionGroups', newConditionGroups);
  };

  const handleAddOrCondition = () => {
    const newConditionGroups = addOrCondition(getValues().conditionGroups);
    setValue('conditionGroups', newConditionGroups);
  };

  const handleAddAndToOrCondition = (index: number) => () => {
    const newConditionGroups = addAndConditionToOrGroup({
      conditionGroups,
      index,
    });
    setValue('conditionGroups', newConditionGroups);
  };

  const findActiveDetails = (
    activeId?: string
  ): { groupIndex: number; conditionIndex: number; id: string } | undefined => {
    if (!activeId) {
      return;
    }

    const activeConditionGroup = findGroupForCondition({
      conditionId: activeId,
      conditionGroups,
    });

    if (!activeConditionGroup) {
      return;
    }

    const activeConditionGroupIndex = conditionGroups.findIndex(
      group => group.id === activeConditionGroup.id
    );

    const activeConditionIndex = activeConditionGroup.data.findIndex(
      condition => condition.id === activeId
    );

    return {
      id: activeId,
      groupIndex: activeConditionGroupIndex,
      conditionIndex: activeConditionIndex,
    };
  };

  const handleRunQuery = async () => {
    const isFormValid = await trigger('conditionGroups', { shouldFocus: true });

    if (!isFormValid) {
      return;
    }

    const formValues = getValues();

    const conditions: ReportingOutputFilter = {
      ...getAndConditions(formValues.conditionGroups),
      orConditions: getOrConditions(formValues.conditionGroups),
    };

    const extraConditions = extraConditionDataSources.find(
      extraCondition => extraCondition.dataSource === dataSource
    );

    // Add extra conditions to the query that derive from the data source
    if (extraConditions) {
      extraConditions.extraConditions.forEach(extraCondition => {
        const conditionKey = extraCondition.key;
        (conditions as Record<string, unknown>)[conditionKey] =
          extraCondition.value;
      });
    }

    setHasQueryRun(true);
    setFilter(conditions);
  };

  const activeDetails = findActiveDetails(activeId?.toString());

  const isQueryLoading =
    isApplicationsFetching || isBookingsFetching || isLocationsFetching;

  const hasReportError =
    isApplicationReportError || isBookingReportError || isLocationReportError;

  const errors =
    applicationReportError?.message ||
    bookingReportError?.message ||
    locationReportError?.message ||
    [];

  return (
    <div className="space-y-4">
      <DndContext
        sensors={sensors}
        collisionDetection={collisionDetectionStrategy}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        {conditionGroups.map((conditionGroup, groupIndex) => {
          if (conditionGroup.variant === 'and') {
            return (
              <div key={conditionGroup.uid} className="space-y-3">
                <ConditionGroup
                  index={groupIndex}
                  id={conditionGroup.id}
                  variant="and"
                />

                {groupIndex !== conditionGroups.length - 1 && (
                  <ConditionTag>And</ConditionTag>
                )}
              </div>
            );
          }

          return (
            <div key={conditionGroup.uid}>
              <div className="space-y-3 rounded-lg border border-gray-200 px-3 py-4">
                <p className="text-md font-semibold text-gray-700">Or Group</p>
                <ConditionGroup
                  index={groupIndex}
                  id={conditionGroup.id}
                  variant="or"
                />

                <button
                  onClick={handleAddAndToOrCondition(groupIndex)}
                  className="btn btn-empty btn-icon text-primary-700"
                >
                  <Plus className="leading-icon" aria-hidden="true" />
                  Add Condition
                </button>
              </div>
              {groupIndex !== conditionGroups.length - 1 && (
                <ConditionTag>And</ConditionTag>
              )}
            </div>
          );
        })}
        <DragOverlay>
          {activeDetails ? (
            <Condition
              id={activeDetails.id}
              groupIndex={activeDetails.groupIndex}
              conditionIndex={activeDetails.conditionIndex}
              isOverlay
            />
          ) : null}
        </DragOverlay>
      </DndContext>

      {hasReportError && (
        <Alert
          type="error"
          text={
            <>
              <b>Query Failed</b>
              {Array.isArray(errors) ? (
                errors.map((error, index) => (
                  <p key={`query-error-${index}`}>{error}</p>
                ))
              ) : (
                <p>{errors}</p>
              )}
            </>
          }
        />
      )}

      <div className="flex items-center justify-between">
        <div className="flex h-min gap-6">
          <button
            onClick={handleAddAndCondition}
            type="button"
            className="btn btn-secondary btn-sm btn-icon"
          >
            <PlusCircle className="leading-icon" aria-hidden="true" />
            Add Rule
          </button>
          <button
            onClick={handleAddOrCondition}
            type="button"
            className="btn btn-secondary btn-sm btn-icon"
          >
            <PlusCircle className="leading-icon" aria-hidden="true" />
            Or Group
          </button>
        </div>
        <button
          className="btn btn-primary btn-icon btn-sm"
          type="button"
          onClick={handleRunQuery}
          disabled={formArrayValue.length < 1}
        >
          {isQueryLoading ? (
            <Loading01
              className="leading-icon animate-spin"
              aria-hidden="true"
            />
          ) : (
            <Play className="leading-icon" aria-hidden="true" />
          )}
          {isQueryLoading ? 'Query Running' : 'Run Query'}
        </button>
      </div>
    </div>
  );
}

export { QueryBuilder };
