import { Alert } from '@/components/alert';
import { CustomSelect } from '@/components/custom-select';
import { Spinner } from '@/components/spinner';
import { organisationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateTermMutation } from '@/hooks/create-hooks/use-create-term-mutation';
import { useTerms } from '@/hooks/query-hooks/use-terms';
import { queryClient } from '@/libs/react-query';
import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import { XClose } from '@untitled-ui/icons-react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type NewSchoolYearModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

function NewSchoolYearModal(props: NewSchoolYearModalProps) {
  const { onClose, isOpen } = props;

  const { isLoading, data: schoolYears, isError, isSuccess } = useTerms();

  const {
    mutateAsync: createSchoolYearMutation,
    isPending: isCreatingMutation,
  } = useCreateTermMutation();

  const [existingSchoolYearId, setExistingSchoolYearId] = useState<string>('');
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();

  const renderExistingSchoolYearField = () => {
    if (isLoading) {
      return <Spinner className="m-auto" />;
    }

    if (isError || !isSuccess) {
      return (
        <Alert
          title="Error"
          type="error"
          text="Something went wrong, try again later!"
        />
      );
    }

    const schoolYearValues = schoolYears.map(schoolYear => ({
      key: schoolYear.id,
      value: schoolYear.name,
    }));

    return (
      <CustomSelect
        options={schoolYearValues}
        label="Create from existing"
        name="Existing school year"
        value=""
        onChange={newVal => {
          setExistingSchoolYearId(newVal);
        }}
      />
    );
  };

  const handleSubmit = async () => {
    setError('');

    const sourceSchoolYear = schoolYears?.find(
      schoolYear => schoolYear.id === existingSchoolYearId
    );

    if (!sourceSchoolYear) {
      setError('School Year is invalid!');
      return;
    }

    await createSchoolYearMutation({
      name: `${sourceSchoolYear.name} (Copy ${new Date().getFullYear()})`,
      source: { id: sourceSchoolYear.id },
    });

    await queryClient.invalidateQueries({ queryKey: organisationKey.terms });

    onClose();
  };

  const handleNavigateToBlankSchoolYear = () => {
    navigate(staffRouterPath.NEW_TERM);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75  backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="pb-5 text-lg font-medium leading-6 text-gray-900"
                  >
                    New School Year
                  </Dialog.Title>
                  <div className="light-gray-container mb-6 p-4">
                    <p className="mb-5 text-gray-600">
                      In order to maintain the link between applications and
                      their placement preferences school years should be
                      duplicated. If you don’t require any relationship between
                      offered sessions you can create an empty school year.
                    </p>
                    {renderExistingSchoolYearField()}
                    <p className="mt-2 text-red-500">{error}</p>
                  </div>
                  <button
                    type="button"
                    onClick={handleNavigateToBlankSchoolYear}
                    className="text-sm font-semibold text-gray-600"
                  >
                    Setup blank School Year
                  </button>
                  <div className="mt-8 flex w-full gap-3">
                    <button
                      className="btn btn-secondary w-full"
                      type="button"
                      disabled={isCreatingMutation}
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary w-full"
                      type="button"
                      disabled={isCreatingMutation}
                      onClick={handleSubmit}
                    >
                      Create School Year
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { NewSchoolYearModal };
