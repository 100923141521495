import { Checkbox, CheckboxBase } from '@/components/form/common/checkbox';
import { Dropzone } from '@/components/form/common/dropzone';
import { Radio } from '@/components/form/common/radio';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { Textarea } from '@/components/form/common/textarea';
import { paths } from '@/config/upload-paths';
import { useFormContext } from 'react-hook-form';
import { Address } from '../address.element';
import { useEffect, useState } from 'react';
import { ChildAdditionalSupportNeedTypes } from '@admissions-support/types';
import { ChildAdditionalSupportNeedsAgency } from '@admissions-support/types';

type ChildAddressProps = {
  disabled?: boolean;
};

function ChildAddress(props: ChildAddressProps) {
  const { disabled } = props;

  const form = useFormContext();

  const homeStatus = form.watch('homeStatus');

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Child&apos;s Address
        </p>
        <p className="text-md leading-6 text-gray-600">
          Please enter child&apos;s address and provide the supporting documents
          as evidenence.
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <Address disabled={disabled} />
        <Select
          label="Current Situation"
          options={[
            { key: '', value: 'Select..' },
            { key: 'LIVING', value: 'Living In' },
            { key: 'MOVING', value: 'Moving In' },
          ]}
          name="homeStatus"
          disabled={disabled}
        />
        {homeStatus === 'MOVING' && (
          <TextInput
            name="moveInDate"
            type="date"
            label="Date of Move"
            disabled={disabled}
          />
        )}
        <div>
          <p className="label mb-1.5">Provide Evidence</p>
          <Dropzone
            name="proofOfAddress"
            path={paths.applications}
            options={{
              disabled: disabled,
              accept: {
                'image/jpeg': [],
                'image/png': [],
                'application/pdf': [],
              },
            }}
            fileRestrictionLabel="Only jpg, pdf files are accepted."
            helperText={
              homeStatus === 'LIVING'
                ? 'Such as Council Tax or a utility bill'
                : 'Such as missives, mortgage acceptance'
            }
          />
        </div>
      </div>
    </>
  );
}

type ChildMedicalDetailsProps = {
  disabled?: boolean;
};

function ChildMedicalDetails(props: ChildMedicalDetailsProps) {
  const { disabled } = props;
  const form = useFormContext();
  const medicalDetails = form.watch('medicalDetails');
  const hasMedicalCondition = Object.values(medicalDetails || {}).some(
    value => value === true
  );

  const [hasOther, setHasOther] = useState(
    Boolean(form.formState.defaultValues?.medicalDetails?.other)
  );

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Child&apos;s Medical details
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <TextInput
            type="text"
            name="medicalDetails.doctorName"
            label="Name of Child's Doctor"
            placeholder="GP Name"
            disabled={disabled}
          />
          <TextInput
            type="text"
            name="medicalDetails.healthVisitor"
            label="Child's Health Visitor"
            placeholder="Health Visitor Name"
            disabled={disabled}
          />
          <TextInput
            type="text"
            name="medicalDetails.surgeryName"
            label="Name of Surgery or Health Centre"
            placeholder="GP Practice Name"
            disabled={disabled}
          />
          <TextInput
            type="text"
            name="medicalDetails.phoneNumber"
            label="Surgery or Health Centre Phone Number"
            placeholder="+44"
            disabled={disabled}
          />
        </div>
        <div>
          <p className="label mb-1">
            Does your child have difficulties with any of the following?
          </p>
          <div className="grid grid-cols-2 gap-2 sm:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7">
            <Checkbox
              label="Sight"
              name="medicalDetails.isVisuallyImpaired"
              disabled={disabled}
            />
            <Checkbox
              label="Hearing"
              name="medicalDetails.isHearingImpaired"
              disabled={disabled}
            />
            <Checkbox
              label="Speech"
              name="medicalDetails.isSpeechImpaired"
              disabled={disabled}
            />
            <Checkbox
              label="Epilepsy"
              name="medicalDetails.isEpileptic"
              disabled={disabled}
            />
            <Checkbox
              label="Diabeties"
              name="medicalDetails.isDiabetic"
              disabled={disabled}
            />
            <Checkbox
              label="Allergy"
              name="medicalDetails.isAllergic"
              disabled={disabled}
            />
            <Checkbox
              label="Asthma"
              name="medicalDetails.isAsmatic"
              disabled={disabled}
            />
            <CheckboxBase
              label="Other"
              name="other"
              onChange={() => {
                setHasOther(prev => !prev);
              }}
              checked={hasOther}
              disabled={disabled}
            />
          </div>
        </div>
        {medicalDetails?.isAllergic && (
          <TextInput
            type="text"
            name="medicalDetails.allergies"
            label="Description of Allergies"
            placeholder="List any allergies"
            disabled={disabled}
          />
        )}

        {hasMedicalCondition || hasOther ? (
          <TextInput
            type="text"
            name="medicalDetails.other"
            label="Provide details"
            placeholder="Provide additional details here"
            disabled={disabled}
          />
        ) : null}
      </div>
    </>
  );
}

type LookedAfterStatusProps = {
  disabled?: boolean;
};

function LookedAfterStatus(props: LookedAfterStatusProps) {
  const { disabled } = props;
  const form = useFormContext();

  const relationship = form.watch('lookedAfter.relationship');

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Looked After Status
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <Radio
          name="lookedAfter.isSubjectToCourtDecision"
          label="Is the child looked after as a result of a children's hearing or court decision?"
          options={[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]}
          disabled={disabled}
        />
        <Radio
          name="lookedAfter.careLocation"
          label="Is the child looked after at home or away from home?"
          options={[
            { label: 'Home', value: 'HOME' },
            { label: 'Away from home', value: 'AWAY' },
          ]}
          disabled={disabled}
        />
        <Select
          name="lookedAfter.relationship"
          label="What is your relationship with the child?"
          helperText="i.e. parent, foster carer, kinship carer, other?"
          options={[
            { key: '', value: 'Select...' },
            { key: 'PARENT', value: 'Parent' },
            { key: 'CARER', value: 'Carer' },
            { key: 'FOSTER_CARER', value: 'Foster Carer' },
            { key: 'KINSHIP_CARER', value: 'Kinship Carer' },
            { key: 'OTHER', value: 'Other' },
          ]}
          disabled={disabled}
        />
        {(relationship === 'KINSHIP_CARER' ||
          relationship === 'FOSTER_CARER') && (
          <TextInput
            type="text"
            name="lookedAfter.authority"
            label="If foster carer or kinship carer, please name the authority that has placed the child with you"
            placeholder="Authority"
            disabled={disabled}
          />
        )}

        {relationship === 'OTHER' && (
          <TextInput
            type="text"
            name="lookedAfter.otherRelationship"
            label="What is the relationship?"
            placeholder="Describe relationship"
            disabled={disabled}
          />
        )}
      </div>
    </>
  );
}

function LocalAuthorityDetails(props: LookedAfterStatusProps) {
  const { disabled } = props;

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Local Authority Details
        </p>
        <p className="text-md leading-6 text-gray-600">
          Please provide details relating to current or previous local
          authorities you have lived in.
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <TextInput
          type="text"
          name="localAuthorityDetails.nursery"
          label="Have you applied to any other nursery?"
          placeholder="Start Typing"
          disabled={disabled}
        />
        <Checkbox
          label="Do you wish to split your child's funding between more than one Early Learning & Childcare Provider?"
          name="localAuthorityDetails.isSplit"
          disabled={disabled}
        />

        <Textarea
          name="localAuthorityDetails.splitDetails"
          label="Details"
          placeholder="Enter session details"
          rows={5}
          helperText="Please indicate the name and number of hours/ days requested at the
            other Early Learning & Childcare Provider. Please ensure you have
            also submitted a fully completed application to the other Early
            Learning & Childcare Provider."
        />
        <Checkbox
          label="Does this child already attend an Early Learning & Childcare provision?"
          name="localAuthorityDetails.isAlreadyAttending"
          disabled={disabled}
        />
        <Textarea
          name="localAuthorityDetails.attendanceDetails"
          label="Details"
          placeholder="Current provider details"
          rows={5}
          helperText="Please give name and address of Early Learning & Childcare provision"
        />

        <Checkbox
          label="Do you require extended childcare"
          name="localAuthorityDetails.isExtendedChildcare"
          disabled={disabled}
          helperText={
            <>
              <span className="mb-2 inline-block">
                yes, please discuss availability and cost with the Early
                Learning & Childcare Provider when submitting this application.
              </span>
              <span className="block">
                (No guarantees are given to provide extended childcare)
              </span>
            </>
          }
        />
      </div>
    </>
  );
}

type ChildReligionDetailsProps = {
  disabled?: boolean;
};

function ChildReligionDetails(props: ChildReligionDetailsProps) {
  const { disabled } = props;

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">Religion</p>
      </div>
      <div className="col-span-2 rounded-xl border border-gray-200 bg-gray-50 p-4">
        <p className="text-md font-medium leading-7 text-gray-900">Details</p>
        <div className="mt-6 flex flex-col gap-6">
          <TextInput
            type="text"
            name="placeOfBaptism"
            label="Place of Baptism"
            placeholder="Type location name"
            disabled={disabled}
          />
          <div>
            <p className="label mb-1.5">Provide Evidence</p>
            <Dropzone
              name="proofOfBaptism"
              path={paths.applications}
              options={{
                disabled: disabled,
                accept: {
                  'image/jpeg': [],
                  'image/png': [],
                  'application/pdf': [],
                },
              }}
              fileRestrictionLabel="Only jpg, pdf files are accepted."
            />
          </div>
        </div>
      </div>
    </>
  );
}

type AdditionalSupportNeedsProps = {
  disabled?: boolean;
};

const agenciesToNames: Record<ChildAdditionalSupportNeedsAgency, string> = {
  ['SOCIAL_POLICY']: 'Social Policy',
  ['EDUCATIONAL_PSYCHOLOGY']: 'Educational Psychology',
  ['HEALTH']: 'Health',
  ['THERAPY_SERVICES']: 'Therapy Services',
};

const otherSupportNeedsToNames: Record<
  ChildAdditionalSupportNeedTypes,
  string
> = {
  ['DIETARY']: 'Dietary',
  ['MEDICAL']: 'Medical',
  ['PHYSICAL']: 'Physical',
};

function ChildAdditionalSupportNeeds(props: AdditionalSupportNeedsProps) {
  const { disabled } = props;
  const form = useFormContext();
  const additionalSupportNeeds = form.watch('additionalSupportNeeds');

  useEffect(() => {
    if (!additionalSupportNeeds.hasAdditionalSupportNeeds) {
      form.resetField('additionalSupportNeeds');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalSupportNeeds.hasAdditionalSupportNeeds]);

  return (
    <>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Additional Support Needs
        </p>
      </div>
      <div className="col-span-2 flex flex-col gap-6">
        <Checkbox
          label="Does the child have additional support needs?"
          name="additionalSupportNeeds.hasAdditionalSupportNeeds"
          disabled={disabled}
        />

        {additionalSupportNeeds.hasAdditionalSupportNeeds ? (
          <>
            <Radio
              name="additionalSupportNeeds.hasIndividualEducationalProgramme"
              label="Does the child have an Individual Educational Programme (IEP)?"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              disabled={disabled}
            />

            <Radio
              name="additionalSupportNeeds.hasCoordinatedSupportPlan"
              label="Does the child have a Co-ordinated Support Plan (CSP)?"
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              disabled={disabled}
            />

            {additionalSupportNeeds.hasCoordinatedSupportPlan === 'true' ? (
              <TextInput
                type="text"
                name="additionalSupportNeeds.coordinatedSupportPlanAuthorityName"
                label="Name of Authority"
                placeholder="Enter authority name"
                disabled={disabled}
              />
            ) : null}

            <div>
              <span className="text-sm font-medium">
                Is your child involved with any of the following agencies?
              </span>
              <div className="mt-1.5 flex flex-wrap gap-x-8 gap-y-2">
                {Object.entries(agenciesToNames).map(([key, value]) => (
                  <CheckboxBase
                    key={key}
                    name={`additionalSupportNeeds.agencies.${key}`}
                    label={value}
                    disabled={disabled}
                    checked={form.getValues(
                      `additionalSupportNeeds.agencies.${key}`
                    )}
                    onChange={() => {
                      form.setValue(
                        `additionalSupportNeeds.agencies.${key}`,
                        !form.getValues(
                          `additionalSupportNeeds.agencies.${key}`
                        )
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <Checkbox
                label="Does the child have any other particular support needs?"
                name="additionalSupportNeeds.hasAnyOtherNeed"
                disabled={disabled}
              />
              {additionalSupportNeeds.hasAnyOtherNeed ? (
                <div>
                  <span className="text-sm font-medium">Are they:</span>
                  <div className="mt-1.5 flex flex-wrap gap-x-8 gap-y-2">
                    {Object.entries(otherSupportNeedsToNames).map(
                      ([key, value]) => (
                        <CheckboxBase
                          key={key}
                          name={`additionalSupportNeeds.extraNeeds.${key}`}
                          label={value}
                          checked={form.getValues(
                            `additionalSupportNeeds.extraNeeds.${key}`
                          )}
                          disabled={disabled}
                          onChange={() => {
                            form.setValue(
                              `additionalSupportNeeds.extraNeeds.${key}`,
                              !form.getValues(
                                `additionalSupportNeeds.extraNeeds.${key}`
                              )
                            );
                          }}
                        />
                      )
                    )}
                  </div>
                </div>
              ) : null}
              <Textarea
                name="additionalSupportNeeds.specialistContactDetails"
                label="It would be useful if you provide the name and phone number of any specialist already dealing with your child so that we can contact them, if necessary."
                placeholder="Provide details where necassary"
                rows={4}
              />
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export {
  ChildAddress,
  ChildMedicalDetails,
  LocalAuthorityDetails,
  LookedAfterStatus,
  ChildReligionDetails,
  ChildAdditionalSupportNeeds,
};
