import { Modal } from '@/components/modal';
import { applicationKey } from '@/config/query-keys';
import { ChoicesProvider } from '@/context/choices.context';
import { useCreateChoiceMutation } from '@/hooks/create-hooks/use-create-choice-mutation';
import { useDeleteChoiceMutation } from '@/hooks/delete-hooks/use-delete-choice-mutation';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useWeekModels } from '@/hooks/query-hooks/use-week-models';
import { useModal } from '@/hooks/use-modal';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { Application } from '@admissions-support/types';
import { Trash01 } from '@untitled-ui/icons-react';
import { startCase } from 'lodash-es';
import { Tooltip } from 'react-tooltip';
import { ChoiceStatusTag } from '../choice-status-tag';
import { LocationChoice } from '../location-choice';
import { ChoiceModal } from '../placement-preferences/choices/choice-modal';

type PrescribedChoiceProps = {
  application: Application;
};

function PrescribedChoice(props: PrescribedChoiceProps) {
  const { application } = props;

  const {
    isOpen: isAddModalOpen,
    openModal: openAddModal,
    closeModal: closeAddModal,
  } = useModal();
  const { data: locationsData } = useLocations(undefined, {
    select: locations =>
      //hide archived/inactive locations
      locations.filter(location => location.status === 'ACTIVE'),
  });
  const { data: sessionTypesData } = useSessionTypes();
  const { data: modelsData } = useWeekModels();
  const { isOpen, openModal, closeModal } = useModal();
  const { hasPermission } = usePermissions();

  const {
    mutateAsync: createChoice,
    isPending: isChoiceCreating,
    error: deletionError,
  } = useCreateChoiceMutation(application.id, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationKey.single(application.id),
      });

      await queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
    },
  });
  const { mutateAsync: deleteChoice, isPending: isDeletingChoice } =
    useDeleteChoiceMutation({
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: applicationKey.single(application.id),
        });

        await queryClient.invalidateQueries({
          queryKey: ['application-list'],
        });
      },
    });

  const prescribedChoice = application.choices.find(
    choice => choice.type === 'PRESCRIBED'
  );

  const hasPlacedChoice = application.choices.find(
    choice =>
      choice.type === 'USER_DEFINED' &&
      (choice.status === 'GRANTED' || choice.status === 'PLACED')
  );

  if (prescribedChoice) {
    const handleDeleteChoice = async () => {
      await deleteChoice({
        applicationId: application.id,
        choiceId: prescribedChoice.id,
      });
    };

    return (
      <div className="light-gray-container relative">
        <div className="mb-3 flex items-center md:justify-between">
          <p className="mr-2 flex font-medium">Prescribed Placement</p>
          <ChoiceStatusTag status={prescribedChoice.status || undefined}>
            {startCase(prescribedChoice.status?.toLowerCase())}
          </ChoiceStatusTag>
          <button
            className="btn btn-sm btn-error ml-auto flex items-center"
            onClick={openModal}
            type="button"
          >
            <Trash01
              className="mr-2 h-5 w-5"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            Remove
          </button>
        </div>
        <LocationChoice
          schoolYearId={application.schoolYear.id.toString()}
          choice={prescribedChoice}
        />
        <Modal
          open={isOpen}
          onClose={closeModal}
          title="Cancel Prescribed Placement"
          description="Are you sure you want to remove prescribed placement? This action cannot be undone."
          primaryBtnText="Delete Placement"
          primaryAction={handleDeleteChoice}
          secondaryBtnText="Keep Placement"
          secondaryAction={closeModal}
          type="error"
          error={getErrorMessage(deletionError)}
          isLoading={isDeletingChoice}
        />
      </div>
    );
  }

  if (hasPlacedChoice) {
    return (
      <div>
        <Tooltip
          anchorSelect=".cannot-prescribe-placement"
          content="You already have a Placed / Granted choice!"
          className="tooltip"
        />
        <button
          type="button"
          className="btn btn-secondary btn-full cannot-prescribe-placement"
          disabled
        >
          Add Prescribed Placement
        </button>
      </div>
    );
  }

  return (
    <div>
      {hasPermission([Permission['application:update']]) && (
        <button
          type="button"
          className="btn btn-secondary btn-full"
          onClick={openAddModal}
        >
          Add Prescribed Placement
        </button>
      )}
      <ChoicesProvider schoolYearId={application.schoolYear.id.toString()}>
        <ChoiceModal
          onClose={closeAddModal}
          open={isAddModalOpen}
          schoolYearId={application.schoolYear.id.toString()}
          onSubmit={createChoice}
          nbPreference={-1}
          isMutating={isChoiceCreating}
          locations={locationsData || []}
          sessionTypes={sessionTypesData || []}
          models={modelsData || []}
        />
      </ChoicesProvider>
    </div>
  );
}

export { PrescribedChoice };
