import { useSchoolYear } from '@/context/school-year.context';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import {
  LocationType,
  ValidateUploadApplication,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useValidateBulkUploadMutation(
  applicationType: LocationType,
  mutationOptions?: UseMutationOptions<
    ValidateUploadApplication,
    HttpError,
    { file: string }
  >
) {
  const { schoolYear } = useSchoolYear();

  return useMutation({
    mutationFn: data =>
      applicationService.validateApplicationFile(applicationType, {
        file: data.file,
        schoolYearId: schoolYear.id,
      }),
    ...mutationOptions,
  });
}

export { useValidateBulkUploadMutation };
