import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { ChangeEvent } from 'react';
import { CheckboxBase } from '../form/common/checkbox';
import {
  LocationType,
  SchoolApplicationYearStage,
} from '@admissions-support/types';

type YearStageFilterProps = {
  applicationsType?: LocationType;
};

function YearStageFilter(props: YearStageFilterProps) {
  const { applicationsType } = props;

  const { filter, setFilter } = useApplicationControl();

  if (!applicationsType || applicationsType === 'ELC') {
    return null;
  }

  const handleYearStageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedYearStage = event.target.name as SchoolApplicationYearStage;

    const index = filter.yearStages.indexOf(selectedYearStage);
    if (index === -1) {
      setFilter(prevFilter => ({
        ...prevFilter,
        yearStages: [...prevFilter.yearStages, selectedYearStage],
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        yearStages: prevFilter.yearStages.filter(
          yearStage => yearStage !== selectedYearStage
        ),
      }));
    }
  };

  const handleSelectAllYearStages = () => {
    if (filter.yearStages.length === yearStages.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        yearStages: defaultApplicationControl.filter.yearStages,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        yearStages: yearStages.map(ys => ys.value),
      }));
    }
  };

  const isSomeSelected = yearStages.some(yearStage =>
    filter.yearStages.includes(yearStage.value)
  );

  return (
    <>
      {yearStages
        .filter(yearStage =>
          yearStage.value.includes(applicationsType.toLowerCase())
        )
        .map(yearStage => {
          return (
            <CheckboxBase
              key={yearStage.value.toString()}
              name={yearStage.value.toString()}
              label={yearStage.label}
              onChange={handleYearStageChange}
              checked={
                (filter.yearStages &&
                  filter.yearStages.findIndex(
                    ys => ys === yearStage.value.toString()
                  ) >= 0) ||
                false
              }
            />
          );
        })}
      <hr className="!my-4" />
      <CheckboxBase
        name="selectAllYearStages"
        label="Select All"
        onChange={handleSelectAllYearStages}
        checked={filter.yearStages.length === yearStages.length}
        indeterminate={isSomeSelected}
      />
    </>
  );
}

const yearStages: { label: string; value: SchoolApplicationYearStage }[] = [
  { label: 'Primary 1', value: 'primaryOne' },
  { label: 'Primary 2', value: 'primaryTwo' },
  { label: 'Primary 3', value: 'primaryThree' },
  { label: 'Primary 4', value: 'primaryFour' },
  { label: 'Primary 5', value: 'primaryFive' },
  { label: 'Primary 6', value: 'primarySix' },
  { label: 'Primary 7', value: 'primarySeven' },
  { label: 'Secondary 1', value: 'secondaryOne' },
  { label: 'Secondary 2', value: 'secondaryTwo' },
  { label: 'Secondary 3', value: 'secondaryThree' },
  { label: 'Secondary 4', value: 'secondaryFour' },
  { label: 'Secondary 5', value: 'secondaryFive' },
  { label: 'Secondary 6', value: 'secondarySix' },
];

export { YearStageFilter, yearStages };
