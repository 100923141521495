import { Dialog, Transition } from '@headlessui/react';
import { Download01, XClose } from '@untitled-ui/icons-react';
import { Fragment } from 'react';
import { Alert } from './alert';
import { BulkUploadForm } from './form/bulk-upload.form';
import { LocationType } from '@admissions-support/types';

type BulkUploadModalProps = {
  open: boolean;
  applicationType: LocationType;
  onClose: () => void;
};

function BulkUploadModal(props: BulkUploadModalProps) {
  const { open, applicationType, onClose } = props;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-60" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75  backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XClose className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Batch Upload Applications
                  </Dialog.Title>
                  <p className="mb-5 text-sm text-gray-600">
                    Upload and attach files to this project.
                  </p>
                  <Alert
                    type="warning"
                    className="mb-5"
                    text={
                      <>
                        <p className="mb-1 font-medium">Attention</p>
                        <p className="mb-3">
                          CSV must match the data structure that has been
                          provided in the sample file. You can download a copy
                          of this below.
                        </p>
                        <a
                          className="flex items-center font-medium text-yellow-700"
                          href="/application_import_sample.csv"
                        >
                          <Download01
                            aria-hidden="true"
                            viewBox="0 0 24 24"
                            className="mr-2 h-5 w-5"
                          />
                          Download Sample CSV
                        </a>
                      </>
                    }
                  />
                  <BulkUploadForm
                    onClose={onClose}
                    applicationType={applicationType}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { BulkUploadModal };
