import { staffRouterPath } from '@/config/route-paths.config';
import { useLeaveModal } from '@/hooks/use-leave-modal';
import { ApplicationFormDto, OverridenSiblingsDto } from '@/types/application';
import { HttpError, isBadRequest } from '@/types/error';
import { transformFormDataToDto } from '@/utils/application-utils';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  AllApplications,
  Sex,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { householdFormSchema } from './household.schema';
import { HouseholdFormData } from './houshold.type';

const defaultSibling: OverridenSiblingsDto = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  sex: '',
  school: null,
  stage: '',
};

type UseHouseholdFormProps = {
  onSubmit: (data: UpdateApplicationDto) => Promise<AllApplications>;
  initialData: AllApplications;
};

function transformApiDataToFormData(
  initialData: AllApplications
): HouseholdFormData {
  return {
    siblings: initialData.siblings.map(sibling => ({
      ...sibling,
      firstName: sibling.firstName || '',
      lastName: sibling.lastName || '',
      dateOfBirth: sibling.dateOfBirth
        ? formatDateToYYYYMMDD(sibling.dateOfBirth)
        : '',
      sex: sibling.sex || '',
      school:
        sibling.school && sibling.school.name
          ? {
              label: sibling.school.name || '',
              value: sibling.school.id?.toString() || '',
            }
          : null,
    })),
    homeLanguage: initialData.homeLanguage || '',
  };
}

function useHouseholdForm(props: UseHouseholdFormProps) {
  const { initialData, onSubmit } = props;
  const isDraft = useRef(true);
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const defaultValues: HouseholdFormData =
    transformApiDataToFormData(initialData);

  const defaultResolver = yupResolver(householdFormSchema);

  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        // if its draft no validation required
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  const { isDirty } = form.formState;
  useLeaveModal({
    show: isDirty,
  });

  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    navigate(
      generatePath(staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES, {
        id: params.id || '',
      })
    );
  }, [shouldRedirect, navigate, params.id]);

  const { reset } = form;
  useEffect(() => {
    if (!form.formState.isSubmitSuccessful) {
      return;
    }
    reset(undefined, { keepValues: true });

    if (!isDraft.current) {
      setShouldRedirect(true);
    }
  }, [form.formState.isSubmitSuccessful, params.id, navigate, reset]);

  useEffect(() => {
    reset(transformApiDataToFormData(initialData));
  }, [initialData, reset]);

  const handleSubmit = async (data: ApplicationFormDto) => {
    try {
      const transformedApplication = transformFormDataToDto(data, [
        'siblings',
        'homeLanguage',
      ]);

      const applicationDto: UpdateApplicationDto = {
        ...transformedApplication,
        siblings: transformedApplication.siblings?.map(sibling => ({
          ...sibling,
          sex: sibling.sex as Sex,
          dateOfBirth: sibling.dateOfBirth
            ? new Date(sibling.dateOfBirth)
            : null,
          school: sibling.school
            ? {
                name: sibling.school.label,
                // dont pass an ID when the location is not selected from the list,
                // but typed by the user instead
                id: sibling.school.__isNew__ ? null : sibling.school.value,
              }
            : {
                name: null,
                id: null,
              },
        })),
      };

      await onSubmit(applicationDto);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        const availableFields = getNestedKeys(form.getValues());

        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }

      form.setError('root.server', {
        message: Array.isArray(httpError.message)
          ? httpError.message[0]
          : httpError.message,
      });
    }
  };

  const setDraft = (status: boolean) => {
    isDraft.current = status;
  };

  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(true);
    await form.handleSubmit(handleSubmit)();
  };

  const handleSave = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setDraft(false);
    await form.handleSubmit(handleSubmit)();
  };

  return {
    form,
    handleSubmit: handleSave,
    saveAsDraft,
    defaultValues,
  };
}

export { defaultSibling, useHouseholdForm };
