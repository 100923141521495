import { Toggle } from '@/components/form/common/toggle';
import { useFormContext } from 'react-hook-form';

function IsApplicationTemplateEnabledForm() {
  const { watch, setValue } = useFormContext();

  const isEnabled = watch('isEnabled');

  const handleToggle = (value: boolean) => {
    setValue('isEnabled', value);
  };

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="mt-6 bg-white pt-6">
        <div className="two-col-form pt-0">
          <div>
            <h2 className="text-md font-medium leading-7 text-gray-900">
              Toggle Template Availability
            </h2>
            <p className="text-md leading-6 text-gray-600">
              Enable or disable this application template. Disabled templates
              will not be shown to parents, only staff members.
            </p>
          </div>

          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
            <div className="sm:col-span-12">
              <div className="mb-6">
                <Toggle
                  label="Enable Template"
                  onChange={handleToggle}
                  value={isEnabled}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { IsApplicationTemplateEnabledForm };
