import { ApplicantNameSearchBar } from '@/components/application/applicant-name-searchbar';
import { ApplicationsFilterDrawer } from '@/components/application/applications-filter-drawer';
import { ApplicationsFilterRow } from '@/components/application/applications-filter-row';
import { ApplicationsList } from '@/components/application/applications-list';
import { MatchingModal } from '@/components/application/matching-modal';
import { PermissionGuard } from '@/components/auth/permission-guard';
import { BulkUploadModal } from '@/components/bulk-upload.modal';
import { NewApplicationModal } from '@/components/new-application.modal';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { ApplicationControlProvider } from '@/context/application-control.context';
import { Permission } from '@/types/auth';
import { LocationType } from '@admissions-support/types';
import { useState } from 'react';

function ApplicationsPrimary() {
  const [openNewApplicationModal, setOpenNewApplicationModal] = useState(false);

  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState(false);

  const handleOpenActionModal = (applicationIds: string[]) => {
    // TODO: Implement action modal handling
  };

  const openBulkUploadModal = () => {
    setIsBulkUploadModalOpen(true);
  };

  const closeBulkUploadModal = () => {
    setIsBulkUploadModalOpen(false);
  };

  return (
    <>
      <PageTitle title="Applications" variant="gray">
        <PermissionGuard
          requiredPermissions={[Permission['application:create']]}
        >
          <div className="space-x-2 sm:ml-auto">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setOpenNewApplicationModal(true);
              }}
            >
              New Application
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={openBulkUploadModal}
            >
              Bulk upload
            </button>

            <MatchingModal />
          </div>
        </PermissionGuard>
      </PageTitle>
      <ApplicationControlProvider type="primary_main">
        <div className="!mt-12 flex flex-col space-y-2 sm:flex-row sm:items-center sm:space-y-0">
          <div className="flex space-x-2 sm:mr-2">
            <SchoolYearFilter />

            <ApplicationsFilterDrawer
              applicationsType={'PRIMARY' as LocationType}
            />
          </div>
          <div className="sm:ml-auto">
            <ApplicantNameSearchBar />
          </div>
        </div>
        <ApplicationsFilterRow applicationsType={'PRIMARY' as LocationType} />
        <ApplicationsList
          onActionApply={handleOpenActionModal}
          applicationsType={'PRIMARY' as LocationType}
        />
      </ApplicationControlProvider>

      <NewApplicationModal
        open={openNewApplicationModal}
        onClose={() => {
          setOpenNewApplicationModal(false);
        }}
      />

      <BulkUploadModal
        open={isBulkUploadModalOpen}
        onClose={closeBulkUploadModal}
        applicationType={'PRIMARY' as LocationType}
      />
    </>
  );
}

export { ApplicationsPrimary };
