import { PermissionGuard } from '@/components/auth/permission-guard';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { LoadingScreen } from '@/components/loading-screen';
import { SchoolLocationOverviewForm } from '@/components/locations/school-locations/school-location-overview.form';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolLocation } from '@/hooks/query-hooks/use-school-location';
import { Permission } from '@/types/auth';
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';

function SchoolLocationOverview() {
  const navigate = useNavigate();

  const params = useParams<{ schoolId: string }>();

  const {
    isLoading,
    data: location,
    isError,
  } = useSchoolLocation(params.schoolId);

  const handleCancel = () => {
    navigate(staffRouterPath.LOCATIONS);
  };

  const handleEdit = () => {
    if (!params.schoolId) {
      return navigate(staffRouterPath.LOCATIONS);
    }

    const redirectUrl = generatePath(
      `${staffRouterPath.EDIT_SCHOOL_LOCATION}`,
      {
        schoolId: params.schoolId || null,
      }
    );

    navigate(redirectUrl);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  return (
    <div>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      >
        <PermissionGuard requiredPermissions={[Permission['location:update']]}>
          <div className="flex gap-3">
            <button
              type="button"
              className="btn btn-empty"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleEdit}
              disabled={isLoading}
            >
              Edit Location
            </button>
          </div>
        </PermissionGuard>
      </PageTitle>
      <LocationTabNavigation id={params.schoolId || ''} isSchoolLocation />

      <h2 className="my-6 text-lg font-medium text-gray-900">
        Location Overview
      </h2>

      <SchoolLocationOverviewForm school={location} />
    </div>
  );
}

export { SchoolLocationOverview };
