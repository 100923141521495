import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Textarea } from './common/textarea';
import { queryClient } from '@/libs/react-query';
import { applicationKey, logKey } from '@/config/query-keys';

const schema = yup.object({
  notes: yup.string().label('Notes'),
});

type ApplicationNoteFormProps = {
  applicationId: string;
  initialNote?: string;
};

function ApplicationNoteForm(props: ApplicationNoteFormProps) {
  const { applicationId, initialNote } = props;

  const { mutateAsync, isPending } = useUpdateApplicationMutation(
    applicationId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: logKey.single(applicationId),
        });
        queryClient.invalidateQueries({
          queryKey: applicationKey.single(applicationId),
        });
      },
    }
  );

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      notes: initialNote,
    },
  });

  const submitHandler = async (data: { notes?: string }) => {
    await mutateAsync(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submitHandler)} className="">
        <Textarea
          name="notes"
          label="Notes"
          disabled={isPending}
          placeholder="Enter a note..."
          helperText="Add any notes relating to this application."
          rows={5}
        />
        <button className="btn btn-secondary mt-6">Save Note</button>
      </form>
    </FormProvider>
  );
}

export { ApplicationNoteForm };
