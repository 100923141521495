import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  AddDefaultSessionDataParamsDto,
  CreateExtraHourResourceDto,
  CreateLocationDto,
  CreateResourceDto,
  CreateSessionDto,
  ExtraHourPublic,
  FetchExtraHoursByLocationDto,
  Location,
  LocationListing,
  Operation,
  Resource,
  School,
  Session,
  UpdateExtraHourResourceDto,
  UpdateLocationDto,
  UpdateOperationDto,
  UpdateResourceDto,
  UpdateSessionDto,
} from '@admissions-support/types';
import { QueryLocations } from '@admissions-support/types/dist/location/dtos';
import { AxiosResponse } from 'axios';
import { compact } from 'lodash-es';

const createLocation = async (data: CreateLocationDto): Promise<Location> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Location>,
    CreateLocationDto
  >(`/locations`, data);

  return response.data;
};

const addDefaultSessionData = async (
  payload: AddDefaultSessionDataParamsDto & { locationId: string }
) => {
  const response = await axiosClient.post(
    `/operations/locations/${payload.locationId}/add-default-session-data`,
    { schoolYearId: payload.schoolYearId }
  );

  return response.data;
};

const getLocations = async (
  query?: QueryLocations
): Promise<LocationListing[]> => {
  const queryParams = [];

  if (query?.type) {
    queryParams.push(`type=${query.type}`);
  }

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<LocationListing[]>,
    CreateLocationDto
  >(`/locations?${queryParams.join('&')}`);

  return response.data;
};

const getLocation = async (id: string): Promise<Location> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<Location>>(
    `/locations/${id}`
  );

  return response.data;
};

const updateLocation = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateLocationDto;
}): Promise<Location> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Location>,
    UpdateLocationDto
  >(`/locations/${id}`, data);

  return response.data;
};

const deleteLocation = async (id: string) => {
  await axiosClient.delete(`/locations/${id}`);
};

const createSession = async ({
  data,
  id,
}: {
  id: string;
  data: CreateSessionDto;
}): Promise<Location> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Location>,
    CreateSessionDto
  >(`/locations/${id}/sessions`, data);

  return response.data;
};

const getSessions = async ({
  locationId,
  termId,
}: {
  locationId: string;
  termId: string;
}): Promise<Session[]> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<Session[]>,
    CreateLocationDto
  >(`/locations/${locationId}/sessions?schoolYearId=${termId}`);

  return response.data;
};

const updateSession = async ({
  locationId,
  sessionId,
  data,
}: {
  locationId: string;
  sessionId: string;
  data: UpdateSessionDto;
}): Promise<Location> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Location>,
    UpdateSessionDto
  >(`/locations/${locationId}/sessions/${sessionId}`, data);

  return response.data;
};

const deleteSession = async ({
  locationId,
  sessionId,
}: {
  locationId: string;
  sessionId: string;
}) => {
  await axiosClient.delete<HttpError>(
    `/locations/${locationId}/sessions/${sessionId}`
  );
};

const getOperation = async ({
  locationId,
  termId,
}: {
  locationId: string;
  termId: string;
}): Promise<Operation> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<Operation>>(
    `/operations/locations/${locationId}?schoolYearId=${termId}`
  );

  return response.data;
};

const updateOperation = async ({
  operationId,
  data,
}: {
  operationId: string;
  data: UpdateOperationDto;
}): Promise<Operation> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Operation>,
    UpdateOperationDto
  >(`/operations/${operationId}`, data);

  return response.data;
};

const updateLocationResource = async ({
  operationId,
  resourceId,
  data,
}: {
  operationId: string;
  resourceId: string;
  data: UpdateResourceDto;
}): Promise<Resource> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Resource>,
    UpdateResourceDto
  >(`/operations/${operationId}/resources/${resourceId}`, data);

  return response.data;
};

const createLocationResource = async ({
  operationId,
  data,
}: {
  operationId: string;
  data: CreateResourceDto;
}): Promise<Resource> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Resource>,
    CreateResourceDto
  >(`/operations/${operationId}/resources`, data);

  return response.data;
};

const deleteLocationResource = async ({
  operationId,
  resourceId,
}: {
  resourceId: string;
  operationId: string;
}) => {
  await axiosClient.delete<HttpError>(
    `/operations/${operationId}/resources/${resourceId}`
  );
};

const getSchools = async (): Promise<School[]> => {
  const response = await axiosClient.get<HttpError, AxiosResponse<School[]>>(
    '/schools'
  );

  return response.data;
};

const getExtraHoursByLocation = async (
  locationId: string,
  data: FetchExtraHoursByLocationDto
): Promise<ExtraHourPublic[]> => {
  const queryParams = compact([
    `schoolYearId=${data.schoolYearId}`,
    `to=${data.to}`,
    `from=${data.from}`,
    data.isSessionQuery ? `isSessionQuery=${data.isSessionQuery}` : null,
  ]);

  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<ExtraHourPublic[]>
  >(`/extra-hours/locations/${locationId}?${queryParams.join('&')}`);

  return response.data;
};

const createExtraHourResource = async ({
  operationId,
  data,
}: {
  operationId: string;
  data: CreateExtraHourResourceDto;
}): Promise<Operation> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<Operation>,
    CreateExtraHourResourceDto
  >(`/operations/${operationId}/extra-hour-resources`, data);

  return response.data;
};

const updateExtraHourResource = async ({
  operationId,
  resourceId,
  data,
}: {
  operationId: string;
  resourceId: string;
  data: UpdateExtraHourResourceDto;
}): Promise<Operation> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<Operation>,
    UpdateExtraHourResourceDto
  >(`/operations/${operationId}/extra-hour-resources/${resourceId}`, data);

  return response.data;
};

const deleteExtraHourResource = async ({
  operationId,
  resourceId,
}: {
  operationId: string;
  resourceId: string;
}) => {
  await axiosClient.delete<HttpError>(
    `/operations/${operationId}/extra-hour-resources/${resourceId}`
  );
};

export const locationService = Object.freeze({
  createLocation,
  getLocations,
  getLocation,
  updateLocation,
  deleteLocation,
  createSession,
  getSessions,
  updateSession,
  deleteSession,
  getOperation,
  updateOperation,
  updateLocationResource,
  createLocationResource,
  deleteLocationResource,
  getSchools,
  getExtraHoursByLocation,
  createExtraHourResource,
  updateExtraHourResource,
  deleteExtraHourResource,
  addDefaultSessionData,
});
