import {
  exportableKeysApplications,
  exportableKeysBookings,
  exportableKeysLocations,
  exportableKeysSchoolApplications,
  exportableKeysSchoolLocations,
} from '@/utils/query-builder-utils';

export const applicationsToggledColumnsDefaultValue = Object.fromEntries(
  exportableKeysApplications.map(key => [key, true])
);

export const schoolApplicationsToggledColumnsDefaultValue = Object.fromEntries(
  exportableKeysSchoolApplications.map(key => [key, true])
);

export const bookingsToggledColumnsDefaultValue = Object.fromEntries(
  exportableKeysBookings.map(key => [key, true])
);

export const locationsToggledColumnsDefaultValue = Object.fromEntries(
  exportableKeysLocations.map(key => [key, true])
);

export const schoolLocationsToggledColumnsDefaultValue = Object.fromEntries(
  exportableKeysSchoolLocations.map(key => [key, true])
);
