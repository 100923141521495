import { DeleteUserDto } from '@admissions-support/types/dist/users/dtos';
import { userKeys } from '@/config/query-keys';
import { userService } from '@/services/user.service';
import { HttpError } from '@/types/error';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useDeleteUserMutation(
  id: string,
  mutationOptions?: UseMutationOptions<void, HttpError, DeleteUserDto>
) {
  return useMutation({
    mutationKey: userKeys.delete(id),
    mutationFn: (data: DeleteUserDto) => userService.deleteUser(id, data),
    ...mutationOptions,
  });
}

export { useDeleteUserMutation };
