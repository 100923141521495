import { FormColEmptyState } from '@/components/form-col-empty-state';
import { LocationSessionForm } from '@/components/form/location-session.form';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { locationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolYear } from '@/context/school-year.context';
import { useCreateSessionMutation } from '@/hooks/create-hooks/use-create-session-mutation';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useSessions } from '@/hooks/query-hooks/use-sessions';
import { useUpdateSessionMutation } from '@/hooks/update-hooks/use-update-session-mutation';
import { useWeekModels } from '@/hooks/query-hooks/use-week-models';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { UpdateSessionDto } from '@admissions-support/types';
import { Plus } from '@untitled-ui/icons-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function LocationSessions() {
  const navigate = useNavigate();
  const [isCreateNewSessionOpen, setIsCreateNewSessionOpen] = useState(false);
  const { schoolYear } = useSchoolYear();
  const { hasPermission } = usePermissions();

  const params = useParams<{ locationId: string }>();
  const locationId = params.locationId || '';
  const {
    isLoading: isLocationLoading,
    data: location,
    isError: isLocationError,
  } = useLocation(locationId);

  useEffect(() => {
    if (isLocationError) {
      navigate(staffRouterPath.LOCATIONS);
    }
  }, [isLocationError, navigate]);

  const {
    isLoading: isSessionTypesLoading,
    data: sessionTypes,
    isError: isSessionTypesError,
  } = useSessionTypes();
  const {
    isLoading: isWeekModelsLoading,
    data: weekModels,
    isError: isWeekModelsError,
  } = useWeekModels();
  const {
    isLoading: isSessionsLoading,
    data: sessions,
    isError: isSessionsError,
  } = useSessions(locationId, schoolYear.id);
  const { mutateAsync: create, isPending: isCreating } =
    useCreateSessionMutation({
      onSuccess: async () => {
        setIsCreateNewSessionOpen(false);
        await invalidateSessionQueries();
      },
    });

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateSessionMutation({
      onSuccess: invalidateSessionQueries,
    });

  async function invalidateSessionQueries() {
    await queryClient.invalidateQueries({
      queryKey: locationKey.listSessions(locationId, schoolYear.id),
      refetchType: 'active',
    });
  }

  const isPageLoading =
    isLocationLoading ||
    isSessionTypesLoading ||
    isWeekModelsLoading ||
    isSessionsLoading;

  const isError =
    isLocationError ||
    isSessionTypesError ||
    isWeekModelsError ||
    isSessionsError;

  if (isPageLoading) {
    return (
      <>
        <PageTitle
          title={location?.name || ''}
          variant="gray"
          className="border-none"
          description={
            location?.name ? (
              <SchoolYearFilter className="-mt-1 w-64" />
            ) : undefined
          }
        >
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={params.locationId || ''} />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (isError) {
    return (
      <>
        <PageTitle
          title={location?.name || 'Location'}
          variant="gray"
          className="border-none"
          description={<SchoolYearFilter className="-mt-1 w-64" />}
        />
        <LocationTabNavigation id={params.locationId || ''} />
        <p className="mt-6 text-center text-sm">
          Something went wrong! Try again later!
        </p>
      </>
    );
  }

  if (!sessions) {
    return (
      <>
        <PageTitle
          title={location?.name || 'Location'}
          variant="gray"
          className="border-none"
          description={<SchoolYearFilter className="-mt-1 w-64" />}
        />
        <LocationTabNavigation id={params.locationId || ''} />
        <p className="mt-6 text-center text-sm">
          Settings are not available for this School Year
        </p>
      </>
    );
  }

  const canUserEdit = hasPermission([Permission['location:update']]);

  const hasSessionTypes =
    Array.isArray(sessionTypes) && sessionTypes.length > 0;

  const hasWeekModels = Array.isArray(weekModels) && weekModels.length > 0;

  const hasEnoughDataToDisplayForm = hasSessionTypes && hasWeekModels;

  return (
    <div>
      <PageTitle
        title={location?.name || 'Location'}
        description={<SchoolYearFilter className="-mt-1 w-64" />}
        variant="gray"
        className="border-none"
      />
      <Tooltip
        className="tooltip"
        place="top"
        anchorSelect=".copy-session-id-tooltip"
        content="Copy Session ID"
      />
      <LocationTabNavigation id={params.locationId || ''} />
      <div className="two-col-form">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            Sessions
          </p>
          <p className="text-md leading-6 text-gray-600">
            Specify session details.
          </p>
        </div>
        <div className="col-span-2 space-y-4">
          <div className="space-y-4 divide-y">
            {!hasSessionTypes && (
              <FormColEmptyState
                title="No Session Types Found"
                btnText="Create Session Type"
                slug={staffRouterPath.NEW_SESSION_TYPE}
                description="Before you're able to define capacity for this location, please define some session types."
              />
            )}
            {!hasWeekModels && (
              <FormColEmptyState
                title="No Week Model Found"
                btnText="Create Week Model"
                slug={staffRouterPath.NEW_WEEK_MODEL}
                description="Before you're able to create a session for this location, please define a week model."
              />
            )}
          </div>
          {hasEnoughDataToDisplayForm && (
            <>
              {isCreateNewSessionOpen ? (
                <LocationSessionForm
                  sessionTypes={sessionTypes || []}
                  weekModels={weekModels || []}
                  onClose={() => setIsCreateNewSessionOpen(false)}
                  onSubmit={create}
                  isMutating={isCreating}
                />
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary flex items-center"
                  onClick={() => setIsCreateNewSessionOpen(true)}
                  disabled={!canUserEdit}
                >
                  <Plus
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  Add Session
                </button>
              )}

              {sessions?.map(session => (
                <LocationSessionForm
                  key={session.id}
                  id={session.id}
                  sessionTypes={sessionTypes || []}
                  weekModels={weekModels || []}
                  onClose={() => setIsCreateNewSessionOpen(false)}
                  onSubmit={(data: UpdateSessionDto) =>
                    update({ sessionId: session.id, data })
                  }
                  isMutating={isUpdating}
                  initialData={session}
                  disabled={!canUserEdit}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { LocationSessions };
