import {
  AllApplications,
  Child,
  ChildAdditionalSupportNeeds,
  ChildAdditionalSupportNeedsAgency,
  ChildAdditionalSupportNeedsDto,
  ChildAdditionalSupportNeedTypes,
  ChildLookedAfterDto,
} from '@admissions-support/types';

import { Application } from '@admissions-support/types';

import {
  Attachment,
  ChildLookedAfter,
  LocalAuthorityDetails,
  SeemisAddress,
} from '@admissions-support/types';
import {
  NurseryAdditionalChildDetailsFormData,
  SchoolAdditionalChildDetailsFormData,
} from './additional-child-details.type';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { OverriddenChildLookedAfterDto } from '@/types/application';

/**
 * Transforms the API address to the form address
 * @param address - The API address
 * @returns The form address
 */
function transformApiAddressToFormAddress(
  address: SeemisAddress
):
  | NurseryAdditionalChildDetailsFormData['address']
  | SchoolAdditionalChildDetailsFormData['address'] {
  return {
    lineOne: address.lineOne || '',
    lineTwo: address.lineTwo || null,
    lineThree: address.lineThree || null,
    lineFour: address.lineFour || null,
    postcode: address.postcode || '',
    uprn: address.uprn || null,
    eastings: address.eastings?.toString() || null,
    northings: address.northings?.toString() || null,
  };
}

/**
 * Transforms the API local authority details to the form local authority details
 * @param localAuthorityDetails - The API local authority details
 * @returns The form local authority details
 */
function transformLocalAuthorityDetailsToFormLocalAuthorityDetails(
  localAuthorityDetails: LocalAuthorityDetails | null
): NurseryAdditionalChildDetailsFormData['localAuthorityDetails'] {
  return {
    nursery: localAuthorityDetails?.nursery,
    isSplit: localAuthorityDetails?.isSplit,
    splitDetails: localAuthorityDetails?.splitDetails,
    isAlreadyAttending: localAuthorityDetails?.isAlreadyAttending,
    attendanceDetails: localAuthorityDetails?.attendanceDetails,
    isExtendedChildcare: localAuthorityDetails?.isExtendedChildcare,
  };
}

/**
 * Transforms the API multi select fields to the form multi select fields
 * @param fields - The API multi select fields
 * @returns The form multi select fields
 */
function transformMultiSelectFieldsToFormMultiSelectFields(
  fields: string[] = []
): Record<string, boolean> {
  return fields.reduce((acc, field) => {
    acc[field] = true;
    return acc;
  }, {} as Record<string, boolean>);
}

/**
 * Transforms the API additional support needs to the form additional support needs
 * @param additionalSupportNeeds - The API additional support needs
 * @returns The form additional support needs
 */
function transformAdditionalSupportNeedsToFormAdditionalSupportNeeds(
  additionalSupportNeeds: ChildAdditionalSupportNeeds
): SchoolAdditionalChildDetailsFormData['additionalSupportNeeds'] {
  return {
    hasAdditionalSupportNeeds: additionalSupportNeeds.hasAdditionalSupportNeeds,
    hasIndividualEducationalProgramme:
      additionalSupportNeeds.hasIndividualEducationalProgramme.toString(),
    hasCoordinatedSupportPlan:
      additionalSupportNeeds.hasCoordinatedSupportPlan.toString(),
    coordinatedSupportPlanAuthorityName:
      additionalSupportNeeds.coordinatedSupportPlanAuthorityName,
    agencies: transformMultiSelectFieldsToFormMultiSelectFields(
      additionalSupportNeeds.agencies
    ),
    hasAnyOtherNeed: additionalSupportNeeds.hasAnyOtherNeed,
    extraNeeds: transformMultiSelectFieldsToFormMultiSelectFields(
      additionalSupportNeeds.extraNeeds
    ),
    specialistContactDetails:
      additionalSupportNeeds.specialistContactDetails || '',
  };
}

/**
 * Transforms the API place of baptism to the form place of baptism
 * @param placeOfBaptism - The API place of baptism
 * @returns The form place of baptism
 */
function transformPlaceOfBaptismToFormPlaceOfBaptism(
  placeOfBaptism: string | null
): SchoolAdditionalChildDetailsFormData['placeOfBaptism'] {
  return placeOfBaptism || '';
}

/**
 * Transforms the API proof of baptism to the form proof of baptism
 * @param proofOfBaptism - The API proof of baptism
 * @returns The form proof of baptism
 */
function transformProofOfBaptismToFormProofOfBaptism(
  proofOfBaptism: Attachment[] | null
): SchoolAdditionalChildDetailsFormData['proofOfBaptism'] {
  return proofOfBaptism || [];
}

/**
 * Transforms the API move in date to the form move in date
 * @param moveInDate - The API move in date
 * @returns The form move in date
 */
function transformMoveInDateToFormMoveInDate(
  moveInDate: Date | null
):
  | NurseryAdditionalChildDetailsFormData['moveInDate']
  | SchoolAdditionalChildDetailsFormData['moveInDate'] {
  return moveInDate ? formatDateToYYYYMMDD(moveInDate) : '';
}

/**
 * Transforms the API looked after status to the form looked after status
 * @param lookedAfter - The API looked after status
 * @returns The form looked after status
 */
function transformLookedAfterStatusToFormLookedAfterStatus(
  lookedAfter: ChildLookedAfter
): NurseryAdditionalChildDetailsFormData['lookedAfter'] {
  return {
    ...lookedAfter,
    isSubjectToCourtDecision:
      lookedAfter.isSubjectToCourtDecision !== null
        ? lookedAfter.isSubjectToCourtDecision
          ? 'true'
          : 'false'
        : null,
  };
}

/**
 * Transforms the API data to the form data
 * @param initialData - The API data
 * @returns The form data
 */
function transformNurseryApiDataToFormData(
  initialData: Application
): NurseryAdditionalChildDetailsFormData {
  return {
    address: transformApiAddressToFormAddress(initialData.address),
    homeStatus: initialData.homeStatus,
    moveInDate: transformMoveInDateToFormMoveInDate(initialData.moveInDate),
    medicalDetails: initialData.medicalDetails,
    lookedAfter: transformLookedAfterStatusToFormLookedAfterStatus(
      initialData.lookedAfter
    ),
    proofOfAddress: initialData?.proofOfAddress || [],
    localAuthorityDetails:
      transformLocalAuthorityDetailsToFormLocalAuthorityDetails(
        initialData.localAuthorityDetails
      ),
  };
}

/**
 * Transforms the form looked after status to the API looked after status
 * @param lookedAfter - The form looked after status
 * @returns The API looked after status
 */
const transformFormLookedAfterStatusToApiLookedAfterStatus = (
  lookedAfter: OverriddenChildLookedAfterDto
): ChildLookedAfterDto => {
  return {
    ...lookedAfter,
    isSubjectToCourtDecision:
      lookedAfter.isSubjectToCourtDecision === 'true' ? true : false,
  };
};

/**
 * Transforms the API data to the form data
 * @param initialData - The API data
 * @param childData - The child data
 * @returns The form data
 */
const transformSchoolApiDataToFormData = (
  initialData: AllApplications,
  childData: Child
): SchoolAdditionalChildDetailsFormData => {
  return {
    address: transformApiAddressToFormAddress(initialData.address),
    homeStatus: initialData.homeStatus,
    moveInDate: transformMoveInDateToFormMoveInDate(initialData.moveInDate),
    proofOfAddress: initialData?.proofOfAddress || [],
    lookedAfter: transformLookedAfterStatusToFormLookedAfterStatus(
      initialData.lookedAfter
    ),
    additionalSupportNeeds:
      transformAdditionalSupportNeedsToFormAdditionalSupportNeeds(
        initialData.additionalSupportNeeds
      ),
    placeOfBaptism: transformPlaceOfBaptismToFormPlaceOfBaptism(
      childData.placeOfBaptism
    ),
    proofOfBaptism: transformProofOfBaptismToFormProofOfBaptism(
      childData.proofOfBaptism
    ),
    medicalDetails: initialData.medicalDetails,
  };
};

/**
 * Converts the form additional support needs agencies to the API additional support needs agencies
 * @param agencies - The form additional support needs agencies
 * @returns The API additional support needs agencies
 */
const convertFormAdditionalSupportNeedsAgenciesToApiAdditionalSupportNeedsAgencies =
  (agencies: Record<string, boolean>): ChildAdditionalSupportNeedsAgency[] => {
    return Object.entries(agencies).reduce<ChildAdditionalSupportNeedsAgency[]>(
      (prev, [agency, value]) => {
        if (value) {
          prev.push(agency as ChildAdditionalSupportNeedsAgency);
        }
        return prev;
      },
      []
    );
  };

/**
 * Converts the form additional support needs extra needs to the API additional support needs extra needs
 * @param extraNeeds - The form additional support needs extra needs
 * @returns The API additional support needs extra needs
 */
const convertFormAdditionalSupportNeedsExtraNeedsToApiAdditionalSupportNeedsExtraNeeds =
  (extraNeeds: Record<string, boolean>): ChildAdditionalSupportNeedTypes[] => {
    return Object.entries(extraNeeds).reduce<ChildAdditionalSupportNeedTypes[]>(
      (prev, [extraNeed, value]) => {
        if (value) {
          prev.push(extraNeed as ChildAdditionalSupportNeedTypes);
        }
        return prev;
      },
      []
    );
  };

/**
 * Transforms the form additional support needs to the API additional support needs
 * @param additionalSupportNeeds - The form additional support needs
 * @returns The API additional support needs
 */
const transformFormAdditionalSupportNeedsToApiAdditionalSupportNeeds = (
  additionalSupportNeeds: SchoolAdditionalChildDetailsFormData['additionalSupportNeeds']
): ChildAdditionalSupportNeedsDto => {
  return {
    hasAdditionalSupportNeeds:
      additionalSupportNeeds?.hasAdditionalSupportNeeds || false,
    hasIndividualEducationalProgramme:
      additionalSupportNeeds?.hasIndividualEducationalProgramme === 'true' ||
      false,
    hasCoordinatedSupportPlan:
      additionalSupportNeeds?.hasCoordinatedSupportPlan === 'true' || false,
    coordinatedSupportPlanAuthorityName:
      additionalSupportNeeds?.coordinatedSupportPlanAuthorityName || '',
    agencies:
      convertFormAdditionalSupportNeedsAgenciesToApiAdditionalSupportNeedsAgencies(
        additionalSupportNeeds?.agencies || {}
      ),
    hasAnyOtherNeed: additionalSupportNeeds?.hasAnyOtherNeed || false,
    extraNeeds:
      convertFormAdditionalSupportNeedsExtraNeedsToApiAdditionalSupportNeedsExtraNeeds(
        additionalSupportNeeds?.extraNeeds || {}
      ),
    specialistContactDetails:
      additionalSupportNeeds?.specialistContactDetails || undefined,
  };
};

export {
  transformNurseryApiDataToFormData,
  transformSchoolApiDataToFormData,
  transformFormAdditionalSupportNeedsToApiAdditionalSupportNeeds,
  transformFormLookedAfterStatusToApiLookedAfterStatus,
};
