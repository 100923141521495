import { FilterItem } from '@/components/filter-item';
import { PermissionGuard } from '@/components/auth/permission-guard';
import { TextInputBase } from '@/components/form/common/text-input';
import { Toggle } from '@/components/form/common/toggle';
import { messageCategories } from '@/components/messaging/message-categories';
import { MessageCategoryFilter } from '@/components/messaging/message-category-filter';
import { PageTitle } from '@/components/page-title';
import { staffRouterPath } from '@/config/route-paths.config';
import { useMessages } from '@/hooks/query-hooks/use-messages';
import { Permission } from '@/types/auth';
import { ListTicketsResponseItem } from '@admissions-support/types';
import { SearchLg } from '@untitled-ui/icons-react';
import { debounce } from 'lodash-es';
import { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { MessagingTable } from './messaging-table';

function Messaging() {
  const [showUnreadFirst, setShowUnreadFirst] = useState(false);
  const [showClosedTasks, setShowClosedTasks] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<
    ListTicketsResponseItem['topic'][]
  >([]);

  const { data: messages, isLoading } = useMessages(
    { isParent: false },
    {
      ...(search?.length && { search }),
      ...(selectedCategories.length > 0 && {
        topic: selectedCategories,
      }),
      ...(showClosedTasks && {
        status: ['OPEN', 'IN_PROGRESS', 'PENDING', 'CLOSED'],
      }),
      ...(showUnreadFirst && { showUnreadFirst: true }),
    }
  );

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const debouncedSearch = debounce(handleSearchChange, 300);

  const resetCategoryFilter = () => {
    setSelectedCategories([]);
  };

  return (
    <div>
      <PageTitle title="Messaging" variant="gray">
        <PermissionGuard requiredPermissions={[Permission['messaging:create']]}>
          <Link
            to={staffRouterPath.MESSAGING_NEW_CONVERSATION}
            className="btn btn-primary"
          >
            New Ticket
          </Link>
        </PermissionGuard>
      </PageTitle>
      <div className="mt-6 flex w-full min-w-full flex-col justify-between gap-8 py-6 lg:flex-row">
        <div className="flex flex-col gap-4 lg:flex-row">
          <TextInputBase
            type="search"
            placeholder="Tickets"
            className="w-full lg:w-[320px]"
            defaultValue={search}
            onChange={debouncedSearch}
            LeadingIcon={
              <SearchLg className="h-4 w-4 text-gray-500" viewBox="0 0 24 24" />
            }
          />
          <div>
            <div className="w-fit rounded-md shadow-sm">
              <FilterItem
                label="Filter By Category"
                counter={selectedCategories.length}
                reset={resetCategoryFilter}
                renderSelectedItems={messageCategories[selectedCategories[0]]}
              >
                <MessageCategoryFilter
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              </FilterItem>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 sm:flex-row">
          <Toggle
            value={showUnreadFirst}
            onChange={() => setShowUnreadFirst(!showUnreadFirst)}
            label="Show Unread First"
          />
          <Toggle
            value={showClosedTasks}
            onChange={() => setShowClosedTasks(!showClosedTasks)}
            label="Show Closed Tasks"
          />
        </div>
      </div>
      <div className="border-t " />
      <div className="mt-6 max-w-full">
        <MessagingTable
          messages={messages?.docs || []}
          isLoading={isLoading}
          setSelectedCategories={setSelectedCategories}
          setShowClosedTasks={setShowClosedTasks}
          setSearch={setSearch}
          isParent={false}
        />
      </div>
    </div>
  );
}

export { Messaging };
