import { ApplicationStatus } from '@admissions-support/types';
import { twMerge } from 'tailwind-merge';
import { Tag } from '../tag';

type ApplicationStatusTagProps = {
  status?: ApplicationStatus;
};

const childApplicationStatusText: Record<ApplicationStatus, string> = {
  SUBMITTED: 'Application Processing',
  PLACED: 'Application Processing',
  PLACEMENT_REJECTED: 'Placement Rejected',
  DRAFT: 'Draft Application',
  NOT_PLACED: 'Not Placed',
  GRANTED: 'Placement Granted',
};

function ChildApplicationStatusTag(props: ApplicationStatusTagProps) {
  const { status } = props;

  const tagText = status ? childApplicationStatusText[status] : '';

  return (
    <Tag
      type="outline"
      className="inline-flex w-auto flex-row items-center px-1.5 py-0.5 text-xs font-normal capitalize shadow-xs"
    >
      <span
        className={twMerge(
          'mr-1 inline-block h-1.5 w-1.5 rounded-full',
          status === 'SUBMITTED' ? 'bg-blue-500' : null,
          status === 'PLACED' ? 'bg-blue-500' : null,
          status === 'GRANTED' ? 'bg-green-500' : null,
          status === 'PLACEMENT_REJECTED' ? 'bg-red-500' : null,
          status === 'DRAFT' ? 'bg-yellow-500' : null,
          status === 'NOT_PLACED' ? 'bg-yellow-500' : null
        )}
      />
      {tagText}
    </Tag>
  );
}

export { ChildApplicationStatusTag };
