import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

function useGenerateApplicationsCsv(
  data: string[],
  options?: Partial<UseQueryOptions<Blob, HttpError>>
) {
  return useQuery<Blob, HttpError>({
    queryKey: ['generate-application-csv'],
    queryFn: () => applicationService.generateApplicationsCsv(data),
    enabled: false, // disable automatic execution
    ...options,
  });
}

export { useGenerateApplicationsCsv };
