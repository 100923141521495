import { AdditionalChildDetailsForm } from '@/components/form/additional-child-details.form';
import { ChildDetailsForm } from '@/components/form/child-details.form';
import { PageTitle } from '@/components/page-title';
import { Spinner } from '@/components/spinner';
import { applicationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateChildMutation } from '@/hooks/create-hooks/use-create-child-mutation';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useChild } from '@/hooks/query-hooks/use-child';
import { usePermissions } from '@/hooks/query-hooks/use-permissions';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import { useUpdateChildMutation } from '@/hooks/update-hooks/use-update-child-mutation';
import { Permission } from '@/types/auth';
import { isAdminDashboard } from '@/utils/is-dashboard';
import { CreateChildDto } from '@admissions-support/types';
import { useIsMutating } from '@tanstack/react-query';
import {
  Navigate,
  generatePath,
  matchPath,
  useNavigate,
  useParams,
} from 'react-router-dom';

function ChildDetails() {
  const navigate = useNavigate();
  const isNewApplication = Boolean(
    matchPath(staffRouterPath.NEW_APPLICATION, location.pathname)
  );

  const params = useParams<{ id: string }>();
  const nbOfMutation = useIsMutating({
    mutationKey: applicationKey.updateApplication,
  });

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
    isSuccess: isApplicationSuccess,
  } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const {
    data: applicationTemplate,
    isSuccess: isApplicationTemplateSuccess,
    isLoading: isApplicationTemplateLoading,
  } = useApplicationTemplate(
    isApplicationSuccess ? application.template.id.toString() : '',
    {
      enabled: isApplicationSuccess,
    }
  );

  const {
    data: child,
    error: childError,
    isLoading: isChildLoading,
  } = useChild(application?.child.id.toString() || '', {
    enabled: Boolean(application?.child.id),
  });
  const { mutateAsync: updateChild, isPending: isChildUpdating } =
    useUpdateChildMutation(application?.child.id.toString() || '');
  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(
    application?.id || ''
  );
  const { mutateAsync: createChild } = useCreateChildMutation();

  const handleStaffCreateChild = async (data: CreateChildDto) => {
    const child = await createChild(data);

    navigate(
      generatePath(staffRouterPath.NEW_APPLICATION_ELIGIBILITY, {
        childId: child.id,
      })
    );
  };

  const handleChildSubmission = async (data: CreateChildDto) => {
    if (isAdminDashboard() && isNewApplication) {
      return handleStaffCreateChild(data);
    }

    return updateChild(data);
  };

  const { hasPermission } = usePermissions();

  if (applicationError || childError) {
    return <Navigate to={staffRouterPath.APPLICATIONS} />;
  }

  const isAdditionalChildDetailsFormShown =
    !isApplicationLoading &&
    isApplicationSuccess &&
    !isApplicationTemplateLoading &&
    isApplicationTemplateSuccess &&
    child &&
    !isChildLoading;

  return (
    <div>
      <PageTitle
        title={isNewApplication ? 'New Application' : 'Edit Application'}
        variant="gray"
        className="top-0 z-10 mt-[52px] py-3 lg:sticky lg:mt-0 lg:py-5"
      >
        {application ? (
          <div className="flex gap-3">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate(staffRouterPath.APPLICATIONS)}
            >
              Cancel
            </button>

            <button
              className="btn btn-primary"
              form="upsert-application-form"
              disabled={
                isNewApplication ||
                Boolean(nbOfMutation) ||
                !hasPermission([Permission['application:update']])
              }
            >
              Save Changes
            </button>
          </div>
        ) : (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate(staffRouterPath.APPLICATIONS)}
          >
            Cancel
          </button>
        )}
      </PageTitle>
      <div className="two-col-form mt-6">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            {application ? 'Child' : 'Create Child'}
          </p>
          <p className="text-md leading-6 text-gray-600">
            Please ensure your child&apos;s details match their birth
            certificate or passport details.
          </p>
        </div>
        <div className="col-span-2 space-y-6">
          {isChildLoading ||
          isApplicationLoading ||
          isApplicationTemplateLoading ? (
            <div className="flex items-center justify-center rounded-lg border border-gray-200 bg-gray-50 px-4 py-20">
              <Spinner />
            </div>
          ) : (
            <div className="light-gray-container group">
              <ChildDetailsForm
                onSubmit={handleChildSubmission}
                isMutating={isChildUpdating}
                isLoading={isChildLoading}
                initialData={child}
              />
            </div>
          )}
        </div>
      </div>
      {isAdditionalChildDetailsFormShown ? (
        <AdditionalChildDetailsForm
          initialData={application}
          onSubmit={updateApplication}
          template={applicationTemplate}
          child={child}
        />
      ) : null}
    </div>
  );
}

export { ChildDetails };
