import { areaKey } from '@/config/query-keys';
import { useCreateAreaMutation } from '@/hooks/create-hooks/use-create-area';
import { useAreas } from '@/hooks/query-hooks/use-areas';
import { queryClient } from '@/libs/react-query';
import { clone } from 'lodash-es';
import { Area } from '@admissions-support/types';
import { CreatableSelect } from '../common/creatable-select';
import { useFormContext } from 'react-hook-form';
import { Alert } from '@/components/alert';

function AreasSection() {
  const form = useFormContext();

  const { mutateAsync: createArea, isPending: isCreatingArea } =
    useCreateAreaMutation({
      onSuccess: async (newArea: Area) => {
        const currentValue = clone(form.getValues('areas')) || [];

        const newValue = [
          ...currentValue,
          { label: newArea.name, value: newArea.id },
        ];

        form.setValue('areas', newValue);

        await queryClient.invalidateQueries({
          queryKey: areaKey.list(),
        });
      },
    });

  const {
    data: areas,
    isLoading: isAreasLoading,
    isSuccess: isAreasSuccess,
  } = useAreas();

  const onCreateArea = (newAreaName: string) => {
    const newArea = { name: newAreaName };

    createArea(newArea);
  };

  if (!isAreasLoading && !isAreasSuccess) {
    return (
      <Alert
        type="error"
        text="An unexpected error happened querying the areas."
        className="my-6"
      />
    );
  }

  const areaOptions = areas?.map(area => ({
    label: area.name,
    value: area.id,
  }));

  return (
    <div className="two-col-form">
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">Areas</p>
        <p className="text-md leading-6 text-gray-600">
          Do you want to group this location with other locations?
        </p>
      </div>
      <div className="col-span-2 space-y-6">
        <CreatableSelect
          name="areas"
          label="Areas"
          helperText="If an area doesn’t already exist you can create it by adding a new tag."
          options={areaOptions}
          isDisabled={isCreatingArea}
          onCreateOption={onCreateArea}
          isMulti
          isLoading={isAreasLoading}
        />
      </div>
    </div>
  );
}

export { AreasSection };
