import { areaKey } from '@/config/query-keys';
import { areaService } from '@/services/area.service';
import { HttpError } from '@/types/error';
import { Area, LocationProviderType } from '@admissions-support/types';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

function useAreas(
  filters?: { providerType: LocationProviderType },
  queryOptions?: Partial<UseQueryOptions<Area[], HttpError>>
) {
  return useQuery({
    queryKey: areaKey.list(filters?.providerType),
    queryFn: () => areaService.getAreas(filters?.providerType),
    ...queryOptions,
  });
}

export { useAreas };
