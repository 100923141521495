import { PermissionGuard } from '@/components/auth/permission-guard';
import { FallbackComponent } from '@/components/fallback-component';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { SchoolLocationStagesForm } from '@/components/locations/school-locations/school-location-stages.form';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolYear } from '@/context/school-year.context';
import { useSchoolLocation } from '@/hooks/query-hooks/use-school-location';
import { Permission } from '@/types/auth';
import {
  generatePath,
  Navigate,
  useNavigate,
  useParams,
} from 'react-router-dom';

function SchoolLocationStages() {
  const { schoolId = '' } = useParams();

  const { isLoading, data: location, isError } = useSchoolLocation(schoolId);

  const { schoolYear } = useSchoolYear();

  const navigate = useNavigate();

  const params = useParams<{ schoolId: string }>();

  const handleCancel = () => {
    navigate(staffRouterPath.LOCATIONS);
  };

  const handleEdit = () => {
    const redirectUrl = generatePath(
      `${staffRouterPath.EDIT_SCHOOL_LOCATION}`,
      {
        schoolId: params.schoolId || null,
      }
    );

    navigate(redirectUrl);
  };

  if (isLoading) {
    return (
      <>
        <PageTitle title="" variant="gray" className="border-none">
          <div className="flex h-[68px] gap-3" aria-hidden="true"></div>
        </PageTitle>
        <LocationTabNavigation id={schoolId} isSchoolLocation />
        <div className="flex justify-center py-12">
          <Spinner className="h-12 w-12" />
        </div>
      </>
    );
  }

  if (!location || isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  const currentSchoolStage = location.stages.find(
    stages => stages.schoolYear.id.toString() === schoolYear.id
  );

  if (!currentSchoolStage) {
    return <FallbackComponent />;
  }

  return (
    <>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-full sm:w-64" />}
      >
        <PermissionGuard requiredPermissions={[Permission['location:update']]}>
          <div className="flex gap-3">
            <button
              type="button"
              className="btn btn-empty"
              onClick={handleCancel}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleEdit}
              disabled={isLoading}
            >
              Edit Location
            </button>
            <button className="btn btn-primary" form="update-stages-form">
              Save Changes
            </button>
          </div>
        </PermissionGuard>
      </PageTitle>
      <LocationTabNavigation id={schoolId} isSchoolLocation />
      <SchoolLocationStagesForm schoolStages={currentSchoolStage} />
    </>
  );
}

export { SchoolLocationStages };
