import { useGenerateApplicationsCsv } from '@/hooks/use-generate-applications-csv';
import { FormProvider, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

type BulkExportApplicationsFormProps = {
  applicantIds: string[];
  onClose: () => void;
};

export function BulkExportApplicationsForm({
  applicantIds,
  onClose,
}: BulkExportApplicationsFormProps) {
  const form = useForm({
    defaultValues: {
      applicantIds: applicantIds,
      emailTemplateId: '',
    },
  });
  const { refetch: exportApplicationsCsv, isFetching: isExporting } =
    useGenerateApplicationsCsv(
      form.getValues().applicantIds.filter(Boolean) as string[]
    );

  const submit = async () => {
    const result = await exportApplicationsCsv();
    if (!result.isSuccess) {
      return;
    }

    const {
      URL: { createObjectURL, revokeObjectURL },
      setTimeout,
    } = window;

    const blob = new Blob([result.data], { type: 'text/csv' });
    const url = createObjectURL(blob);

    const anchor = document.createElement('a');
    anchor.setAttribute('href', url);
    anchor.setAttribute('download', `applications`);
    anchor.click();

    setTimeout(() => {
      revokeObjectURL(url);
    }, 100);
    onClose();
  };

  return (
    <FormProvider {...form}>
      <form id="bulk-placement-decision-form" className="flex flex-col gap-6">
        <div className="text-md font-semibold ">
          You are about to export {applicantIds?.length || 0} application(s)
        </div>

        <div className={twMerge('mt-6 grid grid-cols-1 gap-3 sm:grid-cols-2')}>
          <button
            disabled={isExporting}
            type="button"
            className="btn btn-secondary"
            onClick={close}
          >
            Cancel
          </button>

          <button
            type="button"
            disabled={isExporting}
            className="btn btn-primary"
            onClick={submit}
          >
            Export Applications
          </button>
        </div>
      </form>
    </FormProvider>
  );
}
