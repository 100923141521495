import { PermissionGuard } from '@/components/auth/permission-guard';
import { FallbackComponent } from '@/components/fallback-component';
import { FormColEmptyState } from '@/components/form-col-empty-state';
import { LocationTabNavigation } from '@/components/form/location-tab-navigation';
import { PurchaseExtraHoursNavigation } from '@/components/locations/purchase-extra-hours-navigation';
import { PageTitle } from '@/components/page-title';
import { SchoolYearFilter } from '@/components/school-year-filter';
import { Spinner } from '@/components/spinner';
import { locationKey } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useSchoolYear } from '@/context/school-year.context';
import { useCreateExtraHourResource } from '@/hooks/create-hooks/use-create-extra-hour-resorce';
import { useExtraHoursByLocation } from '@/hooks/query-hooks/use-extra-hours-by-location';
import { useLocation } from '@/hooks/query-hooks/use-location';
import { useLocationOperation } from '@/hooks/query-hooks/use-operation';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { useUpdateExtraHourResource } from '@/hooks/update-hooks/use-update-extra-hour-resource';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import {
  CreateExtraHourResourceDto,
  ExtraHourResource,
  UpdateExtraHourResourceDto,
} from '@admissions-support/types';
import { Plus } from '@untitled-ui/icons-react';
import { startOfHour } from 'date-fns';
import { useState } from 'react';
import {
  Navigate,
  generatePath,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ExtraHourResourceItem } from '../../../components/locations/extra-hours-availability/extra-hour-resource-item';
import { ExtraHourResourceForm } from '../../../components/locations/extra-hours-availability/extra-hour-resource.form';

function AvailabilityPage() {
  const navigate = useNavigate();

  const { locationId = '' } = useParams();

  const { schoolYear } = useSchoolYear();

  const [isCreateNewResourceOpen, setIsCreateNewResourceOpen] = useState(false);
  const [resourceToEdit, setResourceToEdit] = useState<
    ExtraHourResource | undefined
  >(undefined);

  const {
    isLoading: isLocationLoading,
    data: location,
    error: locationError,
  } = useLocation(locationId);

  const {
    isLoading: isOperationLoading,
    data: operation,
    isSuccess: isOperationSuccess,
  } = useLocationOperation(locationId);

  const { isLoading: isSessionTypesLoading, data: sessionTypes } =
    useSessionTypes();

  const {
    data: extraHours,
    isLoading: isExtraHoursLoading,
    error: extraHoursError,
  } = useExtraHoursByLocation(
    locationId,
    {
      schoolYearId: schoolYear.id,
      from: startOfHour(new Date()),
      to: new Date(schoolYear.endDate),
      isSessionQuery: true,
    },
    { enabled: Boolean(locationId) }
  );

  const { mutateAsync: create, isPending: isCreating } =
    useCreateExtraHourResource({
      onSuccess: async () => {
        setIsCreateNewResourceOpen(false);
        await invalidateResourceQueries();
      },
    });

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateExtraHourResource({
      onSuccess: async () => {
        setIsCreateNewResourceOpen(false);
        await invalidateResourceQueries();
      },
    });

  async function invalidateResourceQueries() {
    await queryClient.invalidateQueries({
      queryKey: locationKey.operation(locationId, schoolYear.id),
    });
  }

  const handleCancel = () => {
    navigate(staffRouterPath.LOCATIONS);
  };

  const handleEdit = () => {
    const redirectUrl = generatePath(staffRouterPath.EDIT_LOCATION, {
      locationId: locationId,
    });

    navigate(redirectUrl);
  };

  const isLoading =
    isLocationLoading ||
    isSessionTypesLoading ||
    isOperationLoading ||
    isExtraHoursLoading;

  const isError = !location || locationError || !extraHours || extraHoursError;

  if (isLoading) {
    return (
      <div>
        <PageTitle
          title="Loading..."
          variant="gray"
          className="border-none"
          description={<SchoolYearFilter className="-mt-1 w-64" />}
        >
          <PermissionGuard
            requiredPermissions={[Permission['location:update']]}
          >
            <div className="flex gap-3">
              <button
                type="button"
                className="btn btn-empty"
                onClick={handleCancel}
                disabled={isLocationLoading}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleEdit}
                disabled={isLocationLoading}
              >
                Edit Location
              </button>
            </div>
          </PermissionGuard>
        </PageTitle>
        <LocationTabNavigation id={locationId} />

        <div className="mt-6 flex items-center justify-between">
          <PurchaseExtraHoursNavigation />
          <button className="btn btn-secondary" type="button" disabled>
            Add Resource Group
          </button>
        </div>
        <Spinner className="m-auto mt-10" />
      </div>
    );
  }

  if (isError) {
    return <Navigate to={staffRouterPath.LOCATIONS} />;
  }

  if (!isOperationSuccess) {
    return <FallbackComponent />;
  }

  const hasSessionTypes =
    Array.isArray(sessionTypes) && sessionTypes.length > 0;

  const hasRatios =
    Array.isArray(operation.ratios) && operation.ratios.length > 0;

  const hasEnoughDataToDisplayForm = hasSessionTypes && hasRatios;

  return (
    <div>
      <PageTitle
        title={location.name}
        variant="gray"
        className="border-none"
        description={<SchoolYearFilter className="-mt-1 w-64" />}
      />
      <Tooltip
        place="top-end"
        id="capacity-tooltip"
        className="tooltip !z-50"
      />
      <LocationTabNavigation id={locationId} />

      <div className="mt-6 flex items-center justify-between">
        <PurchaseExtraHoursNavigation />
      </div>

      <div className="two-col-form">
        <div>
          <p className="text-md font-medium leading-7 text-gray-900">
            Resource Groups
          </p>
          <p className="text-md leading-6 text-gray-600">
            Specify location capacity for each ratio. This is used for defining
            the capacity for each product.
          </p>
        </div>
        <div className="col-span-2 space-y-4">
          <div className="space-y-4 divide-y">
            {!hasSessionTypes && (
              <FormColEmptyState
                title="No Session Types Found"
                btnText="Create Session Type"
                slug={staffRouterPath.NEW_SESSION_TYPE}
                description="Before you're able to define capacity for this location, please define some session types."
              />
            )}
            {!hasRatios && (
              <FormColEmptyState
                title="No Supported Ratio Found"
                btnText="Enable Ratio"
                slug={generatePath(staffRouterPath.CAPACITY, {
                  locationId,
                })}
                description="Before you're able to define resource for this location, please enable at least one ratio."
              />
            )}
          </div>
          {hasEnoughDataToDisplayForm && (
            <div className="mt-6 space-y-6">
              {isCreateNewResourceOpen ? (
                <ExtraHourResourceForm
                  sessionTypes={sessionTypes || []}
                  ratioCapacities={operation.ratios || []}
                  onClose={() => setIsCreateNewResourceOpen(false)}
                  onSubmit={(data: CreateExtraHourResourceDto) =>
                    create({
                      operationId: operation.id || '',
                      data,
                    })
                  }
                  isMutating={isCreating}
                  extraHours={extraHours}
                />
              ) : (
                <button
                  className="btn btn-secondary flex items-center"
                  type="button"
                  onClick={() => setIsCreateNewResourceOpen(true)}
                >
                  <Plus
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  Add Resource Group
                </button>
              )}

              {resourceToEdit && operation && sessionTypes && (
                <ExtraHourResourceForm
                  id={resourceToEdit.id}
                  operationId={operation.id}
                  sessionTypes={sessionTypes}
                  ratioCapacities={operation.ratios}
                  onSubmit={(data: UpdateExtraHourResourceDto) =>
                    update({
                      operationId: operation.id,
                      resourceId: resourceToEdit.id,
                      data,
                    })
                  }
                  onClose={() => setResourceToEdit(undefined)}
                  isMutating={isUpdating}
                  initialData={resourceToEdit}
                  extraHours={extraHours}
                />
              )}

              {operation.extraHoursResources.map(resource => {
                return (
                  <ExtraHourResourceItem
                    ratios={operation.ratios}
                    key={resource.id}
                    data={resource}
                    sessionTypes={sessionTypes}
                    onClick={() => setResourceToEdit(resource)}
                    extraHours={extraHours}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { AvailabilityPage };
