import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { Application, UpdateSchoolChoicesDto } from '@admissions-support/types';

import { HttpError } from '@/types/error';

import { applicationService } from '@/services/application.service';
import { applicationKey } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';

function useUpdateSchoolChoices(
  applicationId: string,
  mutationOptions?: UseMutationOptions<
    Application,
    HttpError,
    UpdateSchoolChoicesDto
  >
) {
  return useMutation({
    mutationKey:
      applicationKey.updateSchoolChoicesForApplication(applicationId),
    mutationFn: (data: UpdateSchoolChoicesDto) =>
      applicationService.updateSchoolChoices(applicationId, data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: applicationKey.single(applicationId),
        refetchType: 'active',
      });
    },
    ...mutationOptions,
  });
}

export { useUpdateSchoolChoices };
