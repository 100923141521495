import { ConditionGroup } from '@/components/reporting/reporting.type';
import { set, startCase } from 'lodash-es';

export const getAndConditions = (conditionGroups: ConditionGroup[]) => {
  const andConditions = conditionGroups
    .filter(group => group.variant === 'and')
    .flatMap(group => group.data)
    .reduce((acc, { type, value }) => {
      const formattedValue =
        value === 'true' ? true : value === 'false' ? false : value;
      set(acc, type, formattedValue);
      return acc;
    }, {});

  return andConditions;
};

export const getOrConditions = (conditionGroups: ConditionGroup[]) => {
  const orConditions = conditionGroups
    .filter(group => group.variant === 'or')
    .map(group =>
      group.data.reduce((acc: Record<string, any>[], { type, value }) => {
        const newObj = {};
        set(newObj, type, value);
        acc.push(newObj);
        return acc;
      }, [])
    );

  return orConditions;
};

export const applicationSessionFields = [
  'choices.sessions.monday',
  'choices.sessions.tuesday',
  'choices.sessions.wednesday',
  'choices.sessions.thursday',
  'choices.sessions.friday',
  'choices.sessions.saturday',
  'choices.sessions.sunday',
  'choices.additionalSessions.monday',
  'choices.additionalSessions.tuesday',
  'choices.additionalSessions.wednesday',
  'choices.additionalSessions.thursday',
  'choices.additionalSessions.friday',
  'choices.additionalSessions.saturday',
  'choices.additionalSessions.sunday',
  'choices.schedule.monday',
  'choices.schedule.tuesday',
  'choices.schedule.wednesday',
  'choices.schedule.thursday',
  'choices.schedule.friday',
  'choices.schedule.saturday',
  'choices.schedule.sunday',
  'choices.splitPlacement.sessions.monday',
  'choices.splitPlacement.sessions.tuesday',
  'choices.splitPlacement.sessions.wednesday',
  'choices.splitPlacement.sessions.thursday',
  'choices.splitPlacement.sessions.friday',
  'choices.splitPlacement.sessions.saturday',
  'choices.splitPlacement.sessions.sunday',
  'choices.splitPlacement.schedule.monday',
  'choices.splitPlacement.schedule.tuesday',
  'choices.splitPlacement.schedule.wednesday',
  'choices.splitPlacement.schedule.thursday',
  'choices.splitPlacement.schedule.friday',
  'choices.splitPlacement.schedule.saturday',
  'choices.splitPlacement.schedule.sunday',
];

export const exportableKeysApplications = [
  'isChildWithAdditionalNeeds',
  'additionalDetails',
  'notes',
  'homeLanguage',
  'homeStatus',
  'moveInDate',
  'isArchived',
  'status',
  'address.lineOne',
  'address.lineTwo',
  'address.lineThree',
  'address.lineFour',
  'address.postcode',
  'carers.firstName',
  'carers.lastName',
  'carers.email',
  'carers.mobile',
  'carers.address.lineOne',
  'carers.address.lineTwo',
  'carers.address.lineThree',
  'carers.address.lineFour',
  'carers.address.postcode',
  'carers.isPreferredContact',
  'carers.relationship',
  'siblings.firstName',
  'siblings.lastName',
  'siblings.dateOfBirth',
  'siblings.sex',
  'siblings.stage',
  'siblings.school.name',
  'medicalDetails.doctorName',
  'medicalDetails.healthVisitor',
  'medicalDetails.surgeryName',
  'medicalDetails.phoneNumber',
  'medicalDetails.isVisuallyImpaired',
  'medicalDetails.isEpileptic',
  'medicalDetails.isDiabetic',
  'medicalDetails.isAllergic',
  'medicalDetails.isHearingImpaired',
  'medicalDetails.isAsmatic',
  'medicalDetails.isSpeechImpaired',
  'medicalDetails.other',
  'medicalDetails.allergies',
  'lookedAfter.isSubjectToCourtDecision',
  'lookedAfter.careLocation',
  'lookedAfter.relationship',
  'lookedAfter.otherRelationship',
  'lookedAfter.authority',
  'choices.distanceToLocation',
  'choices.isAttendedBySibling',
  'choices.isInCouncilArea',
  'choices.location.name',
  'choices.nbPreference',
  'choices.score',
  'choices.sessions.monday',
  'choices.sessions.tuesday',
  'choices.sessions.wednesday',
  'choices.sessions.thursday',
  'choices.sessions.friday',
  'choices.sessions.saturday',
  'choices.sessions.sunday',
  'choices.additionalSessions.monday',
  'choices.additionalSessions.tuesday',
  'choices.additionalSessions.wednesday',
  'choices.additionalSessions.thursday',
  'choices.additionalSessions.friday',
  'choices.additionalSessions.saturday',
  'choices.additionalSessions.sunday',
  'choices.status',
  'choices.schedule.monday',
  'choices.schedule.tuesday',
  'choices.schedule.wednesday',
  'choices.schedule.thursday',
  'choices.schedule.friday',
  'choices.schedule.saturday',
  'choices.schedule.sunday',
  'choices.splitPlacement.sessions.monday',
  'choices.splitPlacement.sessions.tuesday',
  'choices.splitPlacement.sessions.wednesday',
  'choices.splitPlacement.sessions.thursday',
  'choices.splitPlacement.sessions.friday',
  'choices.splitPlacement.sessions.saturday',
  'choices.splitPlacement.sessions.sunday',
  'choices.splitPlacement.location.name',
  'choices.splitPlacement.additionalSessionLocation.name',
  'choices.splitPlacement.distanceToLocation',
  'choices.splitPlacement.isAttendedBySibling',
  'choices.splitPlacement.isInCouncilArea',
  'choices.splitPlacement.schedule.monday',
  'choices.splitPlacement.schedule.tuesday',
  'choices.splitPlacement.schedule.wednesday',
  'choices.splitPlacement.schedule.thursday',
  'choices.splitPlacement.schedule.friday',
  'choices.splitPlacement.schedule.saturday',
  'choices.splitPlacement.schedule.sunday',
  'child.firstName',
  'child.lastName',
  'child.owners.firstName',
  'child.owners.lastName',
  'organisation.name',
  'schoolYear.name',
  'cohort.name',
  'currentLocation.name',
  'currentLocationDescription',
  'dateOfBirth',
  'flagsAndOverrides.isFlagged',
  'flagsAndOverrides.hasDeferredChoice',
  'flagsAndOverrides.hasFlaggedChoice',
  'submittedAt',
  'createdAt',
  'updatedAt',
];

export const exportableKeysApplicationsLookup: Readonly<
  Record<string, string>
> = Object.freeze({
  isChildWithAdditionalNeeds: 'Is Child with Additional Needs',
  additionalDetails: 'Additional Details',
  notes: 'Notes',
  homeLanguage: 'Home Language',
  homeStatus: 'Home Status',
  moveInDate: 'Move-In Date',
  isArchived: 'Is Archived',
  status: 'Status',
  'address.lineOne': 'Address Line One',
  'address.lineTwo': 'Address Line Two',
  'address.lineThree': 'Address Line Three',
  'address.lineFour': 'Address Line Four',
  'address.postcode': 'Postcode',
  'carers.firstName': 'Carer First Name',
  'carers.lastName': 'Carer Last Name',
  'carers.email': 'Carer Email',
  'carers.mobile': 'Carer Mobile',
  'carers.address.lineOne': 'Carer Address Line One',
  'carers.address.lineTwo': 'Carer Address Line Two',
  'carers.address.lineThree': 'Carer Address Line Three',
  'carers.address.lineFour': 'Carer Address Line Four',
  'carers.address.postcode': 'Carer Postcode',
  'carers.isPreferredContact': 'Carer Is Preferred Contact',
  'carers.relationship': 'Carer Relationship',
  'siblings.firstName': 'Sibling First Name',
  'siblings.lastName': 'Sibling Last Name',
  'siblings.dateOfBirth': 'Sibling Date of Birth',
  'siblings.sex': 'Sibling Sex',
  'siblings.stage': 'Sibling Stage',
  'siblings.school.name': 'Sibling School Name',
  'medicalDetails.doctorName': 'Doctor Name',
  'medicalDetails.healthVisitor': 'Health Visitor',
  'medicalDetails.surgeryName': 'Surgery Name',
  'medicalDetails.phoneNumber': 'Phone Number',
  'medicalDetails.isVisuallyImpaired': 'Is Visually Impaired',
  'medicalDetails.isEpileptic': 'Is Epileptic',
  'medicalDetails.isDiabetic': 'Is Diabetic',
  'medicalDetails.isAllergic': 'Is Allergic',
  'medicalDetails.isHearingImpaired': 'Is Hearing Impaired',
  'medicalDetails.isAsmatic': 'Is Asthmatic',
  'medicalDetails.isSpeechImpaired': 'Is Speech Impaired',
  'medicalDetails.other': 'Other Medical Details',
  'medicalDetails.allergies': 'Allergies',
  'lookedAfter.isSubjectToCourtDecision': 'Is Subject to Court Decision',
  'lookedAfter.careLocation': 'Care Location',
  'lookedAfter.relationship': 'Looked After Relationship',
  'lookedAfter.otherRelationship': 'Other Relationship',
  'lookedAfter.authority': 'Looked After Authority',
  'choices.distanceToLocation': 'Distance to Location',
  'choices.isAttendedBySibling': 'Is Attended by Sibling',
  'choices.isInCouncilArea': 'Is in Council Area',
  'choices.location.name': 'Location Name',
  'choices.nbPreference': 'Preference Number',
  'choices.score': 'Score',
  'choices.status': 'Status',
  'choices.sessions.monday': 'Choices Sessions Monday',
  'choices.sessions.tuesday': 'Choices Sessions Tuesday',
  'choices.sessions.wednesday': 'Choices Sessions Wednesday',
  'choices.sessions.thursday': 'Choices Sessions Thursday',
  'choices.sessions.friday': 'Choices Sessions Friday',
  'choices.sessions.saturday': 'Choices Sessions Saturday',
  'choices.sessions.sunday': 'Choices Sessions Sunday',
  'choices.additionalSessions.monday': 'Choices Additional Sessions Monday',
  'choices.additionalSessions.tuesday': 'Choices Additional Sessions Tuesday',
  'choices.additionalSessions.wednesday':
    'Choices Additional Sessions Wednesday',
  'choices.additionalSessions.thursday': 'Choices Additional Sessions Thursday',
  'choices.additionalSessions.friday': 'Choices Additional Sessions Friday',
  'choices.additionalSessions.saturday': 'Choices Additional Sessions Saturday',
  'choices.additionalSessions.sunday': 'Choices Additional Sessions Sunday',
  'choices.schedule.monday': 'Choices Schedule Monday',
  'choices.schedule.tuesday': 'Choices Schedule Tuesday',
  'choices.schedule.wednesday': 'Choices Schedule Wednesday',
  'choices.schedule.thursday': 'Choices Schedule Thursday',
  'choices.schedule.friday': 'Choices Schedule Friday',
  'choices.schedule.saturday': 'Choices Schedule Saturday',
  'choices.schedule.sunday': 'Choices Schedule Sunday',
  'choices.splitPlacement.sessions.monday':
    'Choices Split Placement Sessions Monday',
  'choices.splitPlacement.sessions.tuesday':
    'Choices Split Placement Sessions Tuesday',
  'choices.splitPlacement.sessions.wednesday':
    'Choices Split Placement Sessions Wednesday',
  'choices.splitPlacement.sessions.thursday':
    'Choices Split Placement Sessions Thursday',
  'choices.splitPlacement.sessions.friday':
    'Choices Split Placement Sessions Friday',
  'choices.splitPlacement.sessions.saturday':
    'Choices Split Placement Sessions Saturday',
  'choices.splitPlacement.sessions.sunday':
    'Choices Split Placement Sessions Sunday',
  'choices.splitPlacement.location.name':
    'Choices Split Placement Location Name',
  'choices.splitPlacement.additionalSessionLocation.name':
    'Choices Split Placement Additional Session Location Name',
  'choices.splitPlacement.distanceToLocation':
    'Choices Split Placement Distance to Location',
  'choices.splitPlacement.isAttendedBySibling':
    'Choices Split Placement Is Attended By Sibling',
  'choices.splitPlacement.isInCouncilArea':
    'Choices Split Placement Is In Council Area',
  'choices.splitPlacement.schedule.monday':
    'Choices Split Placement Schedule Monday',
  'choices.splitPlacement.schedule.tuesday':
    'Choices Split Placement Schedule Tuesday',
  'choices.splitPlacement.schedule.wednesday':
    'Choices Split Placement Schedule Wednesday',
  'choices.splitPlacement.schedule.thursday':
    'Choices Split Placement Schedule Thursday',
  'choices.splitPlacement.schedule.friday':
    'Choices Split Placement Schedule Friday',
  'choices.splitPlacement.schedule.saturday':
    'Choices Split Placement Schedule Saturday',
  'choices.splitPlacement.schedule.sunday':
    'Choices Split Placement Schedule Sunday',
  'child.firstName': 'Child First Name',
  'child.lastName': 'Child Last Name',
  'child.owners.firstName': 'Child Owner First Name',
  'child.owners.lastName': 'Child Owner Last Name',
  'organisation.name': 'Organisation Name',
  'schoolYear.name': 'School Year',
  'cohort.name': 'Cohort Name',
  'currentLocation.name': 'Current Location Name',
  currentLocationDescription: 'Current Location Description',
  dateOfBirth: 'Date of Birth',
  'flagsAndOverrides.isFlagged': 'Is Flagged',
  'flagsAndOverrides.hasDeferredChoice': 'Has Deferred Choice',
  'flagsAndOverrides.hasFlaggedChoice': 'Has Flagged Choice',
  submittedAt: 'Submitted At',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
});

export const exportableKeysSchoolApplications = [
  'isChildWithAdditionalNeeds',
  'notes',
  'homeLanguage',
  'homeStatus',
  'moveInDate',
  'isArchived',
  'status',
  'address.lineOne',
  'address.lineTwo',
  'address.lineThree',
  'address.lineFour',
  'address.postcode',
  'carers.firstName',
  'carers.lastName',
  'carers.email',
  'carers.mobile',
  'carers.address.lineOne',
  'carers.address.lineTwo',
  'carers.address.lineThree',
  'carers.address.lineFour',
  'carers.address.postcode',
  'carers.isPreferredContact',
  'carers.relationship',
  'siblings.firstName',
  'siblings.lastName',
  'siblings.dateOfBirth',
  'siblings.sex',
  'siblings.stage',
  'siblings.school.name',
  'medicalDetails.doctorName',
  'medicalDetails.healthVisitor',
  'medicalDetails.surgeryName',
  'medicalDetails.phoneNumber',
  'medicalDetails.isVisuallyImpaired',
  'medicalDetails.isEpileptic',
  'medicalDetails.isDiabetic',
  'medicalDetails.isAllergic',
  'medicalDetails.isHearingImpaired',
  'medicalDetails.isAsmatic',
  'medicalDetails.isSpeechImpaired',
  'medicalDetails.other',
  'medicalDetails.allergies',
  'lookedAfter.isSubjectToCourtDecision',
  'lookedAfter.careLocation',
  'lookedAfter.relationship',
  'lookedAfter.otherRelationship',
  'lookedAfter.authority',
  'choices.distanceToLocation',
  'choices.isAttendedBySibling',
  'choices.isInCouncilArea',
  'choices.location.name',
  'choices.nbPreference',
  'choices.score',
  'choices.status',
  'child.firstName',
  'child.lastName',
  'child.owners.firstName',
  'child.owners.lastName',
  'organisation.name',
  'schoolYear.name',
  'dateOfBirth',
  'flagsAndOverrides.isFlagged',
  'flagsAndOverrides.hasDeferredChoice',
  'flagsAndOverrides.hasFlaggedChoice',
  'yearStage',
  'submittedAt',
  'createdAt',
  'updatedAt',
];

export const exportableKeysSchoolApplicationsLookup: Readonly<
  Record<string, string>
> = Object.freeze({
  ...exportableKeysSchoolApplications.reduce<FormattedKeys>((acc, key) => {
    acc[key] = startCase(key);
    return acc;
  }, {}),
});

export const exportableKeysBookings = [
  'child.name',
  'date',
  'location.name',
  'paymentStatus',
  'price',
  'productName',
  'status',
  'time',
];

export const exportableKeysBookingsLookup: Readonly<Record<string, string>> =
  Object.freeze({
    'child.name': 'Child Name',
    'location.name': 'Location Name',
    date: 'Date',
    paymentStatus: 'Payment Status',
    price: 'Price',
    productName: 'Product Name',
    status: 'Booking Status',
    time: 'Time',
  });

export const exportableKeysLocations = [
  'name',
  'type',
  'providerType',
  'serviceProviderNumber',
  'careInspectorNumber',
  'contactNumber',
  'website',
  'address.streetAddress',
  'address.streetAddressTwo',
  'address.city',
  'address.postcode',
  'keyContact.firstName',
  'keyContact.lastName',
  'keyContact.email',
  'notes',
  'status',
  'sessions.model.name',
  'sessions.times.monday.start',
  'sessions.times.monday.end',
  'sessions.times.tuesday.start',
  'sessions.times.tuesday.end',
  'sessions.times.wednesday.start',
  'sessions.times.wednesday.end',
  'sessions.times.thursday.start',
  'sessions.times.thursday.end',
  'sessions.times.friday.start',
  'sessions.times.friday.end',
  'sessions.times.saturday.start',
  'sessions.times.saturday.end',
  'sessions.times.sunday.start',
  'sessions.times.sunday.end',
  'sessions.type.name',
  'sessions.name',
  'createdAt',
  'updatedAt',
  'areas.name',
];

interface FormattedKeys {
  [key: string]: string;
}

export const exportableKeysLocationsLookup: Readonly<Record<string, string>> =
  Object.freeze({
    ...exportableKeysLocations.reduce<FormattedKeys>((acc, key) => {
      acc[key] = startCase(key);
      return acc;
    }, {}),
  });

export const exportableKeysSchoolLocations = [
  'name',
  'type',
  'providerType',
  'contactNumber',
  'website',
  'address.streetAddress',
  'address.streetAddressTwo',
  'address.city',
  'address.postcode',
  'keyContact.firstName',
  'keyContact.lastName',
  'keyContact.email',
  'notes',
  'status',
  'createdAt',
  'updatedAt',
  'feederSchools.name',
];

export const exportableKeysSchoolLocationsLookup: Readonly<
  Record<string, string>
> = {
  name: 'Name',
  type: 'Type',
  providerType: 'Provider Type',
  contactNumber: 'Contact Number',
  website: 'Website',
  'address.streetAddress': 'Address Street Address',
  'address.streetAddressTwo': 'Address Street Address Two',
  'address.city': 'Address City',
  'address.postCode': 'Address Post Code',
  'keyContact.firstName': 'Key Contact First Name',
  'keyContact.lastName': 'Key Contact Last Name',
  'keyContact.email': 'Key Contact Email',
  notes: 'Notes',
  status: 'Status',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  'feederSchools.name': 'Feeder Schools',
};
