import { staffRouterPath } from '@/config/route-paths.config';
import {
  AllApplications,
  UpdateApplicationDto,
} from '@admissions-support/types';
import { ArrowLeft, ArrowRight } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  HomeLanguage,
  Siblings,
} from '../application/houshold-details/houshold.element';
import { useHouseholdForm } from '../application/houshold-details/use-household.form';

type HouseholdFormProps = {
  onSubmit: (data: UpdateApplicationDto) => any;
  initialData: AllApplications;
};

function HouseholdForm(props: HouseholdFormProps) {
  const { initialData, onSubmit } = props;
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();

  const { form, handleSubmit, saveAsDraft } = useHouseholdForm({
    initialData,
    onSubmit,
  });

  const formDisabled = form.formState.isSubmitting;

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="mt-6"
        id="upsert-application-form"
      >
        <div className="two-col-form">
          <Siblings disabled={formDisabled} />
        </div>
        <div className="two-col-form">
          <HomeLanguage disabled={formDisabled} />
        </div>
      </form>

      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_CARER, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Return to Previous Section
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
        >
          Continue to Next Session
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5 text-white" />
        </button>
      </div>
    </FormProvider>
  );
}

export { HouseholdForm };
