import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  ApplicationProgressionResult,
  ProgressApplicationsDto,
} from '@admissions-support/types';
import { HttpError } from '@/types/error';
import { applicationKey } from '@/config/query-keys';
import { applicationService } from '@/services/application.service';

function useProgressApplicationsMutation(
  mutationOptions?: UseMutationOptions<
    ApplicationProgressionResult,
    HttpError,
    ProgressApplicationsDto
  >
) {
  return useMutation({
    mutationKey: applicationKey.progressApplications,
    mutationFn: applicationService.progressApplications,
    ...mutationOptions,
  });
}

export { useProgressApplicationsMutation };
