import { applicationKey, organisationKey } from '@/config/query-keys';
import { useSchoolYear } from '@/context/school-year.context';
import { queryClient } from '@/libs/react-query';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import { SchoolYear } from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';

let toastId: string;

function useResetMatching(
  mutationOptions?: UseMutationOptions<void, HttpError>
) {
  const { schoolYear, setSchoolYear } = useSchoolYear();

  return useMutation({
    mutationKey: applicationKey.resetMatching,
    mutationFn: () =>
      applicationService.resetMatching({
        schoolYearId: schoolYear.id,
      }),
    onMutate: () => {
      toastId = toast.loading('Resetting in progress... Please wait.');
    },
    onSuccess: async () => {
      toast.success('Application matching have been successfully reset.', {
        id: toastId,
      });
      await queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
      await queryClient.invalidateQueries({
        queryKey: organisationKey.terms,
      });

      const terms: SchoolYear[] =
        queryClient.getQueryData(organisationKey.terms) ?? [];
      const hasTerms = terms && Array.isArray(terms) && terms.length > 0;
      if (hasTerms) {
        const currentSchoolYear = terms.find(term => term.id === schoolYear.id);
        if (currentSchoolYear) {
          setSchoolYear(currentSchoolYear);
        }
      }
    },
    onError: () => {
      toast.error('Reset Failed. Please try again.');
    },
    ...mutationOptions,
  });
}

export { useResetMatching };
