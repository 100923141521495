import { getChoiceName } from '@/components/application-quick-view-drawer';
import { ApplicationStatusTag } from '@/components/application/application-status-tag';
import { AttendancePatternCell } from '@/components/application/attendance-pattern-cell';
import { ChoiceCell } from '@/components/application/choice-cell';
import { ExtraSessionCell } from '@/components/application/extra-session-cell';
import { Table } from '@/components/table';
import { Application, Choice } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Tooltip } from 'react-tooltip';
import { ChildChoiceStatusTag } from '../child-choice-status-tag';
import { SessionTypeCell } from '../session-type-cell';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';

type Props = {
  application: Application;
};

function ChildApplicationsTable(props: Props) {
  const { application } = props;

  const { data: applicationTemplate } = useApplicationTemplate(
    application.template.id.toString(),
    {
      enabled: !!application.template.id,
    }
  );
  const isNurseryApplication = applicationTemplate?.applicationType === 'ELC';

  const columnHelper = createColumnHelper<Choice>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('nbPreference', {
        id: 'preference',
        cell: info => {
          return (
            <span className="font-mono">
              {getChoiceName(info.row.original)}
            </span>
          );
        },
        header: () => <span>Choice</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => {
          const status = info.getValue();
          if (application.status === 'DRAFT') {
            return (
              <ApplicationStatusTag status="NOT_PLACED">
                Draft
              </ApplicationStatusTag>
            );
          }

          return (
            <ChildChoiceStatusTag
              status={status !== null ? status : undefined}
            />
          );
        },
        header: () => <span>Status</span>,
        enableSorting: false,
      }),
      columnHelper.accessor('location', {
        id: 'location',
        cell: info => {
          const choice = info.row.original;

          return <ChoiceCell choice={choice} doesHideTag />;
        },
        header: () => <span>Location</span>,
        enableSorting: false,
      }),
      ...(isNurseryApplication
        ? [
            columnHelper.accessor('sessions', {
              id: 'session_type',
              cell: info => {
                const choice = info.row.original;

                return (
                  <SessionTypeCell
                    schoolYearId={application.schoolYear.id.toString()}
                    choice={choice}
                  />
                );
              },
              header: () => <span>Session Type</span>,
              enableSorting: false,
              size: 192,
            }),
            columnHelper.accessor('sessions', {
              id: 'attendance_pattern',
              cell: info => {
                const choice = info.row.original;

                return <AttendancePatternCell choice={choice} />;
              },
              header: () => <span>Attendance Pattern</span>,
              enableSorting: false,
              size: 192,
            }),
            columnHelper.accessor('additionalSessions', {
              id: 'extra_session',
              cell: info => {
                const choice = info.row.original;

                return (
                  <ExtraSessionCell
                    choice={choice}
                    schoolYearId={application.schoolYear.id.toString()}
                  />
                );
              },
              header: () => <span>Extra Session</span>,
              size: 192,
            }),
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <Tooltip id="split-placement-tooltip" className="tooltip" place="top" />
      <Tooltip
        id="split-placement-attendance-pattern-tooltip"
        className="tooltip"
        place="top"
      />
      <Tooltip
        id="split-placement-extra-session-tooltip"
        className="tooltip"
        place="top"
      />
      <Table columns={columns} data={application.choices} className="mt-3" />
    </>
  );
}

export { ChildApplicationsTable };
