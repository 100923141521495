import { PermissionGuard } from '@/components/auth/permission-guard';
import { FormColEmptyState } from '@/components/form-col-empty-state';
import { TextInputBase } from '@/components/form/common/text-input';
import { LoadingScreen } from '@/components/loading-screen';
import { LocationsFilterRow } from '@/components/locations/locations-filter-row';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { Tag } from '@/components/tag';
import { staffRouterPath } from '@/config/route-paths.config';
import { useLocationControl } from '@/context/location-control.context';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { useMe } from '@/hooks/query-hooks/use-me';
import { useSessionTypes } from '@/hooks/query-hooks/use-session-types';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { formatLocationAddress, getTagType } from '@/utils/location-utils';
import { LocationListing } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Copy01,
  Eye,
  SearchLg,
  SearchRefraction,
} from '@untitled-ui/icons-react';
import { camelCase, capitalize } from 'lodash-es';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, Navigate, generatePath } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

function Locations() {
  const { isLoading, data: locations, error } = useLocations();
  const { filter } = useLocationControl();
  const { data: sessionTypes } = useSessionTypes();
  const [search, setSearch] = useState('');
  const {
    data: user,
    isSuccess: isMeSuccess,
    isLoading: isMeLoading,
  } = useMe();

  const columnHelper = createColumnHelper<LocationListing>();

  const handleCopyValue = async (locationId: string) => {
    try {
      if (!locationId) {
        return;
      }

      await navigator.clipboard.writeText(locationId);

      toast.success('ID copied to clipboard!');
    } catch (error) {
      toast.error('Error copying to clipboard!');
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        cell: info => {
          const addressString = formatLocationAddress(
            info.row.original.address
          );

          const locationId = info.row.original.id;

          return (
            <span className="block text-sm">
              <div className="flex items-center font-medium text-gray-900">
                {info.row.original.name}
                <button
                  type="button"
                  className="copy-location-id-tooltip ml-2"
                  onClick={() => handleCopyValue(locationId)}
                >
                  <Copy01 className="h-4 w-4" />
                </button>
              </div>
              {addressString}
            </span>
          );
        },
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor('type', {
        id: 'type',
        cell: info => {
          const type = info.getValue();

          if (type !== 'ELC') {
            return capitalize(type.toLowerCase());
          }

          return type;
        },
        header: () => <span>Location Type</span>,
        enableSorting: true,
        size: 176,
      }),
      columnHelper.accessor('status', {
        id: 'status',
        cell: info => (
          <Tag type={getTagType(info.getValue())} className="capitalize">
            {camelCase(info.getValue())}
          </Tag>
        ),
        header: () => <span>Status</span>,
        enableSorting: true,
        size: 120,
      }),
      columnHelper.display({
        id: 'id',
        cell: info => {
          const isSchoolLocation = info.row.original.type !== 'ELC';

          const linkHref = isSchoolLocation
            ? generatePath(staffRouterPath.OVERVIEW_SCHOOL_LOCATION, {
                schoolId: info.row.original.id,
              })
            : generatePath(staffRouterPath.OVERVIEW_LOCATION, {
                locationId: info.row.original.id,
              });
          return (
            <Link
              to={linkHref}
              className="edit-location-tooltip icon-link block"
            >
              <Eye className="h-5 w-5" viewBox="0 0 24 24" aria-hidden="true" />
              <span className="sr-only">View location</span>
            </Link>
          );
        },
        size: 72,
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (isMeLoading) {
    return <LoadingScreen />;
  }

  if (isMeSuccess) {
    // redirect user to their assigned location if they have only one location
    if (user.assignedLocationIds && user.assignedLocationIds.length === 1) {
      return (
        <Navigate to={`/locations/${user.assignedLocationIds[0]}/overview`} />
      );
    }
  }

  if (sessionTypes && sessionTypes.length < 1) {
    return (
      <>
        <PageTitle
          title="Locations"
          description="Manage locations and their capacity"
          variant="white"
        />

        <FormColEmptyState
          title="No Session Types Found"
          btnText="Create Session Type"
          slug={staffRouterPath.NEW_SESSION_TYPE}
          description="Before you're able to create a location, please define some session types."
        />
      </>
    );
  }

  const filteredLocations = locations?.filter(
    location =>
      location.name.toLowerCase().includes(search.toLowerCase()) &&
      (filter.types.length > 0
        ? filter.types.includes(location.type.toUpperCase())
        : true)
  );

  return (
    <div>
      <PageTitle
        title="Locations"
        description="Manage locations and their capacity"
        variant="white"
      >
        <PermissionGuard requiredPermissions={[Permission['location:create']]}>
          <Link to={staffRouterPath.NEW_LOCATION} className="btn btn-primary">
            New Location
          </Link>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        className="tooltip"
        place="top"
        anchorSelect=".copy-location-id-tooltip"
        content="Copy Location ID"
      />
      <Tooltip
        anchorSelect=".edit-location-tooltip"
        content="View Location"
        place="top"
        className="tooltip"
      />
      <div className="flex flex-col justify-between sm:flex-row sm:items-center">
        <LocationsFilterRow />
        <TextInputBase
          type="search"
          placeholder="Search by name"
          className="mt-4 md:ml-auto md:w-[320px]"
          defaultValue={search}
          onChange={e => setSearch(e.target.value)}
          LeadingIcon={
            <SearchLg className="h-4 w-4 text-gray-500" viewBox="0 0 24 24" />
          }
        />
      </div>
      <Table
        columns={columns}
        data={filteredLocations}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
        EmptyState={
          <div className="py-12 text-center">
            <SearchRefraction
              className="mx-auto h-8 w-8 text-gray-400"
              viewBox="0 0 24 24"
              aria-hidden="true"
            />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No Locations Found
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              You do not currently have any locations added. Please add your
              first location.
            </p>
          </div>
        }
      />
    </div>
  );
}

export { Locations };
