import {
  defaultApplicationControl,
  useApplicationControl,
} from '@/context/application-control.context';
import { useLocations } from '@/hooks/query-hooks/use-locations';
import { FilterLines, XClose } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { Drawer } from '../drawer';
import { CheckboxBase } from '../form/common/checkbox';
import { MultiSelectBase } from '../form/common/multi-select';
import { Tag } from '../tag';
import { BirthdayFilter } from './birthday-filter';
import { ChoiceStatusesFilter } from './choice-statuses-filter';
import { ChoicesFilter } from './choices-filter';
import { IntakeFilter } from './intake-filter';
import { LookedAfterFilter } from './looked-after-filter';
import { SiblingFilter } from './sibling-filter';
import { StatusFilter } from './status-filter';
import { CohortFilter } from './cohorts-filter';
import { LocationType } from '@admissions-support/types';
import { YearStageFilter } from './yearstage-filter';

type ApplicationsFilterDrawerProps = {
  applicationsType?: LocationType;
};

function ApplicationsFilterDrawer(props: ApplicationsFilterDrawerProps) {
  const { applicationsType } = props;
  const { filter, setFilter, type } = useApplicationControl();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: locationsData, isLoading } = useLocations({
    type: applicationsType ? applicationsType : undefined,
  });

  const handleDrawerClose = () => setIsOpen(false);
  const handleDrawerOpen = () => setIsOpen(true);

  const locations =
    locationsData?.map(location => ({
      label: location.name,
      value: location.id,
    })) || [];

  const handleLocationChange = (selectedLocations: string[]) => {
    setFilter(prevFilter => ({
      ...prevFilter,
      locations: selectedLocations,
      //set filter false if locations gonna be empty
      isSiblingAtLocation:
        prevFilter.locations.length === 1
          ? false
          : prevFilter.isSiblingAtLocation,
    }));
  };

  const handleSelectAllLocations = () => {
    if (filter.locations.length === locations.length) {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: defaultApplicationControl.filter.locations,
        isSiblingAtLocation: false,
      }));
    } else {
      setFilter(prevFilter => ({
        ...prevFilter,
        locations: locations.map(s => s.value),
      }));
    }
  };

  const handleFilterReset = () => {
    const resetedFilter = {
      ...defaultApplicationControl.filter,
      ...(type === 'nursery' ? { locations: filter.locations } : {}),
    };
    setFilter(resetedFilter);
  };

  const isSomeLocationsSelected = locations.some(location =>
    filter.locations.includes(location.value)
  );

  let filterCounter =
    filter.choices.length +
    filter.statuses.length +
    filter.choiceStatuses.length;

  // on location page, we have default filter for location, we want to exclude it
  if (type === 'main') {
    filterCounter += filter.locations.length;
  }

  if (filter.isLookedAfter) {
    filterCounter += 1;
  }

  if (filter.isSiblingAtLocation) {
    filterCounter += 1;
  }

  if (filter.baseDateOfBirth || filter.upperDateOfBirth) {
    filterCounter += 1;
  }

  return (
    <>
      <button
        className="btn btn-secondary btn-icon font-bold"
        onClick={handleDrawerOpen}
      >
        <FilterLines viewBox="0 0 24 24" className="leading-icon" />
        Filters{' '}
        {filterCounter > 0 && (
          <Tag type="default" className="ml-2 w-5 px-1 py-0 text-xs">
            {filterCounter}
          </Tag>
        )}
      </button>

      <Drawer open={isOpen} title="Filters" onClose={handleDrawerClose}>
        <div className="space-y-6">
          <div>
            <p className="mb-4 text-lg font-medium">Status</p>
            <div className="max-h-96 space-y-2 overflow-y-auto">
              <StatusFilter />
            </div>
          </div>
          <div>
            <p className="mb-4 text-lg font-medium">Choice</p>
            <div className="max-h-96 space-y-2 overflow-y-auto">
              <ChoicesFilter />
            </div>
          </div>

          {type.includes('main') && (
            <div>
              <p className="mb-4 text-lg font-medium">Location</p>

              <MultiSelectBase
                name="locations"
                label="Search"
                placeholder="Start typing.."
                options={locations}
                isLoading={isLoading}
                value={filter.locations}
                onChange={handleLocationChange}
              />
              <hr className="!mt-4 mb-2" />
              <CheckboxBase
                name="selectAllLocations"
                label="Select All"
                onChange={handleSelectAllLocations}
                checked={filter.locations.length === locations.length}
                indeterminate={isSomeLocationsSelected}
              />
            </div>
          )}
          {filter.locations.length > 0 && (
            <div>
              <SiblingFilter />
            </div>
          )}
          <div>
            <p className="mb-4 text-lg font-medium">Choice Status</p>
            <div className="max-h-96 space-y-2 overflow-y-auto">
              <ChoiceStatusesFilter />
            </div>
          </div>

          {!applicationsType || applicationsType === 'ELC' ? (
            <>
              <div>
                <p className="mb-4 text-lg font-medium">Intake</p>
                <div className="max-h-96 space-y-2 overflow-y-auto">
                  <IntakeFilter />
                </div>
              </div>
              <div>
                <p className="mb-4 text-lg font-medium">Cohort</p>
                <div className="max-h-96 space-y-2 overflow-y-auto">
                  <CohortFilter />
                </div>
              </div>
            </>
          ) : (
            <div>
              <p className="mb-4 text-lg font-medium">Year Stage</p>
              <div className="max-h-96 space-y-2 overflow-y-auto">
                <YearStageFilter applicationsType={applicationsType} />
              </div>
            </div>
          )}
          <div>
            <p className="mb-4 text-lg font-medium">Looked After Status</p>
            <LookedAfterFilter />
          </div>
          <div>
            <p className="mb-4 text-lg font-medium">Child DoB</p>
            <BirthdayFilter />
          </div>
          <button
            className="btn btn-secondary btn-icon ml-auto"
            onClick={handleFilterReset}
          >
            <XClose viewBox="0 0 24 24" className="leading-icon" /> Reset All
            Filters
          </button>
        </div>
      </Drawer>
    </>
  );
}

export { ApplicationsFilterDrawer };
