import { Box } from '@/components/box';
import { DateInput } from '@/components/form/common/date-input';
import { TextInput } from '@/components/form/common/text-input';
import { useFormContext } from 'react-hook-form';

type SubjectItem = {
  name: string;
  courseLevel: string;
  dateFrom: string;
  dateTo: string;
};

type SubjectItemProps = SubjectItem & {
  index: number;
  onRemove: (index: number) => void;
};

function SubjectItem({ index, onRemove }: SubjectItemProps) {
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-gray-200 bg-gray-50 p-4">
      <div className="flex items-center justify-between">
        <p className="font-medium text-gray-900">Subject {index + 1}</p>
        {index !== 0 ? (
          <button
            className="btn btn-error btn-sm"
            onClick={() => onRemove(index)}
          >
            Remove
          </button>
        ) : null}
      </div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
        <TextInput
          label="Name"
          name={`subjectDetails.${index}.name`}
          required
        />
        <TextInput
          label="Course Level"
          name={`subjectDetails.${index}.courseLevel`}
          required
        />
        <DateInput
          label="From"
          name={`subjectDetails.${index}.fromDate`}
          type="date"
          required
        />
        <DateInput
          label="To"
          name={`subjectDetails.${index}.toDate`}
          type="date"
          required
        />
      </div>
    </div>
  );
}

function ParentSubjectDetailsForm() {
  const { setValue, getValues, watch } = useFormContext();

  const addSubject = () => {
    const formValues = getValues();
    const subjectDetails = formValues.subjectDetails || [];
    setValue('subjectDetails', [...subjectDetails, {}]);
  };

  const removeSubject = (index: number) => {
    const formValues = getValues();
    const subjectDetails = formValues.subjectDetails || [];
    subjectDetails.splice(index, 1);
    setValue('subjectDetails', subjectDetails);
  };

  const subjects = watch('subjectDetails') || [{}];

  return (
    <Box>
      <div>
        <p className="text-md font-medium leading-7 text-gray-900">
          Subject Details
        </p>
        <p className="text-md leading-6 text-gray-600">
          Where applicable, please provide details of subjects your child has
          studied. Please provide the name, level and dates.
        </p>
      </div>
      <div className="mt-8 flex flex-col gap-4">
        {subjects?.length === 0 ? (
          <SubjectItem
            key={0}
            index={0}
            name={''}
            courseLevel={''}
            dateFrom={''}
            dateTo={''}
            onRemove={removeSubject}
          />
        ) : null}

        {subjects?.map((subject: SubjectItem, index: number) => (
          <SubjectItem
            key={index}
            index={index}
            name={subject.name}
            courseLevel={subject.courseLevel}
            dateFrom={subject.dateFrom}
            dateTo={subject.dateTo}
            onRemove={removeSubject}
          />
        ))}
      </div>
      <button
        className="btn btn-secondary mt-6"
        onClick={addSubject}
        type="button"
      >
        Add Subject
      </button>
    </Box>
  );
}

export { ParentSubjectDetailsForm };
