import { Spinner } from '@/components/spinner';
import { parentRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import { useUpdateApplicationMutation } from '@/hooks/update-hooks/use-update-application-mutation';
import {
  PrimarySchoolApplicationYearStage,
  SecondarySchoolApplicationYearStage,
} from '@admissions-support/types';
import { ArrowRight } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

const yearStageOptions: Record<
  'PRIMARY' | 'SECONDARY',
  { label: string; value: string }[]
> = {
  PRIMARY: [
    {
      label: 'Primary 1',
      value: 'primaryOne',
    },
    {
      label: 'Primary 2',
      value: 'primaryTwo',
    },
    {
      label: 'Primary 3',
      value: 'primaryThree',
    },
    {
      label: 'Primary 4',
      value: 'primaryFour',
    },
    {
      label: 'Primary 5',
      value: 'primaryFive',
    },
    {
      label: 'Primary 6',
      value: 'primarySix',
    },
    {
      label: 'Primary 7',
      value: 'primarySeven',
    },
  ],
  SECONDARY: [
    {
      label: 'Secondary 1',
      value: 'secondaryOne',
    },
    {
      label: 'Secondary 2',
      value: 'secondaryTwo',
    },
    {
      label: 'Secondary 3',
      value: 'secondaryThree',
    },
    {
      label: 'Secondary 4',
      value: 'secondaryFour',
    },
    {
      label: 'Secondary 5',
      value: 'secondaryFive',
    },
    {
      label: 'Secondary 6',
      value: 'secondarySix',
    },
  ],
};

const applicationTypeToTitle: Record<'PRIMARY' | 'SECONDARY', string> = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
};

function ApplicationYearStageSelector() {
  const { id } = useParams<{ id: string }>();
  const { data: application, isLoading: isApplicationLoading } = useApplication(
    id,
    {
      enabled: !!id,
    }
  );

  const { data: applicationTemplate, isLoading: isApplicationTemplateLoading } =
    useApplicationTemplate(application?.template?.id?.toString() || '', {
      enabled: !!application?.template?.id,
    });

  const isLoading = isApplicationLoading || isApplicationTemplateLoading;

  const options =
    yearStageOptions[
      applicationTemplate?.applicationType as 'PRIMARY' | 'SECONDARY'
    ] || [];

  const [yearStage, setYearStage] = useState<
    | PrimarySchoolApplicationYearStage
    | SecondarySchoolApplicationYearStage
    | null
  >(null);

  const { mutateAsync: updateApplication } = useUpdateApplicationMutation(
    id || ''
  );

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!yearStage) {
      return;
    }

    await updateApplication({
      // @ts-expect-error
      yearStage: yearStage as
        | PrimarySchoolApplicationYearStage
        | SecondarySchoolApplicationYearStage,
    });

    navigate(
      generatePath(parentRouterPath.APPLICATION_CHILD_DETAILS, {
        id: id || '',
      })
    );
  };

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 flex h-full min-h-20 flex-col items-center justify-center">
          <Spinner />
          <span className="mt-4 text-sm text-gray-700">Loading...</span>
        </div>
      ) : (
        <>
          <div>
            <h1 className="mb-8 mt-6 text-xl font-semibold text-gray-900">
              {
                applicationTypeToTitle[
                  applicationTemplate?.applicationType as
                    | 'PRIMARY'
                    | 'SECONDARY'
                ]
              }{' '}
              School Application
            </h1>
          </div>
          <div className="flex flex-col gap-6 rounded-md border border-solid border-gray-200 px-6 py-3">
            <span className="text-sm font-semibold text-gray-700">
              Select year stage applying for
            </span>
            <div className="flex flex-col gap-4">
              {options.map(option => (
                <label
                  key={option.value}
                  className="flex cursor-pointer items-center gap-2"
                >
                  <input
                    type="radio"
                    name="yearStage"
                    value={option.value}
                    checked={yearStage === option.value}
                    onChange={e => setYearStage(e.target.value as any)}
                    className="h-4 w-4 text-blue-600"
                  />
                  <span className="text-sm text-gray-700">{option.label}</span>
                </label>
              ))}
            </div>
            <div>
              <button
                className="btn btn-primary flex items-center gap-2"
                type="button"
                disabled={!yearStage}
                onClick={handleSubmit}
              >
                Begin Application
                <ArrowRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export { ApplicationYearStageSelector };
