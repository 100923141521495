import { CreatableSelect } from '@/components/form/common/creatable-select';
import { Textarea } from '@/components/form/common/textarea';
import { useOrganisationData } from '@/context/organisation-control.context';
import { useFormContext } from 'react-hook-form';

type PreSchoolProps = {
  locations: { key: string; value: string }[];
  disabled?: boolean;
};

function PreSchool(props: PreSchoolProps) {
  const { disabled, locations: locationsData } = props;

  const { data: organisation } = useOrganisationData();

  const { watch, setValue } = useFormContext();

  const selectedLocation = watch('currentLocationId');

  const locations =
    locationsData?.map(location => ({
      label: location.value,
      value: location.key,
    })) || [];

  const currentLabel = locations.find(
    location => location.value === selectedLocation
  );

  return (
    <>
      <div>
        <p className="text-md leading-6 text-gray-600">
          Please give details of any other type of pre-school your child goes to
          or has gone to.
        </p>
      </div>
      <div className="space-y-4">
        <CreatableSelect
          label="Current Nursery attended, if applicable"
          placeholder="Start typing nursery name.."
          name="currentLocationId"
          value={{ value: selectedLocation, label: currentLabel?.label || '' }}
          onChange={option => {
            //@ts-ignore--theres ts missmatch from the component
            setValue('currentLocationId', option ? option.value : '');
          }}
          isClearable
          options={[{ label: 'Nursery', options: locations }]}
          helperText={`List only shows locations within ${organisation.name}`}
        />

        <Textarea
          name="currentLocationDescription"
          label="Location not listed? Provide details."
          disabled={disabled}
          placeholder="Enter details of other pre-school your child has attended"
          rows={3}
        />
      </div>
    </>
  );
}

export { PreSchool };
