import { axiosClient } from '@/libs/axios';
import { HttpError } from '@/types/error';
import {
  SchoolLocationListItem,
  CreateSchoolLocationDto,
  SchoolLocation,
  UpdateSchoolLocationDto,
} from '@admissions-support/types';
import { AxiosResponse } from 'axios';

const createSchoolLocation = async (
  data: CreateSchoolLocationDto
): Promise<SchoolLocation> => {
  const response = await axiosClient.post<
    HttpError,
    AxiosResponse<SchoolLocation>,
    CreateSchoolLocationDto
  >('/school-locations', data);

  return response.data;
};

const list = async (): Promise<SchoolLocationListItem[]> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<SchoolLocationListItem[]>
  >('/school-locations/list');

  return response.data;
};

const updateSchoolLocation = async ({
  id,
  data,
}: {
  id: string;
  data: UpdateSchoolLocationDto;
}): Promise<SchoolLocation> => {
  const response = await axiosClient.patch<
    HttpError,
    AxiosResponse<SchoolLocation>,
    UpdateSchoolLocationDto
  >(`/school-locations/${id}`, data);

  return response.data;
};

const getSchoolLocation = async (id: string): Promise<SchoolLocation> => {
  const response = await axiosClient.get<
    HttpError,
    AxiosResponse<SchoolLocation>
  >(`/school-locations/${id}`);

  return response.data;
};

const deleteSchoolLocation = async (id: string) => {
  await axiosClient.delete(`/school-locations/${id}`);
};

export const schoolLocationService = Object.freeze({
  createSchoolLocation,
  updateSchoolLocation,
  getSchoolLocation,
  deleteSchoolLocation,
  list,
});
