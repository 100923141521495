import { Box } from '@/components/box';
import PrimarySecondaryPlacementPreferences from '../primary-secondary-placement-preferences';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BaseSyntheticEvent, useRef } from 'react';
import * as yup from 'yup';
import {
  AllApplications,
  SchoolLocationListItem,
  SchoolLocationType,
  UpdateSchoolChoicesDto,
} from '@admissions-support/types';
import { ArrowRight } from '@untitled-ui/icons-react';
import { ArrowLeft } from '@untitled-ui/icons-react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { staffRouterPath } from '@/config/route-paths.config';
import { useUpdateSchoolChoices } from '@/hooks/update-hooks/use-update-school-choices';
import { applicationKey } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';
import { InferType } from 'yup';

export const schoolPlacementPreferencesSchema = yup.object({
  choices: yup
    .array()
    .of(
      yup.object({
        value: yup.string().required(),
        label: yup.string().required(),
        type: yup.string().required(),
        distanceToLocation: yup.number().optional(),
      })
    )
    .required(),
});

/**
 * Convert the form values to the update school choices dto
 * @param values - The form values
 * @returns The update school choices dto
 */
const convertFormValuesToUpdateSchoolChoicesDto = (
  values: InferType<typeof schoolPlacementPreferencesSchema>
): UpdateSchoolChoicesDto => {
  const filledChoices = values?.choices.filter(choice => !!choice?.value);

  return {
    choices: filledChoices.map((choice: any, index: number) => ({
      schoolId: choice.value,
      nbPreference: index + 1,
      ...(choice.distanceToLocation &&
        choice.distanceToLocation > 0 && {
          distanceToLocation: parseInt(choice.distanceToLocation),
        }),
    })),
  };
};

const convertInitialDataToFormValues = (
  initialData: AllApplications,
  schoolLocations: SchoolLocationListItem[] = []
): InferType<typeof schoolPlacementPreferencesSchema> => {
  return {
    choices:
      initialData?.choices?.map(choice => ({
        value: choice.location.id?.toString(),
        label: choice.location.name,
        type: schoolLocations.find(
          loc => loc?.id === choice?.location?.id?.toString()
        )?.type as SchoolLocationType,
        ...(choice.distanceToLocation &&
          choice.distanceToLocation > 0 && {
            distanceToLocation: choice.distanceToLocation,
          }),
      })) || [],
  };
};

type SchoolPlacementPreferencesFormProps = {
  initialData: AllApplications;
  schoolType: 'PRIMARY' | 'SECONDARY';
  schoolLocations: SchoolLocationListItem[];
};

function SchoolPlacementPreferencesForm(
  props: SchoolPlacementPreferencesFormProps
) {
  const params = useParams();
  const { initialData, schoolType, schoolLocations } = props;
  const isDraft = useRef(true);
  const defaultResolver = yupResolver(schoolPlacementPreferencesSchema);

  const defaultValues: InferType<typeof schoolPlacementPreferencesSchema> =
    convertInitialDataToFormValues(initialData, schoolLocations);

  const form = useForm({
    resolver: (data, context, options) => {
      if (isDraft.current) {
        // if its draft no validation required
        return { errors: {}, values: data };
      }
      return defaultResolver(data, context, options);
    },
    defaultValues,
  });

  const { mutate: updateSchoolChoices } = useUpdateSchoolChoices(
    initialData.id,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: applicationKey.single(initialData.id),
        });
      },
    }
  );

  const navigate = useNavigate();

  // TODO: implement save as draft
  const saveAsDraft = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const values = form.getValues();
    const updateSchoolChoicesDto =
      convertFormValuesToUpdateSchoolChoicesDto(values);
    await updateSchoolChoices(updateSchoolChoicesDto);
  };

  const handleSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const values = form.getValues();
    const updateSchoolChoicesDto =
      convertFormValuesToUpdateSchoolChoicesDto(values);
    await updateSchoolChoices(updateSchoolChoicesDto);
    navigate(
      generatePath(staffRouterPath.APPLICATION_ADDITIONAL_DETAILS, {
        id: params.id || '',
      })
    );
  };

  const data = form.watch();
  const isThereAnyData = Object.values(data).some(Boolean);

  return (
    <FormProvider {...form}>
      <form
        onSubmit={saveAsDraft}
        className="space-y-4"
        id="upsert-application-form"
      >
        <div>
          <Box className="space-y-4">
            <PrimarySecondaryPlacementPreferences schoolType={schoolType} />
          </Box>
        </div>
      </form>
      <div className="mt-6 flex flex-col space-y-4 sm:flex-row sm:justify-between sm:space-y-0">
        <button
          className="btn btn-secondary flex items-center"
          type="button"
          onClick={() =>
            navigate(
              generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
                id: params.id || '',
              })
            )
          }
        >
          <ArrowLeft
            viewBox="0 0 24 24"
            className="mr-2 h-5 w-5 text-gray-500"
          />
          Previous
        </button>
        <button
          className="btn btn-primary flex items-center"
          type="button"
          onClick={handleSubmit}
          disabled={initialData.choices?.length < 1 && !isThereAnyData}
        >
          Continue
          <ArrowRight viewBox="0 0 24 24" className="ml-2 h-5 w-5" />
        </button>
      </div>
    </FormProvider>
  );
}

export { SchoolPlacementPreferencesForm };
