import { Alert } from '@/components/alert';
import { TextInput } from '@/components/form/common/text-input';
import { HttpError, isBadRequest } from '@/types/error';
import { formatDateToYYYYMMDD } from '@/utils/format-date';
import { getNestedKeys } from '@/utils/get-nested-keys';
import {
  CreateSchoolYearDto,
  SchoolYear,
  UpdateSchoolYearDto,
} from '@admissions-support/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { CohortsForm } from './cohorts.form';
import { IntakesForm } from './intakes.form';
import { schoolYearSchema } from './school-year.schema';
import { SchoolYearFormData } from './school-year.type';

type SchoolYearFormProps =
  | {
      onSubmit: (data: CreateSchoolYearDto) => Promise<void>;
      initialData?: never;
      isLoading?: boolean;
    }
  | {
      onSubmit: (data: UpdateSchoolYearDto) => Promise<void>;
      initialData?: SchoolYear;
      isLoading?: boolean;
    };

function transformApiDataToFormData(
  initialData?: SchoolYear
): SchoolYearFormData {
  if (!initialData) {
    return {
      name: '',
      startDate: '',
      endDate: '',
      cohorts: [],
      selectedCohortIds: [],
      intakes: [
        {
          name: '',
          startDate: '',
          endDate: '',
          deadline: '',
          cohortEligibility: [],
        },
      ],
    };
  }

  return {
    name: initialData.name,
    startDate: formatDateToYYYYMMDD(initialData.startDate),
    endDate: formatDateToYYYYMMDD(initialData.endDate),
    cohorts: initialData.cohorts.map(cohortItem => ({
      cohortId: cohortItem.cohort.id.toString(),
      cohortName: cohortItem.cohort.name,
      startDate: formatDateToYYYYMMDD(cohortItem.startDate),
      endDate: formatDateToYYYYMMDD(cohortItem.endDate),
    })),
    selectedCohortIds: initialData.cohorts.map(cohortItem =>
      cohortItem.cohort.id.toString()
    ),
    intakes: initialData.intakes.map(intake => ({
      id: intake.id,
      name: intake.name,
      startDate: formatDateToYYYYMMDD(intake.start),
      endDate: formatDateToYYYYMMDD(intake.end),
      deadline: formatDateToYYYYMMDD(intake.deadline),
      cohortEligibility: intake.cohortEligibility.map(cohortEligibility => ({
        cohortId: cohortEligibility.cohort.id.toString(),
        startDate: cohortEligibility.eligibleFrom
          ? formatDateToYYYYMMDD(cohortEligibility.eligibleFrom)
          : '',
        endDate: cohortEligibility.eligibleTo
          ? formatDateToYYYYMMDD(cohortEligibility.eligibleTo)
          : '',
        isFromChildsBirthday: cohortEligibility.isEligibleFromChildsBirthday,
      })),
    })),
  };
}

function SchoolYearForm(props: SchoolYearFormProps) {
  const { initialData, isLoading, onSubmit } = props;
  const isEditTermView = Boolean(initialData);

  const defaultValues = transformApiDataToFormData(initialData);

  const form = useForm({
    resolver: yupResolver(schoolYearSchema),
    defaultValues: defaultValues,
  });

  const submitHandler = async (data: SchoolYearFormData) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { selectedCohortIds: _, intakes, ...rest } = data;

      const convertedData: CreateSchoolYearDto = {
        ...rest,
        startDate: new Date(data.startDate),
        endDate: new Date(data.endDate),
        cohorts: data.cohorts.map(cohort => ({
          id: cohort.cohortId,
          name: cohort.cohortName,
          start: cohort.startDate || '',
          end: cohort.endDate || '',
        })),
        intakes: intakes.map(intake => ({
          id: intake.id,
          name: intake.name,
          start: intake.startDate,
          end: intake.endDate,
          deadline: intake.deadline,
          cohortEligibility: intake.cohortEligibility.map(
            cohortEligibilityItem => ({
              cohortId: cohortEligibilityItem.cohortId,
              eligibleFrom:
                cohortEligibilityItem.startDate === ''
                  ? null
                  : cohortEligibilityItem.startDate,
              eligibleTo:
                cohortEligibilityItem.endDate === ''
                  ? null
                  : cohortEligibilityItem.endDate,
              isEligibleFromChildsBirthday:
                cohortEligibilityItem.isFromChildsBirthday,
            })
          ),
        })),
      };

      await onSubmit(convertedData);
    } catch (error) {
      const httpError = error as HttpError;

      if (isBadRequest(httpError)) {
        if (!form.formState.defaultValues) {
          return;
        }

        if (httpError.error === 'organisations-teams/invalid-start-end-date') {
          //@ts-ignore: known bug, wrong error message format from the backend
          form.setError('startDate', { message: httpError.message });
          return;
        }

        const availableFields = getNestedKeys(form.formState.defaultValues);
        availableFields.forEach(field => {
          if (!Array.isArray(httpError.message)) {
            if (!httpError.message.includes(field)) {
              return;
            }

            const formatedMsg = httpError.message.replace(field, 'Field');
            form.setError(field, { message: formatedMsg });
            return;
          }

          httpError.message.map(msg => {
            if (msg.includes(field)) {
              const formatedMsg = msg.replace(field, 'Field');
              form.setError(field, { message: formatedMsg });
            }
          });
        });
      }
    }
  };

  return (
    <FormProvider {...form}>
      <form
        onSubmit={
          //@ts-ignore
          form.handleSubmit(submitHandler)
        }
        className="flex h-full flex-1 flex-col"
        id="upsert-term-form"
      >
        <div className="mt-6 bg-white pt-6">
          <div className="two-col-form pt-0">
            <div>
              <h2 className="text-md font-medium leading-7 text-gray-900">
                School Year
              </h2>
            </div>

            <div className="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <div className="space-y-6">
                  {isEditTermView && (
                    <Alert
                      type="warning"
                      text={
                        <>
                          <p className="mb-1 font-medium">Attention!</p>
                          <p>
                            Updating or deleting a School Year will affect all{' '}
                            <b>Locations and Applications</b> that use this
                            School Year.
                          </p>
                        </>
                      }
                    />
                  )}
                  <TextInput
                    name="name"
                    type="text"
                    label="School Year Name*"
                    placeholder="Summer 23"
                    disabled={isLoading}
                  />
                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
                    <TextInput
                      name="startDate"
                      type="date"
                      label="Start Date*"
                      disabled={isLoading}
                    />
                    <TextInput
                      name="endDate"
                      type="date"
                      label="End Date*"
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 bg-white pt-6">
          <CohortsForm />
        </div>
        <div className="mt-6 bg-white pt-6">
          <IntakesForm />
        </div>
      </form>
    </FormProvider>
  );
}

export { SchoolYearForm };
