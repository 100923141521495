import { applicationKey, childKey, logKey } from '@/config/query-keys';
import { queryClient } from '@/libs/react-query';
import { applicationService } from '@/services/application.service';
import { HttpError } from '@/types/error';
import {
  AllApplications,
  UpdateApplicationDtos,
} from '@admissions-support/types';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

function useUpdateApplicationMutation(
  id: string,
  mutationOptions?: UseMutationOptions<
    AllApplications,
    HttpError,
    UpdateApplicationDtos
  >
) {
  return useMutation({
    mutationKey: applicationKey.updateApplication,
    mutationFn: data =>
      applicationService.updateApplication({ applicationId: id, data }),
    onSuccess: updatedApplication => {
      queryClient.setQueryData(applicationKey.single(id), updatedApplication);
      queryClient.invalidateQueries({
        queryKey: ['application'],
      });
      queryClient.invalidateQueries({
        queryKey: ['application-list'],
      });
      queryClient.invalidateQueries({
        queryKey: logKey.single(updatedApplication.id),
      });
      queryClient.invalidateQueries({
        queryKey: childKey.single(updatedApplication.child.id.toString()),
      });
    },
    ...mutationOptions,
  });
}

export { useUpdateApplicationMutation };
