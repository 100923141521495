import { staffRouterPath } from '@/config/route-paths.config';
import { useApplication } from '@/hooks/query-hooks/use-application';
import { NavigationItem } from '@/types/navigation';
import { isAdminDashboard } from '@/utils/is-dashboard';
import * as Sentry from '@sentry/react';
import {
  AnnotationInfo,
  FaceContent,
  FileQuestion02,
  GraduationHat02,
  Home02,
  User01,
} from '@untitled-ui/icons-react';
import { generatePath, matchPath, useParams } from 'react-router-dom';
import {
  ApplicationNavigation,
  isAdditionalDetailsDisabled,
  isHouseholdDetailsDisabled,
  isParentCarerDisabled,
  isPlacementPreferencesDisabled,
} from '../application-navigation';
import { FallbackComponent } from '../fallback-component';
import { MobileNavigation } from '../mobile-navigation';
import { CookieBanner } from '../cookie-banner';
import { useApplicationTemplate } from '@/hooks/query-hooks/use-application-template';
import {
  PrimarySchoolApplication,
  SecondarySchoolApplication,
} from '@admissions-support/types';

type ApplicationLayoutProps = {
  children?: React.ReactNode;
};

function ApplicationLayout(props: ApplicationLayoutProps) {
  const { children } = props;

  const params = useParams<{ id?: string; childId?: string }>();
  const isNewApplication = Boolean(
    matchPath(staffRouterPath.NEW_APPLICATION, location.pathname)
  );
  const { data: application } = useApplication(params.id || '', {
    enabled: Boolean(params.id),
  });

  const { data: applicationTemplate } = useApplicationTemplate(
    application?.template?.id?.toString() || '',
    {
      enabled: !!application?.template?.id,
    }
  );

  const isNurseryApplication = applicationTemplate?.applicationType === 'ELC';
  const isAdmin = isAdminDashboard();

  const showSelectSchoolYearStage =
    isAdmin &&
    !isNurseryApplication &&
    // Casting is safe because we have already checked for isNurseryApplication
    !(application as PrimarySchoolApplication | SecondarySchoolApplication)
      ?.yearStage;
  const showEligibility = isAdmin && !application;

  const navigation: NavigationItem[] = [
    {
      name: 'Child Details',
      href: params.id
        ? generatePath(staffRouterPath.APPLICATION_CHILD_DETAILS, {
            id: params.id,
          })
        : staffRouterPath.NEW_APPLICATION,
      icon: FaceContent,
    },
    ...(showEligibility
      ? [
          {
            name: 'Eligibility',
            href: params.childId
              ? generatePath(staffRouterPath.NEW_APPLICATION_ELIGIBILITY, {
                  childId: params.childId,
                })
              : staffRouterPath.NEW_APPLICATION_ELIGIBILITY,
            icon: FileQuestion02,
            disabled: !params.childId,
          },
        ]
      : []),
    ...(showSelectSchoolYearStage
      ? [
          {
            name: 'Select School Year Stage',
            href: params.id
              ? generatePath(
                  staffRouterPath.NEW_APPLICATION_SELECT_SCHOOL_YEAR_STAGE,
                  {
                    id: params.id || '',
                  }
                )
              : staffRouterPath.NEW_APPLICATION_SELECT_SCHOOL_YEAR_STAGE,
            icon: FileQuestion02,
            disabled: !params.id,
          },
        ]
      : []),
    {
      name: 'Parent/Carer Details',
      href: generatePath(staffRouterPath.APPLICATION_CARER, {
        id: params.id || '',
      }),
      icon: User01,
      disabled: isParentCarerDisabled(application) || isNewApplication,
    },
    {
      name: 'Household Details',
      href: generatePath(staffRouterPath.APPLICATION_HOUSEHOLD_DETAILS, {
        id: params.id || '',
      }),
      icon: Home02,
      disabled: isHouseholdDetailsDisabled(application) || isNewApplication,
    },
    {
      name: 'Placement Preferences',
      href: generatePath(staffRouterPath.APPLICATION_PLACEMENT_PREFERENCES, {
        id: params.id || '',
      }),
      icon: GraduationHat02,
      disabled: isPlacementPreferencesDisabled(application) || isNewApplication,
    },
    {
      name: 'Additional Details',
      href: generatePath(staffRouterPath.APPLICATION_ADDITIONAL_DETAILS, {
        id: params.id || '',
      }),
      icon: AnnotationInfo,
      disabled: isAdditionalDetailsDisabled(application) || isNewApplication,
    },
  ];

  return (
    <>
      <div className="h-full">
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-gray-50 px-6 pb-4 pt-8 lg:w-72">
            <ApplicationNavigation navigation={navigation} />
          </div>
        </div>
        <MobileNavigation navigation={navigation} bottomNavigation={[]} />
        <div className="lg:pl-72">
          <main className="px-4 py-6 pt-0 sm:px-6 lg:px-8">
            <Sentry.ErrorBoundary
              fallback={props => <FallbackComponent {...props} />}
              key={location.pathname}
              showDialog
            >
              {children}
            </Sentry.ErrorBoundary>
          </main>
        </div>
      </div>
      <CookieBanner />
    </>
  );
}

export { ApplicationLayout };
