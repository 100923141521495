import { Breadcrumbs } from '@/components/breadcrumbs';
import { CookieBanner } from '@/components/cookie-banner';
import { FallbackComponent } from '@/components/fallback-component';
import { LoadingScreen } from '@/components/loading-screen';
import { PageTitle } from '@/components/page-title';
import { TabItem, TabNavigation } from '@/components/tab-navigation';
import { parentRouterPath } from '@/config/route-paths.config';
import { useChild } from '@/hooks/query-hooks/use-child';
import { useParentPermission } from '@/hooks/query-hooks/use-parent-permission';
import { BreadcrumbNode } from '@/types/breadcrumb';
import { formatDateToShortDate } from '@/utils/format-date';
import {
  hasManageExtraHoursPermission,
  hasManageTrusteesPermission,
} from '@/utils/parent-utils';
import { ArrowLeft } from '@untitled-ui/icons-react';
import {
  Link,
  Outlet,
  generatePath,
  useLocation,
  useParams,
} from 'react-router-dom';

function ParentsViewChildNavigation() {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const {
    data: parentPermission,
    isSuccess: isParentPermissionSuccess,
    isLoading: isParentPermissionLoading,
  } = useParentPermission(params.id || '', {
    enabled: Boolean(params.id),
  });

  const locationSplit = location.pathname?.split('/');
  const currentPath = locationSplit[locationSplit.length - 1];

  const childId = params.id || '';
  const {
    data: child,
    isLoading: isChildLoading,
    isSuccess: isChildSuccess,
    isError: isChildError,
  } = useChild(childId, {
    enabled: Boolean(params.id),
  });

  const navigationItems: TabItem[] = [
    {
      name: 'Overview',
      url: generatePath(parentRouterPath.CHILD_OVERVIEW, {
        id: childId,
      }),
    },
  ];

  if (isChildLoading || isParentPermissionLoading) {
    return (
      <div className=" bg-gray-50">
        <div
          role="status"
          className="mb-4 flex h-7 w-48 animate-pulse rounded-md bg-gray-200"
        />
        <div
          role="status"
          className="flex h-5 w-48 animate-pulse rounded-md bg-gray-200"
        />
        <div className="mt-5">
          <TabNavigation
            options={navigationItems}
            tabContainerClassName="bg-gray-50 -mx-4 px-4 sm:-mx-6 sm:px-6"
          />
        </div>
        <div className="py-6">
          <LoadingScreen />
        </div>
      </div>
    );
  }

  if (!isChildSuccess || isChildError || !isParentPermissionSuccess) {
    return <FallbackComponent />;
  }

  if (hasManageExtraHoursPermission(parentPermission)) {
    navigationItems.push({
      name: 'Purchase Extra Hours',
      url: generatePath(parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS, {
        id: childId,
      }),
    });
  }

  if (hasManageTrusteesPermission(parentPermission)) {
    navigationItems.push({
      name: 'Trustee',
      url: generatePath(parentRouterPath.TRUSTEE_DASHBOARD, {
        id: childId,
      }),
    });
  }

  const currentPage =
    currentPath === 'purchase-extra-hours'
      ? {
          content: 'Purchase Extra Hours',
          link: generatePath(parentRouterPath.CHILD_PURCHASE_EXTRA_HOURS, {
            id: childId,
          }),
        }
      : {
          content: 'Overview',
          link: generatePath(parentRouterPath.CHILD_OVERVIEW, {
            id: childId,
          }),
        };

  const breadcrumbPaths: BreadcrumbNode[] = [
    {
      content: 'Children',
      link: parentRouterPath.ROOT,
    },
    {
      content: `${child.firstName} ${child.lastName}`,
    },
    currentPage,
  ];

  return (
    <>
      <div className="bg-gray-50 pb-6">
        <Breadcrumbs path={breadcrumbPaths} />
        <PageTitle
          variant="gray"
          title={`${child.firstName} ${child.lastName}`}
          description={formatDateToShortDate(child.dateOfBirth)}
          className="mt-2 border-none bg-gray-50 sm:my-2"
        >
          <Link
            to={parentRouterPath.ROOT}
            className="btn btn-secondary flex w-fit items-center"
          >
            <ArrowLeft
              viewBox="0 0 24 24"
              className="mr-2 h-5 w-5 text-gray-500"
            />{' '}
            Back to All Children
          </Link>
        </PageTitle>
        <TabNavigation
          options={navigationItems}
          tabContainerClassName="bg-gray-50 -mx-4 px-4 sm:-mx-6 sm:px-6 my-4"
        />
        <Outlet />
      </div>

      <CookieBanner />
    </>
  );
}

export { ParentsViewChildNavigation };
