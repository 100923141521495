import { Checkbox } from '../form/common/checkbox';
import { TextInput } from '../form/common/text-input';

export function AddTrusteeForm() {
  return (
    <div className="space-y-6">
      <div className="grid gap-6 sm:grid-cols-2">
        <TextInput
          name="firstName"
          label="First Name*"
          placeholder="First Name"
          type="text"
        />
        <TextInput
          name="lastName"
          label="Last Name*"
          placeholder="Last Name"
          type="text"
        />
      </div>
      <TextInput
        name="email"
        label="Email Address*"
        placeholder="jane.d@gmail.com"
        type="email"
      />
      <TextInput
        name="relationship"
        label="Relationship to Child*"
        placeholder="Grandparent"
        type="text"
      />
      <Checkbox
        label="Manage Applications"
        helperText="Allow this trustee to make changes to applications if applicable"
        name="canManageApplications"
      />
      <Checkbox
        label="Purchase Extra Hours"
        helperText="Allow this trustee to book, and pay for extra hours if applicable"
        name="canPurchaseExtraHours"
      />
    </div>
  );
}
