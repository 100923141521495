import { DropzoneBase } from '@/components/form/common/dropzone';
import { useProcessBulkUploadMutation } from '@/hooks/use-process-bulk-upload-mutatio';
import { useValidateBulkUploadMutation } from '@/hooks/use-validate-bulk-upload-mutation';
import { queryClient } from '@/libs/react-query';
import { S3File } from '@/services/file.service';
import { CheckCircle } from '@untitled-ui/icons-react';
import { useState } from 'react';
import { Spinner } from '../spinner';
import { paths } from '@/config/upload-paths';
import { LocationType } from '@admissions-support/types';

type BulkUploadFormProps = {
  applicationType: LocationType;
  onClose: () => void;
};

function BulkUploadForm(props: BulkUploadFormProps) {
  const { applicationType, onClose } = props;

  const {
    mutateAsync: validate,
    isPending: isValidating,
    error: validationErrors,
    isError: isValidationError,
    data: validationResult,
    reset: resetValidateMutation,
  } = useValidateBulkUploadMutation(applicationType);

  const {
    mutateAsync: process,
    isPending: isProcessing,
    error: processingErrors,
    isError: isProcessingError,
  } = useProcessBulkUploadMutation(applicationType);

  const [uploadedFile, setUploadedFile] = useState<S3File | undefined>(
    undefined
  );

  const [formValidationError, setFormValidationError] = useState<
    string | undefined
  >();

  const handleProcessButton = async () => {
    if (!uploadedFile) {
      return;
    }

    resetValidateMutation();
    await process({ file: uploadedFile.key });
    await queryClient.invalidateQueries({
      queryKey: ['application'],
    });
    queryClient.invalidateQueries({
      queryKey: ['application-list'],
    });
    onClose();
  };

  const handleValidate = async (files: S3File[]) => {
    if (files.length < 1) {
      return;
    }
    setUploadedFile(files[0]);
    await validate({ file: files[0].key });
  };

  const isValidationPassed = (validationResult?.nbPassedValidation ?? 0) > 0;

  return (
    <>
      <DropzoneBase
        path={paths.applications}
        files={uploadedFile ? [uploadedFile] : []}
        onFilesChange={handleValidate}
        options={{
          multiple: false,
          disabled: isValidating,
          accept: {
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              [],
            'text/csv': [],
          },
        }}
        fileRestrictionLabel="Only CSV"
        setError={errMsg => setFormValidationError(errMsg)}
        clearErrors={() => setFormValidationError(undefined)}
        disableRemoveButton
      />
      {isValidating ? (
        <div className="mt-4 flex items-center text-sm text-gray-500">
          <Spinner className="h-5 w-5" /> Validating the file..
        </div>
      ) : null}
      {isValidationError ? (
        <div className="mt-4 max-h-32 space-y-1 overflow-x-auto overflow-y-auto">
          {Array.isArray(validationErrors.message) ? (
            validationErrors.message.map((error, index) => (
              <p key={index} className="text-xs text-red-500">
                {error}
              </p>
            ))
          ) : (
            <p className="text-xs text-red-500">{validationErrors.message}</p>
          )}
        </div>
      ) : null}

      {formValidationError ? (
        <p className="text-xs text-red-500">{formValidationError}</p>
      ) : null}

      {isProcessingError ? (
        <div className="mt-4 max-h-32 space-y-1 overflow-x-auto overflow-y-auto">
          {Array.isArray(processingErrors.message) ? (
            processingErrors.message.map((error, index) => (
              <p key={index} className="text-xs text-red-500">
                {error}
              </p>
            ))
          ) : (
            <p className="text-xs text-red-500">{processingErrors.message}</p>
          )}
        </div>
      ) : null}

      {isValidationPassed && (
        <div className="mt-4 flex items-center">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-green-50">
            <CheckCircle
              aria-hidden="true"
              viewBox="0 0 24 24"
              className="h-4 w-4 text-green-700"
            />
          </div>
          <p className="ml-2 text-sm font-medium text-green-700">
            Successfully Validated {validationResult?.nbPassedValidation}{' '}
            Applications
          </p>
        </div>
      )}
      <div className="mt-8 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="btn btn-primary inline-flex w-full justify-center sm:ml-3"
          onClick={handleProcessButton}
          disabled={!isValidationPassed}
        >
          {isValidationPassed
            ? `Add ${validationResult?.nbPassedValidation} Applications`
            : 'Add Applications'}
        </button>
        <button
          type="button"
          className="btn btn-secondary mt-3 inline-flex w-full justify-center sm:mt-0"
          onClick={onClose}
        >
          Cancel
        </button>
      </div>
      {isProcessing ? (
        <div className="absolute inset-0 flex h-full w-full flex-col justify-center bg-white">
          <div className="mt-3 px-4 pb-4 pt-5 text-center sm:mt-0 sm:text-left">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Processing Applications
            </h3>
            <p className="mb-5 text-sm text-gray-600">
              Applications are being uploaded. Please do not leave this screen.
            </p>
          </div>
          <div className="flex flex-grow flex-col items-center justify-center">
            <div role="status">
              <Spinner className="h-14 w-14" />
              <span className="sr-only">Loading...</span>
            </div>
            <p className="mt-2 text-lg font-medium text-gray-700">
              Processing...
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export { BulkUploadForm };
